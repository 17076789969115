import React from 'react';
import './card.css'

function Card6(props) {

return(
  <tr>
   
  <td width='20%' align='left' style={{color:'#000',fontFamily:'CircularStd-Book'}} >{props.titulo}</td>
  <td width='30%' align='left' style={{textTransform:'none'}}>{props.valor}</td>  
  <td width='10%' align='left'>{props.cuenta}</td>
  <td width='10%' align='left'>{props.tipocuenta}</td>
  <td width='20%' align='right'>{props.comentario} €</td>

</tr>
              // <div className="card3">
              //     <div className="card-content">
              //     <div style={{display: 'flex',flexDirection: 'row'}}>


              //  <span style={{display:'flex',height:'25px',justifyContent:'center',alignItems:'center', paddingLeft:'0px',fontSize:'22px',fontFamily:'CircularStd-Bold',color:'#000000'}}>{props.titulo}</span>  
              //  </div>
              //       <div style={{fontSize:'20px',fontFamily:'CircularStd-Light',color:'black'}}>{props.comentario} €</div>
              //       <div style={{fontFamily:'CircularStd-Light',fontSize:'15px',color:'gray'}}> Sociedad: {props.valor }</div>
              //       <div style={{fontFamily:'CircularStd-Light',fontSize:'15px',color:'gray'}}> Cuenta: {props.cuenta}</div>
              //       <div style={{fontFamily:'CircularStd-Light',fontSize:'15px',color:'gray'}}> Tipo Cuenta: {props.tipocuenta}</div>
              //     </div>
              // </div>
);
}
export default Card6;