const Estados = [
    
    
    {
        name: "Cifras",
        
    },
    {
        name: "Cartera",
        
    },
    {
        name: "Ratios y Magnitudes",
     
    },
    {
        name: "Estados Financieros",
      
    },
    {
        name: "Tesorería",
     
    },
    {
        name: "Documentos",
     
    },
  
    
];

export default Estados;