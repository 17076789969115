import React, { useState, useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import Caryt from '../components/cardfun'
import axios from 'axios';
import Select from 'react-select'
import ApiService from "./ApiService";
import Spinner from "../assets/icons8-loading-infinity.gif"
import BarLoader from "react-spinners/BarLoader";
import styled from 'styled-components';
import Numeral from "numeral";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import '../App.css';
import XLSX from 'xlsx';
import { RiFileExcel2Line, RiFilePdfLine } from 'react-icons/ri';
import { VscFilePdf } from 'react-icons/vsc';

export default class VentasFamilias extends React.Component {

  state = {
    datos: [],
    cargando: true,
    
    micliente:sessionStorage.getItem('cliente'),
    //ESTADOS DEL ÚLTIMO EJERCICIO Y MES DISPONIBLES EN LA BBDD

    mes: '',
    meses:[],
    nombremes:'',
    ejercicio: '',
    ejercicios:[],
    maxmes:'',
    total:0,
    tableRef:null,
  }
  descargaPdf= async ()=>{
    
    var a = document.createElement('a');
    a.href = "https://apicore.myleistung.com/pdf/"+this.state.ejercicio+"/"+this.state.mes+"/"+this.state.micliente+"/"+sessionStorage.getItem("usuario").toUpperCase()+"/";
    a.download = 'download';
    a.click();
  }
    
   ExportToExcel =async (type, fn, dl)=> {
    var elt = document.getElementById('emp');
    var wb = XLSX.utils.table_to_book(elt, { sheet: "Cuentas Funcionales" });
    return dl ?
      XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
      XLSX.writeFile(wb, fn || ('CuentasFuncionales.xlsx'));
  
 }
 getPath(path) {
    if (path.charAt(0) !== "/") { 
        return  "/" + path;
    }
    return path;
}
  meses = async () => {
    return ApiService.fetchDatosMeses(this.state.micliente, this.state.ejercicio)
      .then(response => {
        // console.log(this.state.ejercicio)

        this.setState(
          {
            meses: response.data
          })

   

      })
      .catch(error => {


      });

  }
  ejercicios = async () => {
    //console.log(this.state.micliente)
    return ApiService.fetchDatosEjercicios(this.state.micliente)
      .then(response => {

        this.setState(
          {
           maxmes:response.data[0].maxmes, ejercicios: response.data, ejercicio: response.data[0].maxejer, mes: response.data[0].maxmes, nombremes: response.data[0].meses
          })

      })
      .catch(error => {


      });

  };

   miusuario=  async () =>
  {
    axios.get('https://apicore.myleistung.com/funcionales/'+this.state.ejercicio+'/'+this.state.mes+'/'+this.state.micliente+'/RICOPIA/',{
   
    })
            .then(res => {
             
              this.setState({datos:res.data})
              
            })
            .catch(function (error) {
                console.log(error);
            })



  
   
  } ;


  async componentDidMount() {
    
    await this.ejercicios();
    
    // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    // await sleep(400)
   
    await this.meses();
 
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(800)
    await this.miusuario();
     
    const sleep3 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep3(1200) 

     this.setState({cargando:false})
     
    
   // alert(parseInt(this.state.mes))// Ejecutamos la función 
   
  }
  handleChange =async (e) => {
    this.setState({cargando:true})
    this.setState({mes:e.value, nombremes:e.label});
//alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)
  
   this.miusuario()
  
   this.setState({nombremes:e.label});
   const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
   await sleep2(1200)
   this.setState({cargando:false})
  }
 
  handleChange2 =async (e) => {
   // alert(e.nombre)
    this.setState({cargando:true})
    this.setState({ejercicio:e.value,mes:e.maxmes, nombremes:e.nombre});
    
//alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)
  
  this.meses(e.value)
  
   this.miusuario()
  
  // this.setState({nombremes:e.label});
   const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
   await sleep2(1200)
   //alert(this.state.meses)
   this.setState({cargando:false})
  }


  render() {

   const options = 
    this.state.meses.map((dato,i) => {
     return {
         label: dato.meses,
         value: dato.nummes,
         nombre:dato.meses
         
         
     }
   }
   );
   const options2 = 
   this.state.ejercicios.map((dato,i) => {
    return {
        label: dato.ejercicios,
        value: dato.ejercicios,
        maxmes:dato.maxmes,
        nombre:dato.meses
    }
   }
   );
     
   const dot = (color = "rgb(255,193,53)") => ({
    alignItems: "center",
    display: "flex",
    color: '#000000',
    borderColor: "black",

    ":before": {
      backgroundColor: "#fff",
      // borderRadius: 1,
      // content: '" "',
      // display: "block",
      // marginRight: 8,
      // height: 10,
      // width: 10

    }
  });
  const styles = {

    control: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontFamily: 'CircularStd-Bold',
        borderColor: "black",
        boxShadow: 'none',
        border: '2px ',
        borderWidth: "1px",
        borderRadius: "5px",
        backgroundColor: isFocused ? "rgba(255,193,53,1)" : "rgba(255,193,53,1)",
        fontSize: '15px', fontColor: 'white', minWidth: '170px', width: '170px', height: '35px',
        ":hover": {
          ...styles[":hover"],
          backgroundColor: "rgba(255,193,53,0.30)",

          color: "white",
          borderColor: "white"
        },

      };
    },
    indicatorSeparator: style => ({
      ...style,
      display: 'none',
      color: '#000000'
    }),
    dropdownIndicator: style => ({
      ...style,

      color: '#000000'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        minWidth: '150px',
        height: '35px',
        borderRadius: '0px',
        
        backgroundColor: isSelected ? "rgba(255,193,53,0.60)" : "#fff",
        // width:'150px',
        color: "#000",
        fontFamily: 'CircularStd-Book',
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles,
          backgroundColor: "#000000",
          color: 'black',
          borderColor: "black"
        },
        ":hover": {
          ...styles[":hover"],
          backgroundColor: "rgba(255,193,53,0.30)",
          color: "black",
          borderColor: "black"
        },

      };
    },

    menu: styles => ({ ...styles, marginTop: '3px', borderRadius: '5px', borderColor: "black", backgroundColor: "white", fontSize: '13px', minWidth: '168px', width: '168px' }),
    input: styles => ({ ...styles, borderColor: "black", borderWidth: "0px" }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
  };

     
      return ( 
        <div style={{padding:'24px',minWidth:'calc(100vw- 265px)', width: 'calc(100vw  - 265px)',minHeight:'calc(100% - 50px)', height:'calc(100% - 50px)'}}>  
            
           
    
          
        { this.state.cargando ? 
  
         <div  style={{
          display:'flex',
       
          height: '90vh',
          width: 'calc(100vw-250px)',
          justifyContent:'center',
          alignItems:'center',
           
            
          }}>
            <BarLoader color={'#000'} size={40}/>
            {/* <img src={Spinner} style={{width:'50px'}}/> */}
            {/* <i style={{fontSize:25,color:'#4682b4'}} className="fas fa-circle-notch fa-spin" />  */}
          </div>
        :
  
        
        <div style={{margin: '10px ',minWidth:'calc(100vw- 265px)', verticalAlign:'middle',maxWidth:'1500px' }}> 
            <div style={{display:'flex',flexWrap:'wrap',flexDirection:'row'}}>
  
                 <div style={{display:'flex',justifyContent:'flex-start',width:'60%'}}>
                   <div style={{display:'flex',fontSize:'32px',fontFamily:'CircularStd-Bold', color:'#000000'}}>Cuentas Funcionales</div>
                 {/* <h3>Dashboard a {this.state.nombremes} de {this.state.ejercicio}</h3> */}
                 </div>
                 <div style={{display:'flex',justifyContent:'center',alignItems:'center',justifyContent:'flex-end',width:'40%'}}> 
                 <div style={{display:'flex',borderRadius:'5px',border:'1px solid #39548240'}}>
                  <button className="boton" onClick={this.ExportToExcel}><RiFileExcel2Line size={20} style={{marginTop:'-3px',lineHeight:'20px'}}/></button>
                  <button className="boton" onClick={this.descargaPdf}><VscFilePdf size={20} style={{marginTop:'-3px',lineHeight:'20px'}}/></button>
                  {/* <ReactHTMLTableToExcel  
                                                className="boton"
                                                table="emp"  
                                                filename="VentasFamilias"  
                                                sheet="RankingVentas"  
                                                buttonText="Excel" />   */}
                </div>  &nbsp;
                  <Select 
                  isSearchable={false}
                 // value={selectedOption}
                 value={{value:this.state.ejercicio,label:this.state.ejercicio}}
                  onChange={this.handleChange2}
                 
                 options={options2} styles={styles} placeholder="Ejercicios"/> &nbsp; 
                 <Select 
                 
                 isSearchable={false}
                 //defaultValue={options[1]}
                 value={options.filter(({value})=>value ===this.state.mes)}
                 onChange={this.handleChange}
                 options={options} styles={styles} placeholder="Meses"/> 
                
                 </div> 
  
         </div> 
         <div style={{marginTop: '10px', display:'flex',flexWrap:'wrap',flexDirection:'row',gridTemplateColumns: 'repeat(1, 1fr)'}}>  
        
        
         <MiTabla>
          <table id="emp">
        
        
          {this.state.datos.length > 0 &&  this.state.datos.map((dato, i) => 

          
     
     <Caryt
      titulo={dato.CONCEPTO}
      concepto={dato.CONCEPTO}
      saldon={dato.SALDON}
      vertica={dato.VERTICA}
      saldon1={dato.SALDON1}
      verticb={dato.VERTICB}
      estilo={dato.ESTILO}
     
      ejercicio={this.state.ejercicio}
      cliente={this.state.micliente}
      mes={this.state.mes}
      
      diferencia={dato.DIF}
      />
      
        )}
      
  
      
     
   </table>
    
    </MiTabla>
      
    </div>
 
    
   
               </div>        
     }    
           </div>
        
       
    
      );
        
  
    } 
  }
   
  const MiTabla = styled.table`
  table {
    
    background-color:white;
    table-layout: fixed;
    border-collapse:collapse;
    
    padding:5px;
    //box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 1px ;
    width: 100%;
  }
  th {
    font-family:'CircularStd-Bold';
    color:#000000;
    border:1px solid lightgray;
    padding:5px;
    background-color:rgba(223,223,223,1);
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
  }
  td {
    font-family:'CircularStd-Light';
    color:'#000000';
    border:1px solid lightgray;
    padding:5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
  }
  table tr th:nth-child(1){
    width: 50%;
   
  }
  table tr th:nth-child(2){
    width: 20%;
  }
  table tr th:nth-child(3){
    width: 20%;
  }
  table tr th:nth-child(4){
    width: 10%;
  }
  table tr th:nth-child(5){
    width: 10%;
   
  }
  table tr th:nth-child(6){
    width: 10%;
  }
  table tr th:nth-child(7){
    width: 10%;
  }
  table tr th:nth-child(8){
    width: 10%;
  }
  tr:hover{
    background-color:#d1d1d140;
  
  }
  tr {
    cursor: pointer;
  }
  `;  
      
     
   




