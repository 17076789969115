import React from 'react';
import './card.css'

function Cardefe(props) {

return(
 

  <tr>
  
    <td  align='left' style={{width:'80%',backgroundColor:props.estilo != 0? 'rgba(223,223,223,1)': '',fontFamily: props.estilo != 0? 'CircularStd-Bold': 'CircularStd-Light',color:props.estilo != 0? '#000000':'#000000',fontSize:props.estilo != 0? '17px':'15px'}}>{props.concepto}</td>
   
    <td  align='right' style={{backgroundColor:props.estilo != 0? 'rgba(223,223,223,1)': '',fontFamily: props.estilo != 0? 'CircularStd-Bold': 'CircularStd-Light',color:props.estilo != 0? '#000000':'#000000',fontSize:props.estilo != 0? '17px':'15px'}}>{props.saldo} €</td>
    
  </tr>
 
);
}
export default Cardefe;