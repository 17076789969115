const SidebarItems = [
    
    
    {
        name: "Cifras",
        route: '/dashboard',
        icon:'Cifras',
        indice:0
    },
    
    {
        name: "Cifras Grupo",
        route: '/ventasglobal',
        icon:'Cifras',
        indice:1
    },
    {
        name: "Ventas por Familias",
        route: '/familias',
        icon:'Cifras',
        indice:2
    },
    {
        name: "Cartera Clientes",
        route: '/cartera',
        icon:'Cartera',
        indice:3
    },
    {
        name: "Ratios y Magnitudes",
        route: '/ratios',
        icon:'Ratios y Magnitudes',
        indice:4
    },
    {
        name: "Masas y FM-NOF",
        route: '/fm-nof',
        icon:'Ratios y Magnitudes',
        indice:5
    },
   
  
    {
        name: "Balance de Situación",
        route: '/balance',
        icon:'Estados Financieros',
        indice:6
    },
    {
        name: "Cuenta de Resultados",
        route: '/cuentaresultados',
        icon:'Estados Financieros',
        indice:7
    },
    {
        name: "Cuentas Funcionales",
        route: '/funcionales',
        icon:'Estados Financieros',
        indice:8
    },
    {
        name: "Cash Flow",
        route: '/efe',
        icon:'Estados Financieros',
        indice:9
    },
    {
        name: "Posición Tesorería",
        route: '/tesoreria',
        icon:'Tesorería',
        indice:10
    },
   
    // {
    //     name: "Gráficas",
    //     route: '/familias',
    //     icon:''
    // },
    
  
   
    {
        name: "Documentos",
        route: '/documentos',
        icon:'Documentos',
        indice:11
        
    },
  
    // {
    //     name: "Mis cuentas",
    //     route: '/update',
    //     icon:'Documentos',
    //     indice:11
        
    // },
    // {
    //     name: "Mi Cuenta Resultados",
    //     route: '/miresultado',
    //     icon:''
    // },
    
];

export default SidebarItems;
