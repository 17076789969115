import React, { useState } from 'react';
import '../App.css';
import * as XLSX from  'xlsx';
import styled from 'styled-components'

export default function Fichero()  {
  const [items, setItems] = useState([]);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
   
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type:  "buffer"  });

        const wsname = wb.SheetNames;
        //alert(wsname)
        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);
      
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setItems(d);
    });
  };
  const contador=()=>{
    {items.map((d) => (
      // <tr key={d.CUENTA}>
      //   <td>{d.CUENTA}</td>
      //   <td>{d.NOMBRE}</td>
      //   <td>{d.saldo}</td>
      // </tr>
      alert(d.CUENTA +' ' +d.NOMBRE)
    ))}
   
  }
  return (
    <div style={{padding:'24px',minWidth:'calc(100vw- 265px)', width: 'calc(100vw  - 265px)',minHeight:'calc(100% - 50px)', height:'calc(100% - 50px)'}}>  
    <div style={{margin: '10px ',minWidth:'calc(100vw- 265px)', verticalAlign:'middle',maxWidth:'1500px' }}> 
    <div style={{backgroundColor:'', display:'flex',flexWrap:'wrap',flexDirection:'row',gridTemplateColumns: 'repeat(1, 3fr)'}}>  
      <input
        type="file"
        onChange={(e) => {
          const file = e.target.files[0];
          readExcel(file);
        }}
      />
      <input type="submit" onClick={contador}/>
      <MiTabla>
            {items.length > 0 &&
                  <table >
                    <thead>
                      <tr>
                        <th >Cuenta</th>
                        <th >Concepto</th>
                        <th >Saldo</th>
                      </tr>
                    </thead>
                    <tbody> 
                      {items.map((d) => (
                        <tr key={d.CUENTA}>
                          <td>{d.CUENTA}</td>
                          <td>{d.NOMBRE}</td>
                          <td>{d.saldo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  
                  } 
      </MiTabla>
    </div>
    </div>
    </div>
  );
}
// export default class SheetJSApp extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
//       cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */
//     };
//     this.handleFile = this.handleFile.bind(this);
//     this.exportFile = this.exportFile.bind(this);
//   }
//   handleFile(file /*:File*/) {
//     /* Boilerplate to set up FileReader */
//     const reader = new FileReader();
//     const rABS = !!reader.readAsBinaryString;
//     reader.onload = e => {
//       /* Parse data */
//       const bstr = e.target.result;
//       const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
//       /* Get first worksheet */
//       const wsname = wb.SheetNames[0];
//       const ws = wb.Sheets[wsname];
//       console.log(rABS, wb);
//       /* Convert array of arrays */
//       const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
//       /* Update state */
//       this.setState({ data: data, cols: make_cols(ws["!ref"]) });
//     };
//     if (rABS) reader.readAsBinaryString(file);
//     else reader.readAsArrayBuffer(file);
//   }
//   exportFile() {
//     /* convert state to workbook */
//     const ws = XLSX.utils.aoa_to_sheet(this.state.data);
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
//     /* generate XLSX file and send to client */
//     XLSX.writeFile(wb, "sheetjs.xlsx");
//   }
//   render() {
//     return (
//       <DragDropFile handleFile={this.handleFile}>
//         <div className="row">
//           <div className="col-xs-12">
//             <DataInput handleFile={this.handleFile} />
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-xs-12">
//             <button
//               disabled={!this.state.data.length}
//               className="btn btn-success"
//               onClick={this.exportFile}
//             >
//               Export
//             </button>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-xs-12">
//             <OutTable data={this.state.data} cols={this.state.cols} />
//           </div>
//         </div>
//       </DragDropFile>
//     );
//   }
// }

// /* -------------------------------------------------------------------------- */

// /*
//   Simple HTML5 file drag-and-drop wrapper
//   usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
//     handleFile(file:File):void;
// */
// class DragDropFile extends React.Component {
//   constructor(props) {
//     super(props);
//     this.onDrop = this.onDrop.bind(this);
//   }
//   suppress(evt) {
//     evt.stopPropagation();
//     evt.preventDefault();
//   }
//   onDrop(evt) {
//     evt.stopPropagation();
//     evt.preventDefault();
//     const files = evt.dataTransfer.files;
//     if (files && files[0]) this.props.handleFile(files[0]);
//   }
//   render() {
//     return (
//       <div
//         onDrop={this.onDrop}
//         onDragEnter={this.suppress}
//         onDragOver={this.suppress}
//       >
//         {this.props.children}
//       </div>
//     );
//   }
// }

// /*
//   Simple HTML5 file input wrapper
//   usage: <DataInput handleFile={callback} />
//     handleFile(file:File):void;
// */
// class DataInput extends React.Component {
//   constructor(props) {
//     super(props);
//     this.handleChange = this.handleChange.bind(this);
//   }
//   handleChange(e) {
//     const files = e.target.files;
//     if (files && files[0]) this.props.handleFile(files[0]);
//   }
//   render() {
//     return (
//       <form className="form-inline">
//         <div className="form-group">
//           <label htmlFor="file">Spreadsheet</label>
//           <input
//             type="file"
//             className="form-control"
//             id="file"
//             accept={SheetJSFT}
//             onChange={this.handleChange}
//           />
//         </div>
//       </form>
//     );
//   }
// }

// /*
//   Simple HTML Table
//   usage: <OutTable data={data} cols={cols} />
//     data:Array<Array<any> >;
//     cols:Array<{name:string, key:number|string}>;
// */
// class OutTable extends React.Component {
//   render() {
//     return (
//       <div className="table-responsive">
//         <table className="table table-striped">
//           <thead>
//             <tr>
//               {this.props.cols.map(c => (
//                 <th key={c.key}>{c.name}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {this.props.data.map((r, i) => (
//               <tr key={i}>
//                 {this.props.cols.map(c => (
//                   <td key={c.key}>{r[c.key]}</td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     );
//   }
// }

// /* list of supported file types */
// const SheetJSFT = [
//   "xlsx",
//   "xlsb",
//   "xlsm",
//   "xls",
//   "xml",
//   "csv",
//   "txt",
//   "ods",
//   "fods",
//   "uos",
//   "sylk",
//   "dif",
//   "dbf",
//   "prn",
//   "qpw",
//   "123",
//   "wb*",
//   "wq*",
//   "html",
//   "htm"
// ]
//   .map(function(x) {
//     return "." + x;
//   })
//   .join(",");

// /* generate an array of column objects */
// const make_cols = refstr => {
//   let o = [],
//     C = XLSX.utils.decode_range(refstr).e.c + 1;
//   for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
//   return o;
// };


const MiTabla = styled.table`
table {
 
  table-layout: fixed;
  border-collapse:collapse;
  padding:5px;
  
  width: 100%;
}
th {
  font-family:'CircularStd-Bold';
  color:#395482;
   border:1px solid rgba(205,205,205,0.5);
   padding:5px;
   background-color:rgb(198,206,219);
}
td {
  font-family:'CircularStd-Light';
  color:'#000000';
  border:1px solid lightgray;
  padding:5px;
  
}
table tr th:nth-child(1){
  width: 15%;
 
}
table tr th:nth-child(2){
  width: 45%;
}
table tr th:nth-child(3){
  width: 20%;
}
table tr th:nth-child(4){
  width: 20%;
}
tr:hover{
  background-color:#d1d1d140;

}
tr {
  cursor: pointer;
}
`;