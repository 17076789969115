import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Caryt from '../components/cardglobal'
import Select from 'react-select'
import { Bar, Line } from 'react-chartjs-2';
import ApiService from "./ApiService";
import Spinner from "../assets/icons8-loading-infinity.gif"
import BarLoader from "react-spinners/BarLoader";


export default class Dashboard extends React.Component {


  // Estado inicial del usuario
  state = {
    datos: [],
    datoschart: [],
    datoschart2: [],
    datoschart3: [],
    cargando: true,
    cargando2: true,
    selectedValue: null,
    micliente: '',
    migrupo: sessionStorage.getItem('admin'),
    //ESTADOS DEL ÚLTIMO EJERCICIO Y MES DISPONIBLES EN LA BBDD

    mes: '',
    meses: [],
    nombremes: '',
    ejercicio: '',
    ejercicios: [],
    maxmes: '',

  }

  // Permite obtener y modificar el estado
  meses = async () => {
    return ApiService.fetchDatosMeses(this.state.micliente, this.state.ejercicio)
      .then(response => {
        // console.log(this.state.ejercicio)

        this.setState(
          {
            meses: response.data
          })



      })
      .catch(error => {


      });

  }
  ejercicios = async () => {
    //console.log(this.state.micliente)
    return ApiService.fetchDatosEjercicios(this.state.micliente)
      .then(response => {

        this.setState(
          {
            maxmes: response.data[0].maxmes, ejercicios: response.data, ejercicio: response.data[0].maxejer, mes: response.data[0].maxmes, nombremes: response.data[0].meses
          })

      })
      .catch(error => {


      });

  };

  miusuario = async () => {
    axios.get('https://apicore.myleistung.com/grupo/' + this.state.ejercicio + '/' + this.state.mes + '/' + this.state.migrupo, {

    })
      .then(res => {

        this.setState({ datos: res.data })

      })
      .catch(function (error) {
        console.log(error);
      })





  };
  michart = async () => {
    axios.get('https://apicore.myleistung.com/chartmesgrupo/' + this.state.ejercicio + '/' + this.state.migrupo, {

    })
      .then(res => {

        this.setState({ datoschart: res.data, datoschart2: res.data, datoschart3: res.data })

      })
      .catch(function (error) {
        console.log(error);
      })





  };
  // Obtenemos datos desde la API
  async componentDidMount() {

    this.setState({ micliente: sessionStorage.getItem("cliente") });
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(400)
    await this.ejercicios();

    // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    // await sleep(400)

    await this.meses();

    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(400)
    await this.miusuario();


    await this.michart();
    const sleep3 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep3(800)

    this.setState({ cargando: false })
    this.setState({ cargando2: false })

    // alert(parseInt(this.state.mes))// Ejecutamos la función 

  }
  handleChange = async (e) => {
    this.setState({ cargando: true })
    this.setState({ mes: e.value, nombremes: e.label });
    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    await this.miusuario()
    await this.michart();
    this.setState({ nombremes: e.label });
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(300)
    this.setState({ cargando: false })
  }

  handleChange2 = async (e) => {
    // alert(e.nombre)
    this.setState({ cargando: true })
    this.setState({ ejercicio: e.value, mes: e.maxmes, nombremes: e.nombre });

    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    await this.meses(e.value)

    await this.miusuario()
    await this.michart();
    // this.setState({nombremes:e.label});
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(300)
    //alert(this.state.meses)
    this.setState({ cargando: false })
  }
  render() {
    const datas = {
      //labels:['ENE','FEB','MAR','ABR','MAY','JUN','JUL','AGO','SEP','OCT','NOV','DIC'],
      labels: this.state.datoschart.map((dato) => dato.mes.substring(0, 3)),
      datasets: [
        {
          label: 'Ventas',
          backgroundColor: '#4682b4',
          // borderColor: '#4682b4',
          // borderWidth: 1,
          pointBackgroundColor: 'rgba(255,193,53,1)',
          hoverBackgroundColor: '#4682b440',
          hoverBorderColor: '#4682b4',
          //data:[-93140,71606,-50830,-1547,-21530,-3053,-39511,-100736,-97502,-3932,75793,289280]
        data: this.state.datoschart.map((dato) => parseInt(dato.ingresosm))
        }
      ]
    };
    const datas2 = {
      labels: this.state.datoschart.map((dato) => dato.mes.substring(0, 3)),
      datasets: [
        {
          label: 'Gastos',
          backgroundColor: '#4682b4',
          //  borderColor: '#4682b4',
          //borderWidth: 1,
          pointBackgroundColor: 'rgba(255,193,53,1)',
          hoverBackgroundColor: '#4682b440',
          hoverBorderColor: '#4682b4',
          data: this.state.datoschart.map((dato) => parseInt(dato.gastosm))
        }
      ]
    };
    const datas3 = {
      labels: this.state.datoschart.map((dato) => dato.mes.substring(0, 3)),
      datasets: [
        {
          label: 'Beneficio',
          backgroundColor: '#4682b4',
          //borderColor: '#4682b4',
          // borderWidth: 1,
          pointBackgroundColor: 'rgba(255,193,53,1)',
          hoverBackgroundColor: '#4682b440',
          hoverBorderColor: '#4682b4',
          data: this.state.datoschart.map((dato) => parseInt(dato.beneficio))
        }
      ]
    };
    //OPCIONES SELECT  Y ESTILOS SELECT


    const options =
      this.state.meses.map((dato, i) => {
        return {
          label: dato.meses,
          value: dato.nummes,
          nombre: dato.meses


        }
      }
      );
    const options2 =
      this.state.ejercicios.map((dato, i) => {
        return {
          label: dato.ejercicios,
          value: dato.ejercicios,
          maxmes: dato.maxmes,
          nombre: dato.meses
        }
      }
      );

    const dot = (color = "rgb(255,193,53)") => ({
      alignItems: "center",
      display: "flex",
      color: '#000000',
      borderColor: "black",

      ":before": {
        backgroundColor: "#fff",
        // borderRadius: 1,
        // content: '" "',
        // display: "block",
        // marginRight: 8,
        // height: 10,
        // width: 10

      }
    });
    const styles = {

      control: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          fontFamily: 'CircularStd-Bold',
          borderColor: "black",
          boxShadow: 'none',
          border: '2px ',
          borderWidth: "1px",
          borderRadius: "5px",
          backgroundColor: isFocused ? "rgba(255,193,53,1)" : "rgba(255,193,53,1)",
          fontSize: '15px', fontColor: 'white', minWidth: '170px', width: '170px', height: '35px',
          ":hover": {
            ...styles[":hover"],
            backgroundColor: "rgba(255,193,53,0.30)",

            color: "white",
            borderColor: "white"
          },

        };
      },
      indicatorSeparator: style => ({
        ...style,
        display: 'none',
        color: '#000000'
      }),
      dropdownIndicator: style => ({
        ...style,

        color: '#000000'
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          minWidth: '150px',
          height: '35px',
          borderRadius: '0px',

          backgroundColor: isSelected ? "rgba(255,193,53,0.60)" : "#fff",
          // width:'150px',
          color: "#000",
          fontFamily: 'CircularStd-Book',
          cursor: isDisabled ? "not-allowed" : "default",
          ":active": {
            ...styles,
            backgroundColor: "#000000",
            color: 'black',
            borderColor: "black"
          },
          ":hover": {
            ...styles[":hover"],
            backgroundColor: "rgba(255,193,53,0.30)",
            color: "black",
            borderColor: "black"
          },

        };
      },

      menu: styles => ({ ...styles, marginTop: '3px', borderRadius: '5px', borderColor: "black", backgroundColor: "white", fontSize: '13px', minWidth: '168px', width: '168px' }),
      input: styles => ({ ...styles, borderColor: "black", borderWidth: "0px" }),
      placeholder: styles => ({ ...styles, ...dot() }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
    };



    return (
      <div style={{ padding: '24px', minWidth: 'calc(100vw- 265px)', width: 'calc(100vw  - 265px)', minHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}>


        {this.state.cargando ?

          <div style={{
            display: 'flex',

            height: '90vh',
            width: 'calc(100vw-250px)',
            justifyContent: 'center',
            alignItems: 'center',


          }}>
            <BarLoader color={'#000'} size={40} />
            {/* <img src={Spinner} style={{width:'50px'}}/> */}
            {/* <i style={{fontSize:25,color:'#4682b4'}} className="fas fa-circle-notch fa-spin" />  */}
          </div>
          :


          <div style={{ margin: '10px ', minWidth: 'calc(100vw- 265px)', verticalAlign: 'middle', maxWidth: '1500px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>

              <div style={{ display: 'flex', justifyContent: 'flex-start', width: '60%' }}>
                <div style={{ display: 'flex', fontSize: '32px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>Cifras Grupo</div>
                {/* <h3>Dashboard a {this.state.nombremes} de {this.state.ejercicio}</h3> */}
              </div>
              <div style={{ marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-end', width: '40%' }}>

                <Select
                  isSearchable={false}
                  // value={selectedOption}
                  value={{ value: this.state.ejercicio, label: this.state.ejercicio }}
                  onChange={this.handleChange2}

                  options={options2} styles={styles} placeholder="Ejercicios" /> &nbsp;
                <Select

                  isSearchable={false}
                  //defaultValue={options[1]}
                  value={options.filter(({ value }) => value === this.state.mes)}
                  onChange={this.handleChange}
                  options={options} styles={styles} placeholder="Meses" />

              </div>

            </div>
            <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>


              {this.state.datos.length > 0 && this.state.datos.map((dato, i) =>

                <Caryt
                  titulo={dato.concepto}
                  acumulado={dato.importe}
                  actual={dato.diferencia}
                  anterior={dato.importeanterior}
                  porcentaje={dato.porcentaje}
                  mes={'Mes ' + this.state.nombremes}
                  epigrafe={dato.epigrafe}
                  ejercicio={this.state.ejercicio}
                  cliente={this.state.micliente}
                  concepto={dato.concepto}
                />

              )}


            </div>
            {this.state.datoschart.length > 0 && !this.state.cargando2 &&


              <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>


                <div className="cardchart">

                  <p style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>Evolución Ventas</p>
                  <Line
                    data={datas}
                    options={

                      {

                        elements: {
                          point: {
                            radius: 4
                          },

                        },
                        tension: 0.3,
                        fill: true,
                        fill: {
                          target: 'start',
                          above: 'rgba(65,65,65,0.1)',   // Area will be red above the origin
                          below: 'rgba(65,65,65,0.1)'    // And blue below the origin
                        },
                        borderWidth: 3,
                        showLine: true,

                        backgroundColor: "rgba(255,193,53,0.60)",
                        borderColor: "rgba(0,0,0,0.61)",

                        pointBackgroundColor: "rgba(255,255,255,0.8)",
                        maintainAspectRatio: true,

                        responsive: true,
                        plugins: {
                          tooltip: {
                            mode: 'index',
                            intersect: false,
                            displayColors: false,
                            bodyFont: {
                              size: 13,
                              family: 'CircularStd-Light'
                            },
                            titleFont: {
                              size: 13,
                              family: 'CircularStd-Bold'
                            },
                          },
                          legend: {
                            display: false,
                          }
                        },
                        animation: {
                          duration: 0,
                        },

                        scales: {

                          x: {
                            grid: {
                              display: false,
                            },
                            ticks: {
                              font: {
                                size: 12,
                                family: 'CircularStd-Book',
                              }
                            }
                          },
                          y: {
                            beginAtZero: true,
                            grid: {
                              display: true,
                            },
                            ticks: {
                              font: {
                                size: 12,
                                family: 'CircularStd-Book',
                              }
                            }
                          },


                        }



                      }}

                  />

                </div>


                <div className="cardchart">
                  <p style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>Evolución Gastos</p>
                  <Line
                    data={datas2}
                    options={{
                      elements: {
                        point: {
                          radius: 4
                        },

                      },
                      lineTension: 0.3,
                      fill: true,
                      fill: {
                        target: 'start',
                        above: 'rgba(65,65,65,0.1)',   // Area will be red above the origin
                        below: 'rgba(65,65,65,0.1)'    // And blue below the origin
                      },
                      borderWidth: 3,
                      showLine: true,

                      backgroundColor: "rgba(255,193,53,0.60)",
                      borderColor: "rgba(0,0,0,0.61)",

                      pointBackgroundColor: "rgba(255,255,255,0.8)",
                      maintainAspectRatio: true,
                      responsive: true,
                      plugins: {
                        tooltip: {
                          mode: 'index',
                          intersect: false,
                          displayColors: false,
                          bodyFont: {
                            size: 13,
                            family: 'CircularStd-Light'
                          },
                          titleFont: {
                            size: 13,
                            family: 'CircularStd-Bold'
                          },
                        },
                        legend: {
                          display: false,
                        }
                      },
                      animation: {
                        duration: 0,
                      },

                      scales: {

                        x: {
                          grid: {
                            display: false,
                          },
                          ticks: {
                            font: {
                              size: 12,
                              family: 'CircularStd-Book',
                            }
                          }
                        },
                        y: {
                          beginAtZero: true,
                          grid: {
                            display: true,
                          },
                          ticks: {
                            font: {
                              size: 12,
                              family: 'CircularStd-Book',
                            }
                          }
                        },


                      }



                    }}
                  />

                </div>

                <div className="cardchart">
                  <p style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>Evolución Beneficio</p>
                  <Line
                    data={datas3}
                    options={{

                      elements: {
                        point: {
                          radius: 4
                        },

                      },
                      tension: 0.3,
                      fill: true,
                      fill: {
                        target: 'start',
                        above: 'rgba(65,65,65,0.1)',   // Area will be red above the origin
                        below: 'rgba(65,65,65,0.1)'    // And blue below the origin
                      },
                      borderWidth: 3,
                      showLine: true,

                      backgroundColor: "rgba(255,193,53,0.60)",
                      borderColor: "rgba(0,0,0,0.61)",

                      pointBackgroundColor: "rgba(255,255,255,0.8)",
                      maintainAspectRatio: true,
                      responsive: true,
                      plugins: {
                        tooltip: {
                          mode: 'index',
                          intersect: false,
                          displayColors: false,
                          bodyFont: {
                            size: 13,
                            family: 'CircularStd-Light'
                          },
                          titleFont: {
                            size: 13,
                            family: 'CircularStd-Bold'
                          },
                        },
                        legend: {
                          display: false,
                        }
                      },
                      animation: {
                        duration: 0,
                      },

                      scales: {

                        x: {
                          grid: {
                            display: false,
                          },
                          ticks: {
                            font: {
                              size: 12,
                              family: 'CircularStd-Book',
                            }
                          }
                        },
                        y: {
                          beginAtZero: true,
                          grid: {
                            display: true,
                          },
                          ticks: {
                            font: {
                              size: 12,
                              family: 'CircularStd-Book',
                            }
                          }
                        },


                      }



                    }}

                  />

                </div>
              </div>

            }
          </div>
        }
      </div>



    );


  }
}
