import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { VscFilePdf } from 'react-icons/vsc';
import { MdOutlineSimCardDownload, MdPictureAsPdf, MdSkipNext, MdSkipPrevious, MdZoomIn, MdZoomOut } from 'react-icons/md';
import axios from 'axios';
import Caryt from '../components/card'
import Select from 'react-select'
import { Bar, Line } from 'react-chartjs-2';
import ApiService from "./ApiService";
import Spinner from "../assets/icons8-loading-infinity.gif"
import BarLoader from "react-spinners/BarLoader";
import '../App.css';
import styled from 'styled-components'
import mipdf from '../assets/test2.pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class BalanceSituacion extends React.Component {

  state = {
    numPages: 0,
    pageNumber: 1,
    mizoom: 1,

    datos: [],
    datoschart: [],
    datoschart2: [],
    datoschart3: [],
    cargando: true,
    cargando2: true,
    selectedValue: 0,
    micliente: '',
    //ESTADOS DEL ÚLTIMO EJERCICIO Y MES DISPONIBLES EN LA BBDD

    mes: '',
    meses: [],
    nombremes: '',
    ejercicio: '',
    ejercicios: [],
    maxmes: '',

    miwidth: '10px',
    mipaginador: '',

  }
  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages });


    //window.open("http://apicore.myleistung.com/pdf/"+this.state.ejercicio+"/"+this.state.mes+"/"+this.state.micliente+"/RICOPIA/balance",'','height=768,width=1024')



  }
  meses = async () => {
    return ApiService.fetchDatosMeses(this.state.micliente, this.state.ejercicio)
      .then(response => {
        // console.log(this.state.ejercicio)

        this.setState(
          {
            meses: response.data
          })



      })
      .catch(error => {


      });

  }
  ejercicios = async () => {
    //console.log(this.state.micliente)
    return ApiService.fetchDatosEjercicios(this.state.micliente)
      .then(response => {

        this.setState(
          {
            maxmes: response.data[0].maxmes, ejercicios: response.data, ejercicio: response.data[0].maxejer, mes: response.data[0].maxmes, nombremes: response.data[0].meses
          })

      })
      .catch(error => {


      });

  };
  async componentDidMount() {
    this.setState({ micliente: sessionStorage.getItem("cliente") });
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(400)
    await this.ejercicios();

    // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    // await sleep(400)

    await this.meses();

    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(400)





    this.setState({ cargando: false })


    // alert(parseInt(this.state.mes))// Ejecutamos la función 

  }
  handleChange = async (e) => {
    this.setState({ cargando: true })
    this.setState({ mes: e.value, nombremes: e.label });
    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    //this.miusuario()

    this.setState({ nombremes: e.label });
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(300)
    this.setState({ cargando: false })
  }

  handleChange2 = async (e) => {
    // alert(e.nombre)
    this.setState({ cargando: true })
    this.setState({ ejercicio: e.value, mes: e.maxmes, nombremes: e.nombre });

    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    this.meses(e.value)

    //  this.miusuario()

    // this.setState({nombremes:e.label});
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(300)
    //alert(this.state.meses)
    this.setState({ mipaginador: this.state.pageNumber + '/' + this.state.numPages })
    this.setState({ cargando: false })
  }


  render() {

    const { pageNumber, numPages, mizoom } = this.state;

    const options =
      this.state.meses.map((dato, i) => {
        return {
          label: dato.meses,
          value: dato.nummes,
          nombre: dato.meses


        }
      }
      );
    const options2 =
      this.state.ejercicios.map((dato, i) => {
        return {
          label: dato.ejercicios,
          value: dato.ejercicios,
          maxmes: dato.maxmes,
          nombre: dato.meses
        }
      }
      );

    const dot = (color = "#395482") => ({
      alignItems: "center",
      display: "flex",
      color: '#395482',
      borderColor: "black",

      ":before": {
        backgroundColor: "#fff",
        // borderRadius: 1,
        // content: '" "',
        // display: "block",
        // marginRight: 8,
        // height: 10,
        // width: 10

      }
    });
    const styles = {

      control: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          fontFamily: 'CircularStd-Bold',
          borderColor: "black",
          boxShadow: 'none',
          border: '2px ',
          borderWidth: "1px",
          borderRadius: "0px",
          backgroundColor: isFocused ? "#39548290" : "#39548240",
          fontSize: '15px', fontColor: 'white', minWidth: '170px', width: '170px', height: '35px',
          ":hover": {
            ...styles[":hover"],
            backgroundColor: "#39548240",

            color: "white",
            borderColor: "white"
          },

        };
      },
      indicatorSeparator: style => ({
        ...style,
        display: 'none',
        color: '#395482'
      }),
      dropdownIndicator: style => ({
        ...style,

        color: '#395482'
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          minWidth: '150px',
          height: '35px',
          borderRadius: '0px',
          backgroundColor: isSelected ? "#00000050" : "#fff",
          // width:'150px',
          color: "#000",
          fontFamily: 'CircularStd-Book',
          cursor: isDisabled ? "not-allowed" : "default",
          ":active": {
            ...styles,
            backgroundColor: "#000000",
            color: 'black',
            borderColor: "black"
          },
          ":hover": {
            ...styles[":hover"],
            backgroundColor: "#00000080",
            color: "white",
            borderColor: "black"
          },

        };
      },

      menu: styles => ({ ...styles, marginTop: '0px', borderRadius: '0px', borderColor: "black", backgroundColor: "white", fontSize: '13px', minWidth: '168px', width: '168px' }),
      input: styles => ({ ...styles, borderColor: "black", borderWidth: "0px" }),
      placeholder: styles => ({ ...styles, ...dot() }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
    };



    return (

      <div style={{ padding: '24px', minWidth: 'calc(100vw- 270px)', width: 'calc(100vw  - 270px)', minHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}>

        {this.state.cargando ?

          <div style={{
            display: 'flex',

            height: '90vh',
            width: 'calc(100vw-250px)',
            justifyContent: 'center',
            alignItems: 'center',


          }}>  <BarLoader color={'#395482'} size={40} />
            {/* <img src={Spinner} style={{width:'50px'}}/> */}
            {/* <i style={{fontSize:25,color:'#4682b4'}} className="fas fa-circle-notch fa-spin" />  */}  </div>
          :
          <div style={{ margin: '10px ', minWidth: 'calc(100vw- 265px)', verticalAlign: 'middle', maxWidth: '1500px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>

              <div style={{ display: 'flex', justifyContent: 'flex-start', width: '60%' }}>
                <div style={{ display: 'flex', fontSize: '32px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>Balance de Situación</div>

                {/* <h3>Dashboard a {this.state.nombremes} de {this.state.ejercicio}</h3> */}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-end', width: '40%' }}>

                <Select
                  isSearchable={false}
                  // value={selectedOption}
                  value={{ value: this.state.ejercicio, label: this.state.ejercicio }}
                  onChange={this.handleChange2}

                  options={options2} styles={styles} placeholder="Ejercicios" /> &nbsp;
                <Select

                  isSearchable={false}
                  //defaultValue={options[1]}
                  value={options.filter(({ value }) => value === this.state.mes)}
                  onChange={this.handleChange}
                  options={options} styles={styles} placeholder="Meses" />

              </div>
              <div style={{ width: 'calc(100%*1)', height: '40px', display: 'flex', color: '#395482', fontSize: '12px', alignItems: 'center', justifyContent: 'center', margin: '0 auto', backgroundColor: 'rgb(183,191,203)', boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 5px' }}>
                <button className="pdf-btn" disabled={pageNumber <= 1} onClick={() => this.setState({ pageNumber: pageNumber - 1 })}><MdSkipPrevious style={{ fontSize: '15px', verticalAlign: 'text-top', marginRight: '0px' }} /></button>
                <input disabled style={{ width: '25px', height: '25px', color: '#395482', textAlign: 'center', fontFamily: 'CircularStd-Light', fontSize: '10px', borderRadius: '2px', border: '0px solid #000' }} value={this.state.pageNumber}></input>&nbsp; de &nbsp;<input disabled style={{ color: '#395482', width: '25px', height: '25px', textAlign: 'center', fontFamily: 'CircularStd-Light', fontSize: '10px', borderRadius: '2px', border: '0px solid #000' }} value={this.state.numPages}></input>
                <button className="pdf-btn" disabled={pageNumber >= numPages} onClick={() => this.setState({ pageNumber: pageNumber + 1 })}><MdSkipNext style={{ fontSize: '15px', verticalAlign: 'text-top', marginRight: '0px' }} /></button>
                <button className="pdf-btn" disabled={mizoom <= 1} onClick={() => this.setState({ mizoom: mizoom - 0.5 })}><MdZoomOut style={{ fontSize: '18px', verticalAlign: 'text-top', marginRight: '0px' }} /></button>
                <button className="pdf-btn" disabled={mizoom >= 3} onClick={() => this.setState({ mizoom: mizoom + 0.5 })}><MdZoomIn style={{ fontSize: '18px', verticalAlign: 'text-top', marginRight: '0px' }} /></button>
                <div> <a href={"https://apicore.myleistung.com/pdf/" + this.state.ejercicio + "/" + this.state.mes + "/" + this.state.micliente + "/" + sessionStorage.getItem("usuario").toUpperCase() + "/balance"} without rel="noopener noreferrer" target="_blank">
                  <button className="pdf-btn">
                    <MdOutlineSimCardDownload style={{ fontSize: '15px', verticalAlign: 'text-top', marginRight: '0px' }} />
                  </button>
                </a></div>
              </div>

              <div style={{ backgroundColor: '#3f3e3e30', padding: '15px', margin: '0 auto', width: 'calc(100%*1)', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>

                {/* <div style={{backgroundColor:'#80808090',width:'100vw ',border: '0px solid #969696',marginTop:'10px',display:'flex',direction:'row',justifyContent:'center'}}> */}
                <div id="test" style={{ overflow: 'hidden', margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                  <PDFDocumentWrapper>
                    <Document
                      file={"https://apicore.myleistung.com/pdf/" + this.state.ejercicio + "/" + this.state.mes + "/" + this.state.micliente + "/" + sessionStorage.getItem("usuario").toUpperCase() + "/balance"
                      }
                      // file={mipdf}
                      //loading={() => 'Cargando...'}
                      onLoadSuccess={this.onDocumentLoadSuccess.bind(this)}
                      renderMode='canvas'

                    >

                      <Page scale={mizoom} pageNumber={pageNumber}
                      />

                    </Document>
                  </PDFDocumentWrapper>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
const PDFDocumentWrapper = styled.div`
 
canvas {
  width: 100% !important;
  height: auto !important;
}
`;
