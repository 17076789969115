import React, {useState,useEffect,useRef} from 'react';
import './card.css'
import {Link,useHistory} from "react-router-dom";

function CardForm(props) {

  let history= useHistory();
 
  function link(){
    // alert(props.ejercicio+props.mes+props.cliente+props.epigrafe+props.concepto)
    history.push({
      pathname: '/cuentas',
      // state: { ejercicio: props.ejercicio, mes: props.mes, cliente: props.cliente, epigrafe: props.epigrafe, concepto: props.concepto }
      state:{ejercicio: props.ejercicio, mes: props.mes, cliente: props.cliente, epigrafe: props.epigrafe, concepto: props.concepto,pagina:props.pagina}
    })
  }
 
return(

  <tr>
    <td width='50%' align='left' style={{backgroundColor:props.estilo == "A" ? 'rgba(198,206,219,0.5)': props.estilo=="B"?'': '',fontFamily: props.estilo == "A"? 'CircularStd-Bold': props.estilo=="B"?'CircularStd-Bold': props.estilo=="C"?'': '',color:props.estilo == "A"? '#000000':'#000000',fontSize:props.estilo == "A"? '17px':props.estilo == "B"? '17px':'15px'}}>{props.titulo}</td>
    <td data-handle={props.epigrafehead} width='20%' align='right' style={{backgroundColor:props.estilo == "A" ? 'rgba(198,206,219,0.5)': props.estilo=="B"?'': '',fontFamily: props.estilo == "A"? 'CircularStd-Bold': props.estilo=="B"?'CircularStd-Bold': props.estilo=="C"?'': '',color:props.estilo == "A"? '#000000':'#000000',fontSize:props.estilo == "A"? '17px':props.estilo == "B"? '17px':'15px'}}>
     {props.estilo=="A" ? props.valor:
    
        <input onChange={props.handleChange}></input> 
    
        }
    </td>
    
  </tr>
              // <div className="card3">
              //     <div className="card-content">
              //     <div style={{display: 'flex',flexDirection: 'row'}}>

              //  <span style={{display:'flex',height:'25px',width:'25px', textAlign:'center',
              //   fontFamily:'CircularStd-Book', justifyContent:'center',alignItems:'center', color:'#ffffff',backgroundColor:'#00000090',borderRadius:'35px',fontSize:'18px'}}>{props.posicion}</span>  
              //  <span style={{display:'flex',height:'25px',justifyContent:'center',alignItems:'center', paddingLeft:'10px',fontSize:'22px',fontFamily:'CircularStd-Bold',color:'#000000'}}>{props.titulo}</span>  
              //  </div>
              //       <div style={{paddingLeft:'35px',fontSize:'20px',fontFamily:'CircularStd-Light',color:'black'}}>{props.valor} €</div>
              //       <div style={{paddingLeft:'35px',fontFamily:'CircularStd-Light',fontSize:'14px',fontWeight:'normal',color:'gray'}}> {props.mes}: {props.comentario } €</div>
              //     </div>
              // </div>
);
}
export default CardForm;