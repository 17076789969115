import React from 'react';
import './card.css';

import  {HiChartBar,HiOutlineDotsVertical,HiOutlineDotsCircleHorizontal,HiOutlineCubeTransparent} from 'react-icons/hi';
import EllipsisText from "react-ellipsis-text";


function Card(props) {

return(


  <tr>
    <td width='30%' align='left' style={{color:'#000'}}>{props.titulo}</td>
    <td width='50%' align='left' style={{textTransform:'none'}}>{props.acumulado.charAt(0).toUpperCase() + props.acumulado.slice(1)}</td> 
    <td width='10%' align='right'>{props.anterior} €</td>
    <td width='10%' align='right'>{props.actual} €</td>
  </tr>
  



          // <div className="card7">
          //     <div>
          //       <div style={{display:'flex', fontSize:'22px',fontFamily:'CircularStd-Light',color:'#000000'}}>({props.titulo})&nbsp;&nbsp;
          //       <div style={{display:'flex', fontSize:'22px',fontFamily:'CircularStd-Light',color:'black',textTransform:'capitalize'}}><EllipsisText text={props.acumulado.toLowerCase()} length={"35"} /> </div>     
                
                                        
          //                 </div>  
          //             </div>  
          //             <Link to={{pathname:"/cuentas",state:{ejercicio:props.ejercicio,mes:props.mes,cliente:props.cliente,epigrafe:props.epigrafe}}} style={{textDecoration: 'none'}}>
          //                 <HiOutlineDotsVertical style={{
          //                   fontSize:'22px',float:'right',marginTop:'-30px',
          //                   color: '#000000'}}/> 
          //             </Link>
          //         <div style={{fontSize:'14px',fontFamily:'CircularStd-Light',color:'gray'}}>{props.mes}: {props.actual} €</div>
                 
          //        <div style={{width:'100%'}}>
          //           <div style={{float:'left',fontSize:'14px',fontFamily:'CircularStd-Light',color:'gray'}}>{props.anterior} €</div>
              
                   
          //        </div>
                 
          //        </div>
               
             
             
          
);
}
export default Card;