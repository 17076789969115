import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Caryt from '../components/card2'
import Select from 'react-select'
import ApiService from "./ApiService";
import Spinner from "../assets/icons8-loading-infinity.gif"
import BarLoader from "react-spinners/BarLoader";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Bar, Line, Scatter } from 'react-chartjs-2';
import Numeral from "numeral";
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import '../App.css';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart, LinearScale } from 'chart.js';
import { VscFilePdf } from 'react-icons/vsc';


export default class Ratios extends React.Component {
  
  state = {
    datos: [],
    filter: '',
    cargando: true,
    micliente: '',
    show: false,
    nombreratio: '',
    mes: '',
    meses: [],
    nombremes: '',
    ejercicio: '',
    ejercicios: [],
    maxmes: '',
    valor: 1,
    datoschart: [],
    tipo:1,
    ratio:'',
    colorines:[]
  }
  descargaPdf= async ()=>{
    
    var a = document.createElement('a');
    a.href = "https://apicore.myleistung.com/inforatios/" + this.state.ejercicio + "/" + this.state.mes + "/" + this.state.micliente + "/" + sessionStorage.getItem("usuario").toUpperCase();
    a.download = 'download';
    a.click();
  }
  handleDelete = async (e) => {
    // alert(e.currentTarget.id)
    this.setState({ nombreratio: e.currentTarget.title,ratio:e.currentTarget.id })
    
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(400)
    this.michart(this.state.ratio)
    this.setState({ show: true })
  };
  handleChangeb = async (val)  => {
    this.setState({valor: val,tipo:val})
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(400)
    
    this.michart(this.state.ratio)
      };
  handleClose = async () => { this.setState({ show: false,tipo:1,valor:1,datoschart:[] }) }
  handleShow = async () => { this.setState({ show: true }) }
  meses = async () => {
    return ApiService.fetchDatosMeses(this.state.micliente, this.state.ejercicio)
      .then(response => {
        // console.log(this.state.ejercicio)

        this.setState(
          {
            meses: response.data
          })



      })
      .catch(error => {


      });

  }
  ejercicios = async () => {
    //console.log(this.state.micliente)
    return ApiService.fetchDatosEjercicios(this.state.micliente)
      .then(response => {

        this.setState(
          {
            maxmes: response.data[0].maxmes, ejercicios: response.data, ejercicio: response.data[0].maxejer, mes: response.data[0].maxmes, nombremes: response.data[0].meses
          })

      })
      .catch(error => {


      });

  };

  miusuario = async () => {
    axios.get('https://apicore.myleistung.com/ratios/' + this.state.ejercicio + '/' + this.state.micliente + '/' + this.state.mes, {

    })
      .then(res => {

        this.setState({ datos: res.data })

      })
      .catch(function (error) {
        console.log(error);
      })





  };
  
  michart = async (ratio) => {
    axios.get('https://apicore.myleistung.com/ratiosevol/' + ratio + '/' + this.state.micliente + '/' + this.state.mes + '/' + this.state.ejercicio+ '/' + this.state.tipo, {

    })
      .then(res => {

        this.setState({ datoschart: res.data })
        
      })
      .catch(function (error) {
        console.log(error);
      })





  };
  isNum(val) {
    return !isNaN(val)
  }
  // Obtenemos datos desde la API 
  async componentDidMount() {
    this.setState({ micliente: sessionStorage.getItem("cliente") });
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(400)
    await this.ejercicios();
    await this.meses();
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(400)

    


    await this.miusuario();
    const sleep3 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep3(400)
    this.setState({ cargando: false })

    
    // alert(parseInt(this.state.mes))// Ejecutamos la función 

  }
  handleChange = async (e) => {
    this.setState({ cargando: true })
    this.setState({ mes: e.value, nombremes: e.label });
    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    this.miusuario()

    this.setState({ nombremes: e.label });
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(300)
    this.setState({ cargando: false })
  }

  handleChange2 = async (e) => {
    // alert(e.nombre)
    this.setState({ cargando: true })
    this.setState({ ejercicio: e.value, mes: e.maxmes, nombremes: e.nombre });

    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    this.meses(e.value)

    this.miusuario()

    // this.setState({nombremes:e.label});
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(300)
    //alert(this.state.meses)
    this.setState({ cargando: false })
  }

  change = event => {
    this.setState({ filter: event.target.value });

  }
  handleKeyDown = e => {
    e.currentTarget.value = e.currentTarget.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
  };
  render() {
//GRAFICA


Chart.register(annotationPlugin);
    const { filter, datos } = this.state;
    const lowercasedFilter = filter.toLowerCase();
    const filteredData = datos.filter(item => {
      return Object.keys(item).some(key =>
        typeof item[key] === "string" && item[key].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(lowercasedFilter)
      );
    });
    
    
    const datas = this.state.datoschart.length > 0 ?{
      labels: this.state.datoschart.map((dato) => this.isNum(dato.periodo)?dato.periodo:dato.periodo.toUpperCase().substring(0, 3)),//dato.periodo.toUpperCase().substring(0, 3)),
   
      datasets: [
        {
         
          label: this.state.nombreratio,
          backgroundColor: '#4682b4',
          // borderColor: '#4682b4',
          // borderWidth: 1,
          hoverBackgroundColor: Object.assign([],this.state.datoschart.map((dato,index) =>
          (dato.semaforo=='verde'?'green':dato.semaforo=='rojo'?'red':dato.semaforo=='naranja'?'orange':'gray'))),
          hoverBorderColor: Object.assign([],this.state.datoschart.map((dato,index) =>
          (dato.semaforo=='verde'?'green':dato.semaforo=='rojo'?'red':dato.semaforo=='naranja'?'orange':'gray'))),
          data: this.state.datoschart.map((dato) =>
            // parseInt(dato.resultado).toFixed(2))
            Numeral((dato.resultado).replace(/,/g, '.')).format('0.00')),
            pointRadius: 5,
            pointHoverRadius: 5,
          pointBackgroundColor:Object.assign([],this.state.datoschart.map((dato,index) =>
          (dato.semaforo=='verde'?'green':dato.semaforo=='rojo'?'red':dato.semaforo=='naranja'?'orange':'gray'))),
       // order:2,
          },
          
          // {
         
          //   label: this.state.nombreratio,
          //   backgroundColor: '#4682b4',
          //   // borderColor: '#4682b4',
          //   // borderWidth: 1,
          //   hoverBackgroundColor: Object.assign([],this.state.datoschart.map((dato,index) =>
          //   (dato.semaforo=='verde'?'green':dato.semaforo=='rojo'?'red':dato.semaforo=='naranja'?'orange':'gray'))),
          //   hoverBorderColor: Object.assign([],this.state.datoschart.map((dato,index) =>
          //   (dato.semaforo=='verde'?'green':dato.semaforo=='rojo'?'red':dato.semaforo=='naranja'?'orange':'gray'))),
          //   data: this.state.datoschart.map((dato) =>
          //     // parseInt(dato.resultado).toFixed(2))
          //     Numeral((dato.resultado).replace(/,/g, '.')).format('0.00')),
          //     pointRadius: 5,
          //     pointHoverRadius: 5,
          //   pointBackgroundColor:Object.assign([],this.state.datoschart.map((dato,index) =>
          //   (dato.semaforo=='verde'?'green':dato.semaforo=='rojo'?'red':dato.semaforo=='naranja'?'orange':'gray'))),
          //   type: 'bar',
          //   order:1
          
          //   }

      ],
      
    }:{
      labels: [],
      datasets: [
        {
         
          label: this.state.nombreratio,
          backgroundColor: '#4682b4',
          // borderColor: '#4682b4',
          // borderWidth: 1,
       
          data: [],
            pointRadius: 5,
            pointHoverRadius: 5,
         
         
       // order:2,
          },
          
          // {
         
          //   label: this.state.nombreratio,
          //   backgroundColor: '#4682b4',
          //   // borderColor: '#4682b4',
          //   // borderWidth: 1,
          //   hoverBackgroundColor: Object.assign([],this.state.datoschart.map((dato,index) =>
          //   (dato.semaforo=='verde'?'green':dato.semaforo=='rojo'?'red':dato.semaforo=='naranja'?'orange':'gray'))),
          //   hoverBorderColor: Object.assign([],this.state.datoschart.map((dato,index) =>
          //   (dato.semaforo=='verde'?'green':dato.semaforo=='rojo'?'red':dato.semaforo=='naranja'?'orange':'gray'))),
          //   data: this.state.datoschart.map((dato) =>
          //     // parseInt(dato.resultado).toFixed(2))
          //     Numeral((dato.resultado).replace(/,/g, '.')).format('0.00')),
          //     pointRadius: 5,
          //     pointHoverRadius: 5,
          //   pointBackgroundColor:Object.assign([],this.state.datoschart.map((dato,index) =>
          //   (dato.semaforo=='verde'?'green':dato.semaforo=='rojo'?'red':dato.semaforo=='naranja'?'orange':'gray'))),
          //   type: 'bar',
          //   order:1
          
          //   }

      ],

    };
    const options =
      this.state.meses.map((dato, i) => {
        return {
          label: dato.meses,
          value: dato.nummes,
          nombre: dato.meses


        }
      }
      );
    const options2 =
      this.state.ejercicios.map((dato, i) => {
        return {
          label: dato.ejercicios,
          value: dato.ejercicios,
          maxmes: dato.maxmes,
          nombre: dato.meses
        }
      }
      );

      const dot = (color = "rgb(255,193,53)") => ({
        alignItems: "center",
        display: "flex",
        color: '#000000',
        borderColor: "black",
  
        ":before": {
          backgroundColor: "#fff",
          // borderRadius: 1,
          // content: '" "',
          // display: "block",
          // marginRight: 8,
          // height: 10,
          // width: 10
  
        }
      });
      const styles = {
  
        control: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            fontFamily: 'CircularStd-Bold',
            borderColor: "black",
            boxShadow: 'none',
            border: '2px ',
            borderWidth: "1px",
            borderRadius: "5px",
            backgroundColor: isFocused ? "rgba(255,193,53,1)" : "rgba(255,193,53,1)",
            fontSize: '15px', fontColor: 'white', minWidth: '170px', width: '170px', height: '35px',
            ":hover": {
              ...styles[":hover"],
              backgroundColor: "rgba(255,193,53,0.30)",
  
              color: "white",
              borderColor: "white"
            },
  
          };
        },
        indicatorSeparator: style => ({
          ...style,
          display: 'none',
          color: '#000000'
        }),
        dropdownIndicator: style => ({
          ...style,
  
          color: '#000000'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            minWidth: '150px',
            height: '35px',
            borderRadius: '0px',
            
            backgroundColor: isSelected ? "rgba(255,193,53,0.60)" : "#fff",
            // width:'150px',
            color: "#000",
            fontFamily: 'CircularStd-Book',
            cursor: isDisabled ? "not-allowed" : "default",
            ":active": {
              ...styles,
              backgroundColor: "#000000",
              color: 'black',
              borderColor: "black"
            },
            ":hover": {
              ...styles[":hover"],
              backgroundColor: "rgba(255,193,53,0.30)",
              color: "black",
              borderColor: "black"
            },
  
          };
        },
  
        menu: styles => ({ ...styles, marginTop: '3px', borderRadius: '5px', borderColor: "black", backgroundColor: "white", fontSize: '13px', minWidth: '168px', width: '168px' }),
        input: styles => ({ ...styles, borderColor: "black", borderWidth: "0px" }),
        placeholder: styles => ({ ...styles, ...dot() }),
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
      };


    return (
      <div style={{ padding: '24px', minWidth: 'calc(100vw- 265px)', width: 'calc(100vw  - 265px)', minHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}>




        {this.state.cargando ?

          <div style={{
            display: 'flex',

            height: '90vh',
            width: 'calc(100vw-250px)',
            justifyContent: 'center',
            alignItems: 'center',


          }}>  <BarLoader color={'#000'} size={40} />
            {/* <img src={Spinner} style={{width:'50px'}}/> */}
            {/* <i style={{fontSize:25,color:'#4682b4'}} className="fas fa-circle-notch fa-spin" />  */}  </div>
          :



          <div style={{ margin: '10px ', minWidth: 'calc(100vw- 265px)', verticalAlign: 'middle', maxWidth: '1500px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>

              <div style={{ display: 'flex', justifyContent: 'flex-start', width: '60%' }}>
                <div style={{ fontSize: '32px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>Análisis de Ratios</div>
                {/* <h3>Dashboard a {this.state.nombremes} de {this.state.ejercicio}</h3> */}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-end', width: '40%' }}>
              <div style={{display:'flex',borderRadius:'5px',border:'1px solid #39548240'}}>
                     <button className="boton" onClick={this.descargaPdf}><VscFilePdf size={20} style={{marginTop:'-3px',lineHeight:'20px'}}/></button>
                 
                </div>  &nbsp;&nbsp;  
                <Select
                  isSearchable={false}
                  // value={selectedOption}
                  value={{ value: this.state.ejercicio, label: this.state.ejercicio }}
                  onChange={this.handleChange2}

                  options={options2} styles={styles} placeholder="Ejercicios" /> &nbsp;
                <Select

                  isSearchable={false}
                  //defaultValue={options[1]}
                  value={options.filter(({ value }) => value === this.state.mes)}
                  onChange={this.handleChange}
                  options={options} styles={styles} placeholder="Meses" />

              </div>

            </div>
            <div style={{ marginTop: '10px', marginBottom: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>

              <input className="documentosinput"
                ref={(input) => { this.secondText = input; }}

                type='text'
                placeholder="Buscar..."
                onKeyDown={this.handleKeyDown}
                onChange=
                {this.change}
              />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>


              {this.state.datos.length > 0 && filteredData.map((dato, i) =>

                <Caryt
                  titulo={dato.ratio}
                  valor={dato.valor}
                  nombreratio={dato.ratio}
                  comentario={dato.recomendacion}
                  semaforo={dato.semaforo}
                  Id={dato.Id}
                  call={this.handleDelete}
                />

              )}




            </div>
          </div>

        } 
      
        <>
       
          <Modal aria-labelledby="contained-modal-title-vcenter" 
             show={this.state.show} onHide={this.handleClose} dialogClassName="custom-modal-style" style={{ fontFamily: 'CircularStd-Book'}}>
            <Modal.Header closeButton style={{backgroundColor:'rgba(154, 152, 152,0.2)'}}>
              <div style={{ display: 'flex', float: 'right', width: '80%' }}>
                <Modal.Title id="contained-modal-title-vcenter" style={{ fontFamily: 'CircularStd-Bold', color: '#000000' }}>{this.state.nombreratio.length > 20 ? this.state.nombreratio.substring(0, 20) + "...":this.state.nombreratio} {this.state.colorines}</Modal.Title>
              </div> &nbsp;&nbsp;
              <div style={{ display: 'flex', float: 'right', width: '20%',marginRight:'45px' }}>
                <ToggleButtonGroup type="radio" name="options" defaultValue={this.state.valor} onChange={this.handleChangeb}>
                  <ToggleButton id="tbg-btn-1" value={1} size= 'sm'  variant='outline-secondary' className='button-tab'>
                    Mensual
                  </ToggleButton>
                  <ToggleButton  id="tbg-btn-2" value={2} size= 'sm'  variant='outline-secondary' className='button-tab'>
                    Anual
                  </ToggleButton>

                </ToggleButtonGroup>
              </div >
            </Modal.Header>
            <Modal.Body style={{ backgroundColor:'rgba(184,181,181,.12)', fontFamily: 'CircularStd-Light', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
 
              <div className="cardchart2" style={{ border: '0' }}>

             
                <Line
                  data={datas}
                  options={

 
                    {

                      elements: {
                        point: {
                         pointStyle:'circle',
                          borderWidth:0
                        },

                      },
                      tension: 0.3,
                      fill: true,
                      fill: {
                        target: 'start',
                        above: 'rgba(65,65,65,0.11)',   // Area will be red above the origin
                        below: 'rgba(65,65,65,1)'    // And blue below the origin
                      },
                      borderWidth: 2,
                      showLine: true,

                      backgroundColor: "rgba(255,193,53,0.60)",
                      borderColor: "rgba(0,0,0,0.61)",

                      pointBackgroundColor: "rgba(255,255,255,0.8)",
                      maintainAspectRatio: true,

                      responsive: true,

                      plugins: {
                        // annotation: {
                        //   drawTime: 'beforeDatasetsDraw',
                        //   annotations: {
                        //     //
                        //     box1: {
                        //       type: 'box',
                        //       xMin: 0,
                        //       borderWidth:0,
                        //       yMin: 16,
                        //       // yMax: 1,
                        //       backgroundColor: 'rgba(0,119,4,1)',
                             
                        //     },
                        //     box2: {
                        //       type: 'box',
                        //       xMin: 0,
                        //       borderWidth:0,
                        //       yMin: 15,
                        //       // yMax: 1,
                        //       backgroundColor: 'rgba(255,41,55,1)'
                        //     },
                        //     box3: {
                        //       type: 'box',
                        //       xMin: 0,
                        //       borderWidth:0,
                        //       yMin: 1.,
                        //       // yMax: 1.5,
                        //       backgroundColor: 'rgba(255,153,15,1)'
                        //     },
                        //   }
                          
                        // },
                        tooltip: {
                          mode: 'index',
                          intersect: false,
                          displayColors: false,
                          bodyFont: {
                            size: 13,
                            family: 'CircularStd-Light'
                          },
                          titleFont: {
                            size: 13,
                            family: 'CircularStd-Bold'
                          },
                        },
                        legend: {
                          display: false,
                        }
                      },
                      animation: true,
                      animation: {
                        duration: 500,
                    },
                      scales: {

                        x: {
                          
                          grid: {
                            display: false,
                          },
                          ticks: {
                            font: {
                              size: 12,
                              family: 'CircularStd-Book',
                            }
                          }
                        },
                        y: {
                          stacked: true,
                          beginAtZero: true,
                          grid: {
                            display: true,
                            
                           
                          },
                          ticks: {
                            font: {
                              size: 12,
                              family: 'CircularStd-Book',
                            }
                          }
                        },
                       

                      }



                    }}

                />

              </div>

            </Modal.Body>
          </Modal>
        </>
  
      </div>

    );


  } 
}