import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Caryt from '../components/carddueda'
import CarCli from '../components/cardcli'
import Select from 'react-select'
import { Bar, Line } from 'react-chartjs-2';
import ApiService from "./ApiService";
import Spinner from "../assets/icons8-loading-infinity.gif"
import BarLoader from "react-spinners/BarLoader";
import '../components/card.css';
import styled from 'styled-components';
import { HiOutlineExclamation, HiOutlineEyeOff, HiOutlineEye, HiOutlineCalendar, HiOutlineBriefcase, HiOutlineDatabase, HiOutlineCog, HiOutlineDocumentText, HiOutlineTrendingUp, HiOutlineDocumentDuplicate, HiOutlineCurrencyEuro, HiOutlineCash, HiOutlineCalculator, HiOutlineChartSquareBar, HiChevronRight, HiOutlineArrowCircleRight, HiOutlineChartBar, HiOutlineArrowSmRight, HiOutlinePresentationChartLine, HiOutlineFolder, HiOutlineChartPie } from 'react-icons/hi';
import Modal from 'react-bootstrap/Modal'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import EllipsisText from "react-ellipsis-text";



export default class Dashboard extends React.Component {


    // Estado inicial del usuario
    state = {
        ver: false,
        datos: [],
        datosc: [],
        datoschart: [],
        datoschart2: [],
        datoschart3: [],
        cargando: true,
        cargando2: true,
        selectedValue: null,
        micliente: sessionStorage.getItem("usuario"),
        //ESTADOS DEL ÚLTIMO EJERCICIO Y MES DISPONIBLES EN LA BBDD
        show: false,
        mes: '',
        meses: [],
        nombremes: '',
        ejercicio: '',
        ejercicios: [],
        maxmes: '',
        //CLIENTES
        filter: '',
        clientes: [],
        nombrecliente: '',
        idcliente: '',
        datoscliente: []
    }
    //MOSTRAR MODAL
    mostrarmodal = async (e) => {
        //alert(e.currentTarget.id + e.currentTarget.title)

        this.setState({ nombrecliente: e.currentTarget.title, idcliente: e.currentTarget.id })
        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        await sleep(100)

        this.misclienteindividual()
        const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
        await sleep2(100)
        this.setState({ show: true })
    };
    handleClose = async () => { this.setState({ show: false, datoscliente: [] }) }
    handleShow = async () => { this.setState({ show: true }) }
    // Permite obtener y modificar el estado
    meses = async () => {
        return ApiService.fetchDatosMeses(this.state.micliente, this.state.ejercicio)
            .then(response => {
                // console.log(this.state.ejercicio)

                this.setState(
                    {
                        meses: response.data
                    })



            })
            .catch(error => {


            });

    }
    ejercicios = async () => {
        //console.log(this.state.micliente)
        return ApiService.fetchDatosEjercicios(this.state.micliente)
            .then(response => {

                this.setState(
                    {
                        maxmes: response.data[0].maxmes, ejercicios: response.data, ejercicio: response.data[0].maxejer, mes: response.data[0].maxmes, nombremes: response.data[0].meses
                    })

            })
            .catch(error => {


            });

    };

    miusuario = async () => {
        return ApiService.fetchDatosDeuda(this.state.micliente)
            .then(res => {

                this.setState({ datos: res.data })

            })
            .catch(function (error) {
                console.log(error);
            })

    };

    misclientes = async () => {
        return ApiService.fetchDatosDeudaClientes(this.state.micliente)
            .then(res => {

                this.setState({ clientes: res.data })

            })
            .catch(function (error) {
                console.log(error);
            })

    };
    misclienteindividual = async () => {
        // alert(this.state.idcliente)
        return ApiService.fetchDatosDeudaIndividual(this.state.micliente.toLocaleUpperCase(), this.state.idcliente)
            .then(res => {

                this.setState({ datoscliente: res.data })

            })
            .catch(function (error) {
                console.log(error);
            })

    };
    michart = async () => {
        return ApiService.fetchDatosDeudaChart(this.state.micliente)
            .then(res => {

                this.setState({ datoschart: res.data, datoschart2: res.data, datoschart3: res.data })


            })
            .catch(function (error) {
                console.log(error);
            })





    };

    cargaClientes = async () => {
        await this.misclientes()
        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        await sleep(400)
        this.setState({ cargando: false })
        this.setState({ cargando2: false })
    }
    // Obtenemos datos desde la API
    async componentDidMount() {

        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        await sleep(400)

        await this.miusuario();


        await this.michart();
      
      // alert(this.state.datoschart.length)
        const sleep3 = ms => new Promise(resolve => setTimeout(resolve, ms))
        await sleep3(400)
        // await this.misclientes();
        const sleep4 = ms => new Promise(resolve => setTimeout(resolve, ms))
        await sleep(400)
        this.setState({ cargando: false })
        this.setState({ cargando2: false })

        // alert(parseInt(this.state.mes))// Ejecutamos la función 

    }
    handleChange = async (e) => {
        this.setState({ cargando: true })
        this.setState({ mes: e.value, nombremes: e.label });
        //alert(e.value)
        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        await sleep(300)

        await this.miusuario()
        await this.michart();
        this.setState({ nombremes: e.label });
        const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
        await sleep2(300)
        await this.misclientes();
        this.setState({ cargando: false })
    }

    handleChange2 = async (e) => {
        // alert(e.nombre)
        this.setState({ cargando: true })
        this.setState({ ejercicio: e.value, mes: e.maxmes, nombremes: e.nombre });

        //alert(e.value)
        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        await sleep(300)

        await this.meses(e.value)

        await this.miusuario()
        await this.michart();
        // this.setState({nombremes:e.label});
        const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
        await sleep2(300)
        await this.misclientes();
        //alert(this.state.meses)
        this.setState({ cargando: false })
    }

    change = event => {
        this.setState({ filter: event.target.value });

    }
    handleKeyDown = e => {
        e.currentTarget.value = e.currentTarget.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
    };


    render() {
      

        const datas = this.state.datoschart.length > 0 ?  {
            labels: this.state.datoschart.map((dato) => dato.mes.substring(0, 3)),
            datasets: [
                {
                    label: 'No Vencida',
                    backgroundColor: 'rgba(65,65,65,0.21)',
                    //  borderColor: '#4682b4',
                    //borderWidth: 1,
                    hoverBackgroundColor: 'rgba(0,0,0,0.21)',
                    hoverBorderColor: 'rgba(0,0,0,0.31)',
                    data: this.state.datoschart.map((dato) => parseInt(dato.novencida) / 1)
                }
            ]
        }:{
            labels: [],
            datasets: [
                {
                    label: 'No Vencida',
                    backgroundColor: 'rgba(65,65,65,0.21)',
                    //  borderColor: '#4682b4',
                    //borderWidth: 1,
                    hoverBackgroundColor: 'rgba(0,0,0,0.21)',
                    hoverBorderColor: 'rgba(0,0,0,0.31)',
                    data: []
                }
            ]

        };
        const datas2 =this.state.datoschart.length > 0 ? {
            labels: this.state.datoschart.map((dato) => dato.mes.substring(0, 3)),
            datasets: [
                {
                    label: 'Vencida',
                    backgroundColor: 'rgba(65,65,65,0.21)',
                    //  borderColor: '#4682b4',
                    //borderWidth: 1,
                    hoverBackgroundColor: 'rgba(0,0,0,0.21)',
                    hoverBorderColor: 'rgba(0,0,0,0.31)',
                    data: this.state.datoschart.map((dato) => parseInt(dato.vencida) / 1)
                }
            ]
        }:{
            labels: [],
            datasets: [
                {
                    label: 'Vencida',
                    backgroundColor: 'rgba(65,65,65,0.21)',
                    //  borderColor: '#4682b4',
                    //borderWidth: 1,
                    hoverBackgroundColor: 'rgba(0,0,0,0.21)',
                    hoverBorderColor: 'rgba(0,0,0,0.31)',
                    data: []
                }
            ]
        };
        const datas3 = this.state.datoschart.length > 0 ?{
            labels: this.state.datoschart.map((dato) => dato.mes.substring(0, 3)),
            datasets: [
                {
                    label: 'Total',
                    backgroundColor: 'rgba(65,65,65,0.21)',
                    //  borderColor: '#4682b4',
                    //borderWidth: 1,
                    hoverBackgroundColor: 'rgba(0,0,0,0.21)',
                    hoverBorderColor: 'rgba(0,0,0,0.31)',
                    data: this.state.datoschart.map((dato) => parseInt(dato.deudatotal) / 1)
                }
            ]
        }:{
            labels: [],
            datasets: [
                {
                    label: 'Total',
                    backgroundColor: 'rgba(65,65,65,0.21)',
                    //  borderColor: '#4682b4',
                    //borderWidth: 1,
                    hoverBackgroundColor: 'rgba(0,0,0,0.21)',
                    hoverBorderColor: 'rgba(0,0,0,0.31)',
                    data: []
                }
            ]
        };
        //OPCIONES SELECT  Y ESTILOS SELECT


        const options =
            this.state.meses.map((dato, i) => {
                return {
                    label: dato.meses,
                    value: dato.nummes,
                    nombre: dato.meses


                }
            }
            );
        const options2 =
            this.state.ejercicios.map((dato, i) => {
                return {
                    label: dato.ejercicios,
                    value: dato.ejercicios,
                    maxmes: dato.maxmes,
                    nombre: dato.meses
                }
            }
            );

        const dot = (color = "#395482") => ({
            alignItems: "center",
            display: "flex",
            color: '#395482',
            borderColor: "black",

            ":before": {
                backgroundColor: "#fff",
                // borderRadius: 1,
                // content: '" "',
                // display: "block",
                // marginRight: 8,
                // height: 10,
                // width: 10

            }
        });
        const styles = {

            control: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                    ...styles,
                    fontFamily: 'CircularStd-Bold',
                    borderColor: "black",
                    boxShadow: 'none',
                    border: '2px ',
                    borderWidth: "1px",
                    borderRadius: "0px",
                    backgroundColor: isFocused ? "#39548290" : "#39548240",
                    fontSize: '15px', fontColor: 'white', minWidth: '170px', width: '170px', height: '35px',
                    ":hover": {
                        ...styles[":hover"],
                        backgroundColor: "#39548240",

                        color: "white",
                        borderColor: "white"
                    },

                };
            },
            indicatorSeparator: style => ({
                ...style,
                display: 'none',
                color: '#395482'
            }),
            dropdownIndicator: style => ({
                ...style,

                color: '#395482'
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                    ...styles,
                    minWidth: '150px',
                    height: '35px',
                    borderRadius: '0px',
                    backgroundColor: isSelected ? "#00000050" : "#fff",
                    // width:'150px',
                    color: "#000",
                    fontFamily: 'CircularStd-Book',
                    cursor: isDisabled ? "not-allowed" : "default",
                    ":active": {
                        ...styles,
                        backgroundColor: "#000000",
                        color: 'black',
                        borderColor: "black"
                    },
                    ":hover": {
                        ...styles[":hover"],
                        backgroundColor: "#00000080",
                        color: "white",
                        borderColor: "black"
                    },

                };
            },

            menu: styles => ({ ...styles, marginTop: '0px', borderRadius: '0px', borderColor: "black", backgroundColor: "white", fontSize: '13px', minWidth: '168px', width: '168px' }),
            input: styles => ({ ...styles, borderColor: "black", borderWidth: "0px" }),
            placeholder: styles => ({ ...styles, ...dot() }),
            singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
        };

        const { filter, clientes } = this.state;
        const lowercasedFilter = filter.toLowerCase();
        const filteredData = clientes.length > 0 ? clientes.filter(item => {
            return Object.keys(item).some(key =>
                typeof item[key] === "string" && item[key].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(lowercasedFilter)
            );
        }) : '';

        return (
            <div style={{ padding: '24px', minWidth: 'calc(100vw- 265px)', width: 'calc(100vw  - 265px)', minHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}>




                {this.state.cargando  ?

                    <div style={{
                        display: 'flex',

                        height: '90vh',
                        width: 'calc(100vw-250px)',
                        justifyContent: 'center',
                        alignItems: 'center',


                    }}>
                        <BarLoader color={'#000'} size={40} />
                        {/* <img src={Spinner} style={{width:'50px'}}/> */}
                        {/* <i style={{fontSize:25,color:'#4682b4'}} className="fas fa-circle-notch fa-spin" />  */}
                    </div>
                    :

                    
                       
                    <div style={{ margin: '10px ', minWidth: 'calc(100vw- 265px)', verticalAlign: 'middle', maxWidth: '1500px' }}>
                        <div style={{ display: 'flex' }}>

                            <div style={{ display: 'flex', justifyContent: 'flex-start', backgroundColor: '', width: '60%' }}>
                                <div style={{ display: 'flex', fontSize: '32px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>Cartera Clientes</div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-end', width: '40%' }}>
                                <div
                                    style={{
                                        pointerEvents: this.state.datoschart.length > 0 ? 'auto' : 'none',
                                        opacity: this.state.datos.length > 0 ? '1' : '0',
                                        //padding: '7px 10px 10px 38px',
                                        display: 'flex', flexWrap: 'wrap', flexDirection: 'row',
                                        cursor: 'pointer',
                                       
                                        border: '0px',
                                        justifyContent: 'center',
                                        borderRadius: '5px',
                                        color: "#000",
                                        backgroundColor: "rgba(255,193,53,1)",
                                        fontFamily: 'CircularStd-Bold', fontSize: '15px', minWidth: '130px', width: '135px', height: '38px',
                                    }} onClick={() => {

                                        this.state.ver === true
                                            ? this.setState({ ver: false, clientes: [] })
                                            : this.setState({ ver: true, cargando: true }, this.cargaClientes)
                                    }}>
                                    <div style={{  lineHeight: '38px', textAlign: 'left', backgroundColor: '' }}>{this.state.ver ? <HiOutlineEyeOff size={'20px'} /> : <HiOutlineEye size={'20px'} />}</div>&nbsp;&nbsp;<div style={{ lineHeight: '40px' }}>Ver Clientes</div>

                                </div>
                            </div>



                        </div>
                        <div>
                            {this.state.ver === true &&
                                <>
                                    <div style={{ marginTop: '10px', marginBottom: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>

                                        <input className="documentosinput"
                                            ref={(input) => { this.secondText = input; }}

                                            type='text'
                                            placeholder="Buscar..."
                                            onKeyDown={this.handleKeyDown}
                                            onChange={this.change}
                                        />
                                    </div>
                                    <div style={{ backgroundColor: '', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>
                                        <div style={{ position: 'relative', height: '555px', overflow: 'auto', display: 'block' }}>
                                            <MiTabla>
                                                <table >


                                                    <tr>
                                                        <th style={{ textAlign: 'left' }}>Cliente</th>
                                                        <th>Vencida</th>
                                                        <th style={{ textAlign: 'right' }}>No Vencida</th>
                                                        <th style={{ textAlign: 'right' }}>Total Deuda</th>
                                                        <th style={{ textAlign: 'right' }}>%/ Deuda Total</th>

                                                    </tr>

                                                    {this.state.clientes.length > 0 && filteredData.map((cliente, i) =>

                                                        <CarCli
                                                            nombre={cliente.nombre}
                                                            vencida={cliente.vencida}
                                                            novencida={cliente.novencida}
                                                            total={cliente.total}
                                                            totalvencida={cliente.porciento}
                                                            miid={cliente.cliente}
                                                            minombre={cliente.nombre}
                                                            call={this.mostrarmodal}
                                                        />

                                                    )}


                                                </table>
                                            </MiTabla>

                                        </div>
                                    </div>
                                </>}
                        </div>                
                       
                                
                        
                   { this.state.datoschart.length > 0 &&
                    
                         <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>

                            <div className="cardtitulo" style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Vencida </div>
                                <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-end', fontSize: '22px', fontFamily: 'CircularStd-Light', color: 'red' }}>{this.state.datos[11] == null ? this.state.datos[11] : this.state.datos[11].importe + ' €'}</div>
                            </div>

                            <div className="cardtitulo" style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>No Vencida </div>
                                <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-end', fontSize: '22px', fontFamily: 'CircularStd-Light', color: 'green' }}>{this.state.datos[5] == null ? this.state.datos[5] : this.state.datos[5].importe + ' €'}</div>
                            </div>

                            <div className="cardtitulo" style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-start', fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Cartera Total </div>
                                <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-end', fontSize: '22px', fontFamily: 'CircularStd-Light', color: 'black' }}>{this.state.datos[0] == null ? this.state.datos[0] : this.state.datos[0].total + ' €'}</div>
                            </div>


                            <div className="cardd">
                                <div className="card-content" >
                                    <div style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Vencida <span style={{ fontSize: '13px', fontFamily: 'CircularStd-Light', color: 'gray' }}>(días desde vencimiento)</span></div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[1] == null ? this.state.datos[1] : this.state.datos[1].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}>{this.state.datos[1] == null ? this.state.datos[1] : this.state.datos[1].importe} €</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[2] == null ? this.state.datos[2] : this.state.datos[2].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}> {this.state.datos[2] == null ? this.state.datos[2] : this.state.datos[2].importe} €</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[3] == null ? this.state.datos[3] : this.state.datos[3].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}> {this.state.datos[3] == null ? this.state.datos[3] : this.state.datos[3].importe} €</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[4] == null ? this.state.datos[4] : this.state.datos[4].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}>{this.state.datos[4] == null ? this.state.datos[4] : this.state.datos[4].importe} €</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[0] == null ? this.state.datos[0] : this.state.datos[0].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}> {this.state.datos[0] == null ? this.state.datos[0] : this.state.datos[0].importe} €</div>
                                    </div>

                                </div>

                            </div>

                            <div className="cardd">
                                <div className="card-content" >
                                    <div style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>No Vencida <span style={{ fontSize: '13px', fontFamily: 'CircularStd-Light', color: 'gray' }}>(días para el cobro)</span></div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[7] == null ? this.state.datos[7] : this.state.datos[7].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}>{this.state.datos[7] == null ? this.state.datos[7] : this.state.datos[7].importe} €</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[8] == null ? this.state.datos[8] : this.state.datos[8].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}> {this.state.datos[8] == null ? this.state.datos[8] : this.state.datos[8].importe} €</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[9] == null ? this.state.datos[9] : this.state.datos[9].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}> {this.state.datos[9] == null ? this.state.datos[9] : this.state.datos[9].importe} €</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[10] == null ? this.state.datos[10] : this.state.datos[10].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}>{this.state.datos[10] == null ? this.state.datos[10] : this.state.datos[10].importe} €</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[6] == null ? this.state.datos[6] : this.state.datos[6].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}> {this.state.datos[6] == null ? this.state.datos[6] : this.state.datos[6].importe} €</div>
                                    </div>

                                </div>

                            </div>
                            <div className="cardd">
                                <div className="card-content" >
                                    <div style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Posibilidad Recobro (%) </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[7] == null ? this.state.datos[7] : this.state.datos[7].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}>90%</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[8] == null ? this.state.datos[8] : this.state.datos[8].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}>80%</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[9] == null ? this.state.datos[9] : this.state.datos[9].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}>70%</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[10] == null ? this.state.datos[10] : this.state.datos[10].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}>60%</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', width: '40%' }}>{this.state.datos[6] == null ? this.state.datos[6] : this.state.datos[6].dias}:</div> <div style={{ display: 'flex', fontSize: '18px', fontFamily: 'CircularStd-Light', color: 'black', justifyContent: 'flex-end', width: '60%' }}>30-15%</div>
                                    </div>

                                </div>

                            </div>
                        </div>
    }
                        {this.state.datoschart.length > 0 && !this.state.cargando2 ?


                            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>


                                <div className="cardchart">

                                    <p style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Evolución Vencida</p>
                                    <Bar
                                        data={datas2}
                                        options={

                                            {

                                                elements: {
                                                    point: {
                                                        radius: 4
                                                    },

                                                },
                                                tension: 0.3,
                                                fill: true,
                                                fill: {
                                                    target: 'start',
                                                    //above: 'rgba(50,74,117,0.51)',   // Area will be red above the origin
                                                    //below: 'rgba(50,74,117,0.51)'    // And blue below the origin
                                                },
                                                borderWidth: 2,
                                                showLine: true,

                                                backgroundColor: "rgba(0,0,0,0.31)",
                                                borderColor: "rgba(0,0,0,0.61)",

                                                pointBackgroundColor: "rgba(255,255,255,1)",
                                                maintainAspectRatio: true,

                                                responsive: true,
                                                plugins: {
                                                    tooltip: {
                                                        displayColors: false,
                                                        bodyFont: {
                                                            size: 13,
                                                            family: 'CircularStd-Light'
                                                        },
                                                        titleFont: {
                                                            size: 13,
                                                            family: 'CircularStd-Bold'
                                                        },
                                                    },
                                                    legend: {
                                                        display: false,
                                                    }
                                                },
                                                animation: {
                                                    duration: 0,
                                                },

                                                scales: {

                                                    x: {
                                                        grid: {
                                                            display: false,
                                                        },
                                                        ticks: {
                                                            font: {
                                                                size: 12,
                                                                family: 'CircularStd-Book',
                                                            }
                                                        }
                                                    },
                                                    y: {
                                                        beginAtZero: true,
                                                        grid: {
                                                            display: true,
                                                        },
                                                        ticks: {
                                                            font: {
                                                                size: 12,
                                                                family: 'CircularStd-Book',
                                                            }
                                                        }
                                                    },


                                                }



                                            }}

                                    />

                                </div>


                                <div className="cardchart">
                                    <p style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Evolución No Vencida</p>
                                    <Bar
                                        data={datas}
                                        options={{
                                            elements: {
                                                point: {
                                                    radius: 4
                                                },

                                            },
                                            lineTension: 0.3,
                                            fill: true,
                                            fill: {
                                                target: 'start',
                                                // above: 'rgba(50,74,117,0.51)',   // Area will be red above the origin
                                                //  below: 'rgba(50,74,117,0.51)'    // And blue below the origin
                                            },
                                            borderWidth: 2,
                                            showLine: true,

                                            backgroundColor: "rgba(0,0,0,0.31)",
                                            borderColor: "rgba(0,0,0,0.61)",

                                            pointBackgroundColor: "rgba(255,255,255,0.8)",
                                            maintainAspectRatio: true,
                                            responsive: true,
                                            plugins: {
                                                tooltip: {
                                                    displayColors: false,
                                                    bodyFont: {
                                                        size: 13,
                                                        family: 'CircularStd-Light'
                                                    },
                                                    titleFont: {
                                                        size: 13,
                                                        family: 'CircularStd-Bold'
                                                    },
                                                },
                                                legend: {
                                                    display: false,
                                                }
                                            },
                                            animation: {
                                                duration: 0,
                                            },

                                            scales: {

                                                x: {
                                                    grid: {
                                                        display: false,
                                                    },
                                                    ticks: {
                                                        font: {
                                                            size: 12,
                                                            family: 'CircularStd-Book',
                                                        }
                                                    }
                                                },
                                                y: {
                                                    beginAtZero: true,
                                                    grid: {
                                                        display: true,
                                                    },
                                                    ticks: {
                                                        font: {
                                                            size: 12,
                                                            family: 'CircularStd-Book',
                                                        }
                                                    }
                                                },


                                            }



                                        }}
                                    />

                                </div>

                                <div className="cardchart">
                                    <p style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Evolución Total Deuda</p>
                                    <Bar
                                        data={datas3}
                                        options={{

                                            elements: {
                                                point: {
                                                    radius: 4
                                                },

                                            },
                                            tension: 0.3,
                                            fill: true,
                                            fill: {
                                                target: 'start',
                                                above: 'rgba(50,74,117,0.51)',   // Area will be red above the origin
                                                below: 'rgba(50,74,117,0.51)'    // And blue below the origin
                                            },
                                            borderWidth: 2,
                                            showLine: true,

                                            backgroundColor: "rgba(0,0,0,0.31)",
                                            borderColor: "rgba(0,0,0,0.61)",

                                            pointBackgroundColor: "rgba(255,255,255,0.8)",
                                            maintainAspectRatio: true,
                                            responsive: true,
                                            plugins: {
                                                tooltip: {
                                                    displayColors: false,
                                                    bodyFont: {
                                                        size: 13,
                                                        family: 'CircularStd-Light'
                                                    },
                                                    titleFont: {
                                                        size: 13,
                                                        family: 'CircularStd-Bold'
                                                    },
                                                },
                                                legend: {
                                                    display: false,
                                                }
                                            },
                                            animation: {
                                                duration: 0,
                                            },

                                            scales: {

                                                x: {
                                                    grid: {
                                                        display: false,
                                                    },
                                                    ticks: {
                                                        font: {
                                                            size: 12,
                                                            family: 'CircularStd-Book',
                                                        }
                                                    }
                                                },
                                                y: {
                                                    beginAtZero: true,
                                                    grid: {
                                                        display: true,
                                                    },
                                                    ticks: {
                                                        font: {
                                                            size: 12,
                                                            family: 'CircularStd-Book',
                                                        }
                                                    }
                                                },


                                            }



                                        }}

                                    />

                                </div>
                            </div>:<div>No existen datos</div>
                                    
                        }
                        
                                
                        
                    </div>
                
                }
                {/* <div style={{ marginTop: '10px', marginBottom: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>

                    <input className="documentosinput"
                    ref={(input) => { this.secondText = input; }}

                    type='text'
                    placeholder="Buscar..."
                    onKeyDown={this.handleKeyDown}
                    onChange={this.change}
                    />
                    </div>
                    <div style={{ backgroundColor: '', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>
                    <div style={{ position: 'relative', height: '555px', overflow: 'auto', display: 'block' }}>
                      <MiTabla>
                        <table >
      
      
                          <tr>
                            <th style={{ textAlign: 'left' }}>Cliente</th>
                            <th>Vencida</th>
                            <th style={{ textAlign: 'right' }}>No Vencida</th>
                            <th style={{ textAlign: 'right' }}>Total Deuda</th>
                            <th style={{ textAlign: 'right' }}>%/ Deuda Total</th>
                            
                          </tr>
   
                          {this.state.clientes.length > 0 && filteredData.map((cliente, i) =>
      
                            <CarCli
                              nombre={cliente.nombre}
                              vencida={cliente.vencida}
                              novencida={cliente.novencida}
                              total={cliente.total}
                              totalvencida={cliente.porciento}
                            />
      
                          )}
                          
      
                        </table>
                      </MiTabla>
                
                    </div>
                  </div> */}

                <>
                    <Modal aria-labelledby="contained-modal-title-vcenter"
                       show={this.state.show} onHide={this.handleClose} dialogClassName="custom-modal-style" style={{ fontFamily: 'CircularStd-Book' }}>
                        <Modal.Header closeButton style={{ backgroundColor: 'rgba(154, 152, 152,0.2)' }}>
                            <div style={{ display: 'flex', float: 'right', width: '80%' }}>
                                <Modal.Title id="contained-modal-title-vcenter" style={{ fontFamily: 'CircularStd-Bold', color: '#000000', fontSize: '20px', textTransform: 'capitalize' }}><EllipsisText text={this.state.nombrecliente} length={"28"} /></Modal.Title>
                            </div> &nbsp;&nbsp;

                        </Modal.Header>
                        <Modal.Body style={{ fontFamily: 'CircularStd-Light', textAlign: 'left', alignContent: 'center', justifyContent: 'center' }}>
                            <div >
                                {this.state.datoscliente.map((cliente, i) =>
                                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>
                                        <div style={{ width: '120px', fontFamily: 'CircularStd-Bold', fontSize: '20px' }}>{cliente.dias}</div>
                                        <div style={{ width: '125px', textAlign: 'right', fontSize: '20px' }}>{cliente.importetotal} €</div>
                                    </div>


                                )}
                            </div >


                        </Modal.Body>
                    </Modal>
                </>
            </div>



        );


    }
}
const MiTabla = styled.table`
  table {
    font-size:15px;
    background-color:white;
    table-layout: fixed;
    border-collapse:collapse;
    padding:5px;
    //box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px ;
    width: 100%;
  }
  th {
    font-size:15px;
    font-family:'CircularStd-Bold';
    color:#000000;
    border:1px solid lightgray;
     padding:5px;
     background-color:rgba(223,223,223,1);
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
  }
  td {
    font-family:'CircularStd-Light';
    color:'#000000';
    border:1px solid lightgray;
    padding:5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
  }
  table tr th:nth-child(1){
    width: 50%;
   
  }
  table tr th:nth-child(2){
    width: 10%;
  }
  table tr th:nth-child(3){
    width: 10%;
  }
  table tr th:nth-child(4){
    width: 10%;
  }
  table tr th:nth-child(5){
    width: 10%;
   
  }
  table tr th:nth-child(6){
    width: 10%;
  }
  table tr th:nth-child(7){
    width: 10%;
  }
  table tr th:nth-child(8){
    width: 10%;
  }
  tr:hover{
    background-color:#d1d1d140;
  
  }
  tr {
    cursor: pointer;
  }
  `;