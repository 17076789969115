import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Caryt from '../components/card6'
import Caryt2 from '../components/cardsoc'
import axios from 'axios';
import Select from 'react-select'
import ApiService from "./ApiService";
import Spinner from "../assets/icons8-loading-infinity.gif"
import BarLoader from "react-spinners/BarLoader";
import styled from 'styled-components';
import { Bar, Line } from 'react-chartjs-2';
export default class Tesoreria extends React.Component {

  state = {
    datos: [],
    datosoc: [],
    cargando: true,

    micliente: '31',
    //ESTADOS DEL ÚLTIMO EJERCICIO Y MES DISPONIBLES EN LA BBDD

    mes: '',
    meses: [],
    nombremes: '',
    ejercicio: '',
    ejercicios: [],
    maxmes: '',
    //CHARTS
    datasource: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    labels: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    sociedades: [],
    datostev: [],
    datostevsoc: [],
    datasource1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    labels1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  }


  meses = async () => {
    return ApiService.fetchDatosMeses(this.state.micliente, this.state.ejercicio)
      .then(response => {
        // console.log(this.state.ejercicio)

        this.setState(
          {
            meses: response.data
          })



      })
      .catch(error => {


      });

  }
  ejercicios = async () => {
    //console.log(this.state.micliente)
    return ApiService.fetchDatosEjercicios(this.state.micliente)
      .then(response => {

        this.setState(
          {
            maxmes: response.data[0].maxmes, ejercicios: response.data, ejercicio: response.data[0].maxejer, mes: response.data[0].maxmes, nombremes: response.data[0].meses
          })

      })
      .catch(error => {


      });

  };

  saldos = async () => {
    axios.get('https://apicore.myleistung.com/saldo/')
      .then(res => {

        this.setState({ datos: res.data })

      })
      .catch(function (error) {
        console.log(error);
      })





  };
  saldossoc = async () => {
    axios.get('https://apicore.myleistung.com/saldos/')
      .then(res => {

        this.setState({ datosoc: res.data })

      })
      .catch(function (error) {
        console.log(error);
      })





  };
  saldossocevo = async () => {
    axios.get('https://apicore.myleistung.com/saldosevsociedad')
      .then(response => {


        this.setState({ datostevsoc: response.data })

        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        sleep(200)
        const miscompanias = Array.from(new Set(this.state.datostevsoc.map((j) => j.sociedad)))
        this.setState({ sociedades: miscompanias })
        const newArr = this.state.datostevsoc.filter(obj => obj.sociedad === this.state.sociedades[2]).map((midato) => midato.mes);
        console.log(newArr)
        this.setState({ datasource1: this.state.datostevsoc.map((dato) => parseInt(dato.saldo) / 1000) })
        this.setState({ labels1: this.state.datostevsoc.map((dato) => dato.mes.substring(0, 1)) })

        var i;
        for (i = 0; i < this.state.sociedades.length; i++) {
          const misdatos = this.state.datostevsoc.filter(dato => dato.sociedad === this.state.sociedades[i],)
          this.setState({ i: misdatos })
          console.log(misdatos)
          const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
          sleep(200)



        }

        this.setState({ isLoading: true })
      })
      .catch(error => {

        console.log('es mi mes');
      });





  };
  saldossocglobal = async () => {
    axios.get('https://apicore.myleistung.com/saldosev')
      .then(response => {


        this.setState({ datostev: response.data })

        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        sleep(200)

        this.setState({ datasource: this.state.datostev.map((dato) => parseInt(dato.saldo)) })
        this.setState({ labels: this.state.datostev.map((dato) => dato.mes) })



        this.setState({ cargando: true })
      })
      .catch(error => {

        console.log('es mi mes');
      });





  };
  async componentDidMount() {

    await this.ejercicios();

    // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    // await sleep(400)

    await this.meses();

    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(600)
    await this.saldos();
    await this.saldossoc();
    await this.saldossocevo();
    await this.saldossocglobal();
    const sleep3 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep3(1800)

    this.setState({ cargando: false })

    console.log(this.state.datostevsoc)
    // alert(parseInt(this.state.mes))// Ejecutamos la función 

  }
  handleChange = async (e) => {
    this.setState({ cargando: true })
    this.setState({ mes: e.value, nombremes: e.label });
    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    this.miusuario()

    this.setState({ nombremes: e.label });
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(600)
    this.setState({ cargando: false })
  }

  handleChange2 = async (e) => {
    // alert(e.nombre)
    this.setState({ cargando: true })
    this.setState({ ejercicio: e.value, mes: e.maxmes, nombremes: e.nombre });

    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    this.meses(e.value)

    this.miusuario()

    // this.setState({nombremes:e.label});
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(600)
    //alert(this.state.meses)
    this.setState({ cargando: false })
  }


  render() {


    const options =
      this.state.meses.map((dato, i) => {
        return {
          label: dato.meses,
          value: dato.nummes,
          nombre: dato.meses


        }
      }
      );
    const options2 =
      this.state.ejercicios.map((dato, i) => {
        return {
          label: dato.ejercicios,
          value: dato.ejercicios,
          maxmes: dato.maxmes,
          nombre: dato.meses
        }
      }
      );

    const dot = (color = "#395482") => ({
      alignItems: "center",
      display: "flex",
      color: '#395482',
      borderColor: "black",

      ":before": {
        backgroundColor: "#fff",
        // borderRadius: 1,
        // content: '" "',
        // display: "block",
        // marginRight: 8,
        // height: 10,
        // width: 10

      }
    });
    const styles = {

      control: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          fontFamily: 'CircularStd-Bold',
          borderColor: "black",
          boxShadow: 'none',
          border: '2px ',
          borderWidth: "1px",
          borderRadius: "0px",
          backgroundColor: isFocused ? "#39548290" : "#39548240",
          fontSize: '15px', fontColor: 'white', minWidth: '170px', width: '170px', height: '35px',
          ":hover": {
            ...styles[":hover"],
            backgroundColor: "#39548240",

            color: "white",
            borderColor: "white"
          },

        };
      },
      indicatorSeparator: style => ({
        ...style,
        display: 'none',
        color: '#395482'
      }),
      dropdownIndicator: style => ({
        ...style,

        color: '#395482'
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          minWidth: '150px',
          height: '35px',
          borderRadius: '0px',
          backgroundColor: isSelected ? "#00000050" : "#fff",
          // width:'150px',
          color: "#000",
          fontFamily: 'CircularStd-Book',
          cursor: isDisabled ? "not-allowed" : "default",
          ":active": {
            ...styles,
            backgroundColor: "#000000",
            color: 'black',
            borderColor: "black"
          },
          ":hover": {
            ...styles[":hover"],
            backgroundColor: "#00000080",
            color: "white",
            borderColor: "black"
          },

        };
      },

      menu: styles => ({ ...styles, marginTop: '0px', borderRadius: '0px', borderColor: "black", backgroundColor: "white", fontSize: '13px', minWidth: '168px', width: '168px' }),
      input: styles => ({ ...styles, borderColor: "black", borderWidth: "0px" }),
      placeholder: styles => ({ ...styles, ...dot() }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
    };
    return (
      <div style={{ padding: '24px', minWidth: 'calc(100vw- 265px)', width: 'calc(100vw  - 265px)', minHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}>




        {this.state.cargando ?

          <div style={{
            display: 'flex',

            height: '90vh',
            width: 'calc(100vw-250px)',
            justifyContent: 'center',
            alignItems: 'center',


          }}>
            <BarLoader color={'#000'} size={40} />
            {/* <img src={Spinner} style={{width:'50px'}}/> */}
            {/* <i style={{fontSize:25,color:'#4682b4'}} className="fas fa-circle-notch fa-spin" />  */}
          </div>
          :


          <div style={{ margin: '10px ', minWidth: 'calc(100vw- 265px)', verticalAlign: 'middle', maxWidth: '1500px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>

              <div style={{ display: 'flex', justifyContent: 'flex-start', width: '60%' }}>
                <div style={{ display: 'flex', fontSize: '32px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>Tesorería</div>
                {/* <h3>Dashboard a {this.state.nombremes} de {this.state.ejercicio}</h3> */}
                {/* <div style={{display:'flex',marginLeft:'5px',display:'flex',backgroundColor:'transparent',borderRadius:'5px',textAlign:'center',fontFamily:'CircularStd-Book', fontSize:'32px'}}> {this.state.datos[0].saldototal} €</div> */}

              </div>





            </div>

            <div style={{marginTop:'10px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>
              <Caryt2
                sociedad="SALDO TOTAL"
                saldo={this.state.datos[0].saldototal}
                labels={this.state.labels}
                data={this.state.datasource}
              />


              {this.state.datosoc.map((datosoc, i) =>

                <Caryt2
                  sociedad={datosoc.sociedad}
                  saldo={datosoc.saldo}
                  labels={this.state.datostevsoc.filter(obj => obj.sociedad === datosoc.sociedad).map((midato) => midato.mes)}
                  data={this.state.datostevsoc.filter(obj => obj.sociedad === datosoc.sociedad).map((midato) => parseInt(midato.saldo))}
                />

              )}
            </div>
            <div style={{marginTop:'5px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>

              <MiTabla>
                <table>


                  <tr>
                    <th style={{ textAlign: 'left' }}>Sociedad</th>
                    <th style={{ textAlign: 'left' }}>Entidad</th>
                    <th style={{ textAlign: 'left' }}>Cuenta </th>
                    <th style={{ textAlign: 'left' }}>Tipo Cuenta</th>
                    <th style={{ textAlign: 'right' }}>Saldo</th>
                  </tr>
                  {this.state.datos.length > 0 && this.state.datos.map((dato, i) =>

                    <Caryt
                      titulo={dato.sociedad}
                      valor={dato.entidad}
                      comentario={dato.saldo}
                      posicion={i + 1}
                      mes={dato.saldo}
                      cuenta={dato.cuenta}
                      tipocuenta={dato.tipo}
                    />

                  )}

                </table>
              </MiTabla>
            </div>

            <div style={{ border: '0px solid lightgray', marginTop: '1px', alignContent: 'flex-end', textAlign: 'right', padding: '8px', borderRadius: '0px', width: '100%', fontFamily: 'CircularStd-Book', fontSize: '20px' }}>Saldo: {this.state.datos[0].saldototal} €</div>

          </div>
        }
      </div>



    );


  }
}

const MiTabla = styled.table`
table {
  font-size:15px;
  background-color:white;
  table-layout: fixed;
  border-collapse:collapse;
  padding:5px;
  //box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px ;
  width: 100%;
}
th {
  font-size:15px;
  font-family:'CircularStd-Bold';
  color:#000000;
  border:1px solid lightgray;
   padding:5px;
   background-color:rgba(223,223,223,1);
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
td {
  font-family:'CircularStd-Light';
  color:'#000000';
  border:1px solid lightgray;
  padding:5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
table tr th:nth-child(1){
  width: 15%;
 
}
table tr th:nth-child(2){
  width: 45%;
}
table tr th:nth-child(3){
  width: 20%;
}
table tr th:nth-child(4){
  width: 20%;
}
tr:hover{
  background-color:#d1d1d140;
  cursor:'pointer';
}
// thead {position: -webkit-sticky; position: sticky; top: 50px; z-index: 100;}
`;






