import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Caryt from '../components/card2'
import Select from 'react-select'
import ApiService from "./ApiService";
import Spinner from "../assets/icons8-loading-infinity.gif"
import BarLoader from "react-spinners/BarLoader";
import { Bar, Line } from 'react-chartjs-2';


export default class Ratios extends React.Component {

  state = {
    datos: [],
    cargando: true,
    micliente: '',
    //ESTADOS DEL ÚLTIMO EJERCICIO Y MES DISPONIBLES EN LA BBDD

    mes: '',
    meses: [],
    nombremes: '',
    ejercicio: '',
    ejercicios: [],
    maxmes: '',
    mismasas: [],
    ANC: '',
    AC: '',
    PN: '',
    PNC: '',
    PC: '',
    FM: '',
    RESTO: ''
  }

  mismasas = async () => {


    this.setState({ ANC: this.state.datos.map((dato) => ((parseInt(dato.ANC) / (parseInt(dato.ANC) + parseInt(dato.AC)) * 100).toFixed(0))) })
    this.setState({ AC: this.state.datos.map((dato) => ((parseInt(dato.AC) / (parseInt(dato.ANC) + parseInt(dato.AC)) * 100).toFixed(0))) })
    this.setState({ PN: this.state.datos.map((dato) => ((parseInt(dato.PN) / (parseInt(dato.ANC) + parseInt(dato.AC)) * 100).toFixed(0))) })
    this.setState({ PNC: this.state.datos.map((dato) => ((parseInt(dato.PNC) / (parseInt(dato.ANC) + parseInt(dato.AC)) * 100).toFixed(0))) })
    this.setState({ PC: this.state.datos.map((dato) => ((parseInt(dato.PC) / (parseInt(dato.ANC) + parseInt(dato.AC)) * 100).toFixed(0))) })
    this.setState({ FM: this.state.datos.map((dato) => ((parseInt(dato.AC) - parseInt(dato.PC)) / (parseInt(dato.AC) + parseInt(dato.ANC)) * 100).toFixed(0)) })

    this.setState({ RESTO: this.state.datos.map((dato) => ((((parseInt(dato.AC) / (parseInt(dato.ANC) + parseInt(dato.AC)) * 100).toFixed(0))) - (parseInt(dato.AC) - parseInt(dato.PC)) / (parseInt(dato.AC) + parseInt(dato.ANC)) * 100).toFixed(0)) })
    //this.setState({RESTO:parseInt(100)-parseInt(this.state.FM)})



  }
  // Permite obtener y modificar el estado
  meses = async () => {
    return ApiService.fetchDatosMeses(this.state.micliente, this.state.ejercicio)
      .then(response => {
        // console.log(this.state.ejercicio)

        this.setState(
          {
            meses: response.data
          })



      })
      .catch(error => {


      });

  }
  ejercicios = async () => {
    //console.log(this.state.micliente)
    return ApiService.fetchDatosEjercicios(this.state.micliente)
      .then(response => {

        this.setState(
          {
            maxmes: response.data[0].maxmes, ejercicios: response.data, ejercicio: response.data[0].maxejer, mes: response.data[0].maxmes, nombremes: response.data[0].meses
          })

      })
      .catch(error => {


      });

  };

  miusuario = async () => {
    axios.get('https://apicore.myleistung.com/masascahrt/' + this.state.ejercicio + '/' + this.state.micliente + '/' + this.state.mes, {

    })
      .then(res => {

        this.setState({ datos: res.data })

      })
      .catch(function (error) {
        console.log(error);
      })





  };

  // Obtenemos datos desde la API 
  async componentDidMount() {
    
    this.setState({ micliente: sessionStorage.getItem("cliente") });
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(400)
    await this.ejercicios();
    await this.meses();
    const sleep1 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep1(400)




    await this.miusuario();
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(400)


    await this.mismasas()
    const sleep3 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep3(400)
    this.setState({ cargando: false })

    // alert(parseInt(this.state.mes))// Ejecutamos la función 

  }
  handleChange = async (e) => {
    this.setState({ cargando: true })
    this.setState({ mes: e.value, nombremes: e.label });
    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    this.miusuario()

    this.setState({ nombremes: e.label });
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(300)
    await this.mismasas()
    const sleep3 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep3(400)

    this.setState({ cargando: false })
  }

  handleChange2 = async (e) => {
    // alert(e.nombre)
    this.setState({ cargando: true })
    this.setState({ ejercicio: e.value, mes: e.maxmes, nombremes: e.nombre });

    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    this.meses(e.value)

    this.miusuario()

    // this.setState({nombremes:e.label});
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(300)
    //alert(this.state.meses)
    await this.mismasas()
    const sleep3 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep3(400)
    this.setState({ cargando: false })
  }
  render() {

    const datas = {
      labels: ['ACTIVO'],
      datasets: [
        {
          label: 'ACTIVO CORRIENTE',
          data: this.state.AC,
          backgroundColor: 'rgba(65,65,65,0.8)' // green
        },
        {
          label: 'ACTIVO NO CORRIENTE',
          data: this.state.ANC,
          backgroundColor: 'rgba(65,65,65,0.5)' // yellow
        }
      ]
    };
    const datas2 = {
      labels: ['PASIVO'],
      datasets: [
        {
          label: 'PASIVO CORRIENTE',
          data: this.state.PC,
          backgroundColor: 'rgba(65,65,65,0.8)' // green
        },
        {
          label: 'PASIVO NO CORRIENTE',
          data: this.state.PNC,
          backgroundColor: 'rgba(65,65,65,0.5)' // yellow
        },
        {
          label: 'PATRIMONIO NETO',
          data: this.state.PN,
          backgroundColor: 'rgba(65,65,65,0.3)' // yellow
        }
      ]
    };
    const datas3 = {
      labels: ['FONDO DE MANIOBRA'],
      datasets: [

        {

          label: 'FONDO RESTO',
          data: this.state.RESTO,
          borderWidth: 0,
          backgroundColor: 'rgba(65,65,65,0)' // green
        },
        {

          label: 'FONDO DE MANIOBRA',
          data: this.state.FM,
          borderWidth: 0,
          backgroundColor: 'rgba(65,65,65,0.8)' // green
        },
        {

          label: 'ACTIVO NO CORRIENTE',
          data: this.state.ANC,
          borderWidth: 0,
          backgroundColor: 'rgba(65,65,65,0)' // yellow
        }

      ]
    };
    const options =
      this.state.meses.map((dato, i) => {
        return {
          label: dato.meses,
          value: dato.nummes,
          nombre: dato.meses


        }
      }
      );
    const options2 =
      this.state.ejercicios.map((dato, i) => {
        return {
          label: dato.ejercicios,
          value: dato.ejercicios,
          maxmes: dato.maxmes,
          nombre: dato.meses
        }
      }
      );

      const dot = (color = "rgb(255,193,53)") => ({
        alignItems: "center",
        display: "flex",
        color: '#000000',
        borderColor: "black",
  
        ":before": {
          backgroundColor: "#fff",
          // borderRadius: 1,
          // content: '" "',
          // display: "block",
          // marginRight: 8,
          // height: 10,
          // width: 10
  
        }
      });
      const styles = {
  
        control: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            fontFamily: 'CircularStd-Bold',
            borderColor: "black",
            boxShadow: 'none',
            border: '2px ',
            borderWidth: "1px",
            borderRadius: "5px",
            backgroundColor: isFocused ? "rgba(255,193,53,1)" : "rgba(255,193,53,1)",
            fontSize: '15px', fontColor: 'white', minWidth: '170px', width: '170px', height: '35px',
            ":hover": {
              ...styles[":hover"],
              backgroundColor: "rgba(255,193,53,0.30)",
  
              color: "white",
              borderColor: "white"
            },
  
          };
        },
        indicatorSeparator: style => ({
          ...style,
          display: 'none',
          color: '#000000'
        }),
        dropdownIndicator: style => ({
          ...style,
  
          color: '#000000'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            minWidth: '150px',
            height: '35px',
            borderRadius: '0px',
            
            backgroundColor: isSelected ? "rgba(255,193,53,0.60)" : "#fff",
            // width:'150px',
            color: "#000",
            fontFamily: 'CircularStd-Book',
            cursor: isDisabled ? "not-allowed" : "default",
            ":active": {
              ...styles,
              backgroundColor: "#000000",
              color: 'black',
              borderColor: "black"
            },
            ":hover": {
              ...styles[":hover"],
              backgroundColor: "rgba(255,193,53,0.30)",
              color: "black",
              borderColor: "black"
            },
  
          };
        },
  
        menu: styles => ({ ...styles, marginTop: '3px', borderRadius: '5px', borderColor: "black", backgroundColor: "white", fontSize: '13px', minWidth: '168px', width: '168px' }),
        input: styles => ({ ...styles, borderColor: "black", borderWidth: "0px" }),
        placeholder: styles => ({ ...styles, ...dot() }),
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
      };

    return (
      <div style={{ padding: '24px', minWidth: 'calc(100vw- 270px)', width: 'calc(100vw  - 270px)', minHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}>




        {this.state.cargando ?

          <div style={{
            display: 'flex',

            height: '90vh',
            width: 'calc(100vw-250px)',
            justifyContent: 'center',
            alignItems: 'center',


          }}>
            <BarLoader color={'#000'} size={40} />

            {/* <img src={Spinner} style={{width:'50px'}}/> */}

            {/* <i style={{fontSize:25,color:'#4682b4'}} className="fas fa-circle-notch fa-spin" />  */}
          </div>
          :



          <div style={{ margin: '10px ', minWidth: 'calc(100vw- 265px)', verticalAlign: 'middle', maxWidth: '1500px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>

              <div style={{ display: 'flex', justifyContent: 'flex-start', width: '60%' }}>
                <div style={{ fontSize: '32px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>Análisis Masas y FM-NOF</div>
                {/* <h3>Dashboard a {this.state.nombremes} de {this.state.ejercicio}</h3> */}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-end', width: '40%' }}>

                <Select
                  isSearchable={false}
                  // value={selectedOption}
                  value={{ value: this.state.ejercicio, label: this.state.ejercicio }}
                  onChange={this.handleChange2}

                  options={options2} styles={styles} placeholder="Ejercicios" /> &nbsp;
                <Select

                  isSearchable={false}
                  //defaultValue={options[1]}
                  value={options.filter(({ value }) => value === this.state.mes)}
                  onChange={this.handleChange}
                  options={options} styles={styles} placeholder="Meses" />

              </div>

            </div>

            {!this.state.cargando &&
              <div style={{marginTop: '10px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>

                <div className="cardnof">
                  <div style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Análisis Masas Patrimoniales</div>
                  <div style={{ color: 'gray', fontSize: '15px', fontFamily: 'CircularStd-Light' }}>{this.state.datos.map((datos, i) => datos.mensaje1)}</div>
                </div>

                <div className="cardchart">

                  <p style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Activo (%)</p>
                  <Bar
                    data={datas}
                    options={{



                      lineTension: 0,
                      fill: true,
                      fill: {
                        target: 'origin',
                        above: 'rgba(50,74,117,0.51)',   // Area will be red above the origin
                        below: 'rgba(50,74,117,0.51)'    // And blue below the origin
                      },
                      borderWidth: 0,
                      showLine: true,

                      backgroundColor: "rgba(50,74,117,0.1)",
                      borderColor: "rgba(50,74,117,0.8)",
                      barThickness: 196,  // number (pixels) or 'flex'
                      //maxBarThickness: 8, // number (pixels),
                      pointBackgroundColor: "rgba(255,255,255,0.8)",
                      maintainAspectRatio: true,
                      responsive: true,
                      plugins: {
                        tooltip: {


                          callbacks: {
                            label: function (context) {
                              let label = context.dataset.label || '';

                              if (label) {
                                label += ': ';
                              }
                              if (context.parsed.y !== null) {
                                label += context.parsed.y + " %";
                              }
                              return label;
                            }
                          },
                          displayColors: false,
                          bodyFont: {
                            size: 13,
                            family: 'CircularStd-Light'
                          },
                          titleFont: {
                            size: 13,
                            family: 'CircularStd-Bold'
                          },
                        },
                        legend: {
                          display: false,
                        }
                      },
                      animation: {
                        duration: 0,
                      },
                    
                      scales: {

                        x: {
                          stacked: true,

                          grid: {
                            display: false,
                            drawBorder: false,

                          },
                          ticks: {
                            font: {
                              size: 12,
                              family: 'CircularStd-Book',
                            }
                          }
                        },
                        y: {
                          stacked: true,

                          grid: {
                            display: false,
                            drawBorder: false,
                          },
                          ticks: {
                            display: false,

                            font: {
                              size: 12,
                              family: 'CircularStd-Book',
                            }
                          }
                        },


                      }



                    }}

                  />

                </div>
                <div className="cardchart">

                  <p style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Fondo de Maniobra (%)</p>
                  <Bar
                    data={datas3}
                    options={{
                      lineTension: 0,
                      fill: true,
                      fill: {
                        target: 'origin',
                        above: 'rgba(50,74,117,0.51)',   // Area will be red above the origin
                        below: 'rgba(50,74,117,0.51)'    // And blue below the origin
                      },
                      borderWidth: 0,
                      showLine: true,
                      barThickness: 196,
                      backgroundColor: "rgba(50,74,117,0.1)",
                      borderColor: "rgba(50,74,117,0.8)",

                      pointBackgroundColor: "rgba(255,255,255,0.8)",
                      maintainAspectRatio: true,
                      responsive: true,

                      plugins: {
                        tooltip: {
                          filter: function (tooltipItem) {
                            return tooltipItem.datasetIndex === 1;
                          },
                          callbacks: {
                            label: function (context) {
                              let label = context.dataset.label || '';

                              if (label) {
                                label += ': ';
                              }
                              if (context.parsed.y !== null) {
                                label += context.parsed.y + " %";
                              }
                              return label;
                            }
                          },
                          displayColors: false,
                          bodyFont: {
                            size: 13,
                            family: 'CircularStd-Light'
                          },
                          titleFont: {
                            size: 13,
                            family: 'CircularStd-Bold'
                          },
                        },
                        legend: {
                          display: false,
                        }
                      },
                      animation: {
                        duration: 0,
                      },

                      scales: {

                        x: {
                          stacked: true,
                          grid: {
                            display: false,
                            drawBorder: false,
                          },
                          ticks: {
                            font: {
                              size: 12,
                              family: 'CircularStd-Book',
                            }
                          }
                        },
                        y: {
                          stacked: true,
                          grid: {
                            display: false,
                            drawBorder: false,
                          },
                          ticks: {
                            display: false,
                            font: {
                              size: 12,
                              family: 'CircularStd-Book',
                            }
                          }
                        },


                      }



                    }}

                  />

                </div>
                <div className="cardchart">

                  <p style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Pasivo (%)</p>
                  <Bar
                    data={datas2}
                    options={{
                      lineTension: 0,
                      fill: true,
                      fill: {
                        target: 'origin',
                        above: 'rgba(50,74,117,0.51)',   // Area will be red above the origin
                        below: 'rgba(50,74,117,0.51)'    // And blue below the origin
                      },
                      borderWidth: 0,
                      showLine: true,
                      barThickness: 196,
                      backgroundColor: "rgba(50,74,117,0.1)",
                      borderColor: "rgba(50,74,117,0.8)",

                      pointBackgroundColor: "rgba(255,255,255,0.8)",
                      maintainAspectRatio: true,
                      responsive: true,
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              let label = context.dataset.label || '';

                              if (label) {
                                label += ': ';
                              }
                              if (context.parsed.y !== null) {
                                label += context.parsed.y + " %";
                              }
                              return label;
                            }
                          },
                          displayColors: false,
                          bodyFont: {
                            size: 13,
                            family: 'CircularStd-Light'
                          },
                          titleFont: {
                            size: 13,
                            family: 'CircularStd-Bold'
                          },
                        },
                        legend: {
                          display: false,
                        }
                      },
                      animation: {
                        duration: 0,
                      },

                      scales: {

                        x: {
                          stacked: true,
                          grid: {
                            display: false,
                            drawBorder: false,
                          },
                          ticks: {
                            font: {
                              size: 12,
                              family: 'CircularStd-Book',
                            }
                          }
                        },
                        y: {
                          stacked: true,
                          grid: {
                            display: false,
                            drawBorder: false,
                          },
                          ticks: {
                            display: false,
                            font: {
                              size: 12,
                              family: 'CircularStd-Book',
                            }
                          }
                        },


                      }



                    }}

                  />

                </div>


                <div className="cardnof">
                  <div style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Fondo de Maniobra</div>
                  <div style={{ color: 'gray', fontSize: '20px', fontFamily: 'CircularStd-Light' }}>{this.state.datos.map((datos, i) => datos.fm + ' €')}</div>

                </div>
                <div className="cardnof">
                  <div style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>NOF</div>
                  <div style={{ color: 'gray', fontSize: '20px', fontFamily: 'CircularStd-Light' }}>{this.state.datos.map((datos, i) => datos.nof + ' €')}</div>

                </div>
                <div className="cardnof">
                  <div style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Análisis FM-NOF</div>
                  <div style={{ color: 'gray', fontSize: '15px', fontFamily: 'CircularStd-Light' }}>{this.state.datos.map((datos, i) => datos.diferencia1)}</div>
                </div>
              </div>



            }
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>


            </div>
          </div>


        }
      </div>

    );


  }
}