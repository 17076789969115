import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Caryt from '../components/card4'
import axios from 'axios';
import Select from 'react-select'
import ApiService from "./ApiService";
import Spinner from "../assets/icons8-loading-infinity.gif"
import BarLoader from "react-spinners/BarLoader";
import { MdOutlineSimCardDownload, MdPictureAsPdf, MdSkipNext, MdSkipPrevious, MdZoomIn, MdZoomOut } from 'react-icons/md';
import  {HiOutlineEye,HiOutlineCalendar,HiOutlineBriefcase,HiOutlineDatabase,HiOutlineCog,HiOutlineDocumentText,HiOutlineTrendingUp,HiOutlineDocumentDuplicate,HiOutlineCurrencyEuro,HiOutlineCash,HiOutlineCalculator,HiOutlineChartSquareBar,HiChevronRight,HiOutlineArrowCircleRight,HiOutlineChartBar,HiOutlineArrowSmRight,HiOutlinePresentationChartLine,HiOutlineFolder,HiOutlineChartPie} from 'react-icons/hi';




export default class Documentos extends React.Component {

  state = {
    datos: [],
    cargando: true,
    search: '',
    tipo: '',
    modoSearch: false,
    mibusqueda: '',
    micliente: '',
    //ESTADOS DEL ÚLTIMO EJERCICIO Y MES DISPONIBLES EN LA BBDD

    mes: '',
    meses: [],
    nombremes: '',
    ejercicio: '',
    ejercicios: [],
    maxmes: '',

  }

  async cargaDatosbus(search) {

    //this.setState({ cargando: true })



    return ApiService.fetchDocumentobus(this.state.micliente, search)
      .then(response => {
        console.log(response.data.length)
        this.setState({ datos: response.data })
        // this.setState({ cargando: false })
        
      })
      .catch(error => {


      });

  }



  miusuario = async () => {
    axios.get('https://apicore.myleistung.com/documentos/' + this.state.micliente, {

    })
      .then(res => {

        this.setState({ datos: res.data })

      })
      .catch(function (error) {
        console.log(error);
      })


  };
  updateSearch = async (search) => {
    //alert(search.target.value)

    if (search.target.value === '') {


      this.setState({ modoSearch: false, search: '' })
      await this.miusuario()

      return

    } else {

      this.setState({ datos: [], modoSearch: true, search: search.target.value })
      await this.cargaDatosbus(search.target.value)

    }
  };

  updateSearch2 = async () => {
    

    if (this.state.search === '') {


      this.setState({ modoSearch: false, search: '' })
      await this.miusuario()
      const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
      await sleep2(800)
      this.setState({ cargando: false })
      this.secondText.focus(); 
      return

    } else {

      this.setState({ datos: [], modoSearch: true, search: this.state.search })
      await this.cargaDatosbus(this.state.search)
      const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
      await sleep2(800)
      this.setState({ cargando: false })
      this.secondText.focus(); 
    }
   
  };
  async componentDidMount() {
    this.setState({ micliente: sessionStorage.getItem("cliente") });
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(400)

    await this.miusuario();

    const sleep3 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep3(400)

    this.setState({ cargando: false })


    // alert(parseInt(this.state.mes))// Ejecutamos la función 

  }

  handleChange2 = async (e) => {

    this.setState({ cargando: false })
  }


  render() {

    const { search } = this.state



    return (
      <div style={{ padding: '24px', minWidth: 'calc(100vw- 265px)', width: 'calc(100vw  - 265px)', minHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}>




        {this.state.cargando ?

          <div style={{
            display: 'flex',

            height: '90vh',
            width: 'calc(100vw-250px)',
            justifyContent: 'center',
            alignItems: 'center',


          }}>
            <BarLoader color={'#000'} size={40} />
            {/* <img src={Spinner} style={{width:'50px'}}/> */}
            {/* <i style={{fontSize:25,color:'#4682b4'}} className="fas fa-circle-notch fa-spin" />  */}
          </div>
          :


          <div style={{ margin: '10px ', minWidth: 'calc(100vw- 265px)', verticalAlign: 'middle', maxWidth: '1500px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>

              <div style={{ display: 'flex', justifyContent: 'flex-start', width: '50%' }}>
                <div style={{ display: 'flex', fontSize: '32px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>Directorios</div>
                {/* <h3>Dashboard a {this.state.nombremes} de {this.state.ejercicio}</h3> */}
              </div>
              
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-end', width: '50%' }}>
              <Link to={{pathname:"/upload"}} style={{ padding:'7px 0px 0px 15px',
                  display: 'flex', flexWrap: 'wrap', flexDirection: 'row',
                  cursor:'pointer',
                  border: '0px',
                  borderRadius:'0px',
                  color:"#000",
                  backgroundColor: "rgba(255,193,53,1)",
                  fontFamily: 'CircularStd-Bold',fontSize: '15px', minWidth: '180px', width: '180px', height: '38px',textDecoration: 'none',borderRadius:'5px'}}>
              <div style={{verticalAlign:'middle'}}><HiOutlineDocumentText size={'20px'}/></div>&nbsp;&nbsp;<div style={{lineHeight:'1.60'}}>Subir 
                Documento</div>
                
                </Link>&nbsp;&nbsp;
                <input className="documentosinput"
                  ref={(input) => { this.secondText = input; }}
                  value={search}
                  type='text'
                  placeholder="Buscar Documento"

                  // onChange=
                  // {this.updateSearch}
                  onChange={event => {this.setState({search: event.target.value})}}
                   onKeyPress={event => {
                     if (event.key === 'Enter') {
                        this.updateSearch2()
                        
                        this.setState({ cargando: true })
                }
              }}
                />

              </div>

            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>


              {this.state.datos.length > 0 ? this.state.datos.map((dato, i) =>

                <Caryt
                  cabecera={dato.Nombre}
                  spano2={dato.Size + ' Mb'}
                  numdocs={dato.Numdocs}
                  accion={dato.Tipo}
                  modo={this.state.modoSearch}
                  busqueda={this.state.search}

                />

              ):
                // : this.state.search != ''   &&

                <div style={{ display: 'flex', fontFamily: 'CircularStd-Light' }}>{this.state.datos.mensaje}</div>
              }








            </div>

          </div>
        }
      </div>



    );


  }
}









