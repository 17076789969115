import React,{ useState, useEffect,useLocation } from 'react';
/* import Routes from "../routes"; */
import Sidebar from "./Sidebar";
import Nav from "./Nav";




function Layout(props) {
  

useEffect(() => {
  if(typeof sessionStorage['token']==='undefined')  alert('no existe');
}, [])



    return (
       
            <div style={{display: "flex"}}>
                <Sidebar history={props.history} default="0"/>
                
                <div >
                     <Nav /> 

                <div style={{marginTop:'30px',height:'calc(100% - 30px)'}}>{props.children} </div> 
                </div>
           </div>
           
      
    );
}

export default Layout;
