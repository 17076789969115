import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import Caryt from '../components/card7'
import ApiService from "./ApiService";
import BarLoader from "react-spinners/BarLoader";
import Select from 'react-select'
import EllipsisText from "react-ellipsis-text";
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { HiOutlineChevronLeft, HiChevronLeft, HiOutlineArrowCircleLeft, HiArrowSmLeft, HiOutlineDocumentText, HiOutlineTrendingUp, HiOutlineDocumentDuplicate, HiOutlineCurrencyEuro, HiOutlineCash, HiOutlineCalculator, HiOutlineChartSquareBar, HiChevronRight, HiOutlineArrowCircleRight, HiOutlineChartBar, HiOutlineArrowSmRight, HiOutlinePresentationChartLine, HiOutlineFolder, HiOutlineChartPie } from 'react-icons/hi';


export default class Cuentas extends React.Component {

  state = {
    datos: [],
    filter: '',
    cargando: true,
    cargando2: true,
    selectedValue: null,
    micliente: sessionStorage.getItem('cliente'),
    //ESTADOS DEL ÚLTIMO EJERCICIO Y MES DISPONIBLES EN LA BBDD

    mes: '',
    meses: [],
    nombremes: '',
    ejercicio: '',
    ejercicios: [],
    maxmes: '',
    epigrafe: this.props.location.state.epigrafe,
    concepto: this.props.location.state.pagina=="balance" ? this.props.location.state.concepto.split(". ").pop() : this.props.location.state.pagina=="cr" ? this.props.location.state.concepto.split(")").pop():this.props.location.state.pagina=="" ? this.props.location.state.concepto:this.props.location.state.concepto,
    pagina:this.props.location.state.pagina
  }

  datosheredados = async () => {
   this.setState({ ejercicio: this.props.location.state.ejercicio, mes: this.props.location.state.mimes, nombremes: this.props.location.state.nombremes })
  //alert(this.props.location.state.ejercicio +this.props.location.state.mimes+ this.props.location.state.nombremes)
  }
  // Permite obtener y modificar el estado
  meses = async () => {
    return ApiService.fetchDatosMeses(this.state.micliente, this.state.ejercicio)
      .then(response => {
        // console.log(this.state.ejercicio)

        this.setState(
          {
            meses: response.data
          })



      })
      .catch(error => {


      });

  }
  ejercicios = async () => {
    //console.log(this.state.micliente)
    return ApiService.fetchDatosEjercicios(this.state.micliente)
      .then(response => {

        this.setState(
          {
            maxmes: response.data[0].maxmes, ejercicios: response.data, ejercicio: response.data[0].maxejer, mes: response.data[0].maxmes, nombremes: response.data[0].meses
          })

      })
      .catch(error => {


      });

  };


  async cargaDatos() {

    this.setState({ cargando: true })

if(this.state.pagina=="balance"){

   return ApiService.fetchDatosSubCuentasbal(this.state.ejercicio, this.state.mes, this.state.micliente, this.state.epigrafe)
   .then(response => {
    console.log(response.data)
    this.setState({ datos: response.data })
    this.setState({ cargando: false })
  })
  .catch(error => {


  });
}else if(this.state.pagina=="cr"){
  return ApiService.fetchDatosSubCuentasc(this.state.ejercicio, this.state.mes, this.state.micliente, this.state.epigrafe)

  .then(response => {
    console.log(response.data)
    this.setState({ datos: response.data })
    this.setState({ cargando: false })
     })
      .catch(error => {


      });
}else{

  return ApiService.fetchDatosSubCuentas(this.state.ejercicio, this.state.mes, this.state.micliente, this.state.epigrafe)

  .then(response => {
    console.log(response.data)
    this.setState({ datos: response.data })
    this.setState({ cargando: false })
     })
      .catch(error => {


      });
}


      

     

  }
  handleChange = async (e) => {
    this.setState({ cargando: true })
    this.setState({ mes: e.value, nombremes: e.label });
    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    await this.cargaDatos()

    this.setState({ nombremes: e.label });
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(400)
    this.setState({ cargando: false })
  }

  handleChange2 = async (e) => {
    // alert(e.nombre)
    this.setState({ cargando: true })
    this.setState({ ejercicio: e.value, mes: e.maxmes, nombremes: e.nombre });

    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    this.meses(e.value)

    await this.cargaDatos()

    // this.setState({nombremes:e.label});
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(400)
    //alert(this.state.meses)
    this.setState({ cargando: false })
  }


  async componentDidMount() {

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(400)
    await this.ejercicios();

   
    await this.datosheredados()
    await this.meses();

     const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(400)


    await this.cargaDatos()
    const sleep3 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep3(400)
    this.setState({ cargando: false })
    // alert(parseInt(this.state.mes))// Ejecutamos la función 

  }


  change = event => {
    this.setState({ filter: event.target.value });

  }
  handleKeyDown = e => {
    e.currentTarget.value = e.currentTarget.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
  };
  render() {
    const { filter, datos } = this.state;
    const lowercasedFilter = filter.toLowerCase();
    const filteredData = datos.length > 0 ? datos.filter(item => {
      return Object.keys(item).some(key =>
        typeof item[key] === "string" && item[key].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(lowercasedFilter)
      );
    }):'';
    const options =
      this.state.meses.map((dato, i) => {
        return {
          label: dato.meses,
          value: dato.nummes,
          nombre: dato.meses


        }
      }
      );
    const options2 =
      this.state.ejercicios.map((dato, i) => {
        return {
          label: dato.ejercicios,
          value: dato.ejercicios,
          maxmes: dato.maxmes,
          nombre: dato.meses
        }
      }
      );

      const dot = (color = "rgb(255,193,53)") => ({
        alignItems: "center",
        display: "flex",
        color: '#000000',
        borderColor: "black",
  
        ":before": {
          backgroundColor: "#fff",
          // borderRadius: 1,
          // content: '" "',
          // display: "block",
          // marginRight: 8,
          // height: 10,
          // width: 10
  
        }
      });
      const styles = {
  
        control: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            fontFamily: 'CircularStd-Bold',
            borderColor: "black",
            boxShadow: 'none',
            border: '2px ',
            borderWidth: "1px",
            borderRadius: "5px",
            backgroundColor: isFocused ? "rgba(255,193,53,1)" : "rgba(255,193,53,1)",
            fontSize: '15px', fontColor: 'white', minWidth: '170px', width: '170px', height: '35px',
            ":hover": {
              ...styles[":hover"],
              backgroundColor: "rgba(255,193,53,0.30)",
  
              color: "white",
              borderColor: "white"
            },
  
          };
        },
        indicatorSeparator: style => ({
          ...style,
          display: 'none',
          color: '#000000'
        }),
        dropdownIndicator: style => ({
          ...style,
  
          color: '#000000'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            minWidth: '150px',
            height: '35px',
            borderRadius: '0px',
            
            backgroundColor: isSelected ? "rgba(255,193,53,0.60)" : "#fff",
            // width:'150px',
            color: "#000",
            fontFamily: 'CircularStd-Book',
            cursor: isDisabled ? "not-allowed" : "default",
            ":active": {
              ...styles,
              backgroundColor: "#000000",
              color: 'black',
              borderColor: "black"
            },
            ":hover": {
              ...styles[":hover"],
              backgroundColor: "rgba(255,193,53,0.30)",
              color: "black",
              borderColor: "black"
            },
  
          };
        },
  
        menu: styles => ({ ...styles, marginTop: '3px', borderRadius: '5px', borderColor: "black", backgroundColor: "white", fontSize: '13px', minWidth: '168px', width: '168px' }),
        input: styles => ({ ...styles, borderColor: "black", borderWidth: "0px" }),
        placeholder: styles => ({ ...styles, ...dot() }),
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
      };


    return (

      <div style={{ padding: '24px', minWidth: 'calc(100vw- 265px)', width: 'calc(100vw  - 265px)', minHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}>
        {this.state.cargando ?

          <div style={{
            display: 'flex',

            height: '90vh',
            width: 'calc(100vw-250px)',
            justifyContent: 'center',
            alignItems: 'center',


          }}>
            <BarLoader color={'#000'} size={40} />
            {/* <img src={Spinner} style={{width:'50px'}}/> */}
            {/* <i style={{fontSize:25,color:'#4682b4'}} className="fas fa-circle-notch fa-spin" />  */}
          </div>
          :


          <div style={{ margin: '10px ', minWidth: 'calc(100vw- 265px)', verticalAlign: 'middle', maxWidth: '1500px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>

              <div style={{ display: 'flex', justifyContent: 'flex-start', width: '60%' }}>
                {/* <Link to={{ pathname: "/dashboard" }} style={{ textDecoration: 'none' }}>  */}
                <HiOutlineChevronLeft style={{ cursor:'hand',marginTop: '10px', color: '#000000' }} size={30} onClick={()=> window.history.go(-1)}/> 
                {/* </Link>  */}
                &nbsp; &nbsp;
                <div style={{ display: 'flex', fontSize: '32px', fontFamily: 'CircularStd-Bold', color: '#000000' }}><EllipsisText text={this.state.concepto.charAt(0).toUpperCase() + this.state.concepto.slice(1)} length={"28"} /></div>
                {/* <h3>Dashboard a {this.state.nombremes} de {this.state.ejercicio}</h3> */}
              </div>
              <div style={{ marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-end', width: '40%' }}>

                <Select
                  isSearchable={false}
                  // value={selectedOption}
                  value={{ value: this.state.ejercicio, label: this.state.ejercicio }}
                  onChange={this.handleChange2}

                  options={options2} styles={styles} placeholder="Ejercicios" /> &nbsp;
                <Select

                  isSearchable={false}
                  //defaultValue={options[1]}
                 // value={options.filter(({ value }) => value === this.state.mes)}
                 value={{ value: this.state.mes, label: this.state.nombremes }}
                  onChange={this.handleChange}
                  options={options} styles={styles} placeholder="Meses" />

              </div>

            </div>
            <div style={{ marginTop: '10px', marginBottom: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>

              <input className="documentosinput"
                ref={(input) => { this.secondText = input; }}

                type='text'
                placeholder="Buscar..."
                onKeyDown={this.handleKeyDown}
                onChange={this.change}
              />
            </div>
            <div style={{ backgroundColor: '', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>
              <div style={{ position: 'relative', height: '555px', overflow: 'auto', display: 'block' }}>
                <MiTabla>
                  <table >


                    <tr>
                      <th style={{ textAlign: 'left' }}>Cuenta</th>
                      <th>Concepto</th>
                      <th style={{ textAlign: 'right' }}>Saldo {this.state.nombremes}</th>
                      <th style={{ textAlign: 'right' }}>Saldo Acumulado</th>
                    </tr>


                    {this.state.datos.length > 0 && filteredData.map((dato, i) =>

                      <Caryt
                        titulo={dato.cuenta}
                        acumulado={dato.descripcion.toLowerCase()}
                        actual={dato.saldo}
                        anterior={dato.saldoactual}
                        nombremes={this.state.nombremes}
                        epigrafe={dato.cuenta}
                        concepto={dato.descripcion}
                        ejercicio={this.state.ejercicio}
                        mimes={this.state.mes}
                        subepigrafe={this.state.epigrafe}
                        subconcepto={this.state.concepto}
                        pagina={this.state.pagina}
                      />

                    )}
                    

                  </table>
                </MiTabla>
                {

                    <p style={{backgroundColor:'white',textAlign:'center',width:'100%'}}>{this.state.datos.mensaje}</p> 
                     }
              </div>
            </div>


          </div>
        }
      </div>



    );


  }
}
const MiTabla = styled.table`
  table {
    font-size:15px;
    background-color:white;
    table-layout: fixed;
    border-collapse:collapse;
    padding:5px;
    //box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px ;
    width: 100%;
  }
  th {
    font-size:15px;
    font-family:'CircularStd-Bold';
    color:#000000;
    border:1px solid lightgray;
     padding:5px;
     background-color:rgba(223,223,223,1);
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
  }
  td {
    font-family:'CircularStd-Light';
    color:'#000000';
    border:1px solid lightgray;
    padding:5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
  }
  table tr th:nth-child(1){
    width: 5%;
   
  }
  table tr th:nth-child(2){
    width: 20%;
  }
  table tr th:nth-child(3){
    width: 10%;
  }
  table tr th:nth-child(4){
    width: 10%;
  }
  table tr th:nth-child(5){
    width: 10%;
   
  }
  table tr th:nth-child(6){
    width: 10%;
  }
  table tr th:nth-child(7){
    width: 10%;
  }
  table tr th:nth-child(8){
    width: 10%;
  }
  tr:hover{
    background-color:#d1d1d140;
  
  }
  tr {
    cursor: pointer;
  }
  `;