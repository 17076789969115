import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Caryt from '../components/card5'
import axios from 'axios';
import Select from 'react-select'
import ApiService from "./ApiService";
import Spinner from "../assets/icons8-loading-infinity.gif"
import Moment from 'moment';
import BarLoader from "react-spinners/BarLoader";

import  Modal  from 'react-bootstrap/Modal'

import Button from 'react-bootstrap/Button'
import { HiOutlineTrash,HiOutlineArrowCircleLeft, HiArrowSmLeft, HiOutlineDocumentText, HiOutlineTrendingUp, HiOutlineDocumentDuplicate, HiOutlineCurrencyEuro, HiOutlineCash, HiOutlineCalculator, HiOutlineChartSquareBar, HiChevronRight, HiOutlineArrowCircleRight, HiOutlineChartBar, HiOutlineArrowSmRight, HiOutlinePresentationChartLine, HiOutlineFolder, HiOutlineChartPie } from 'react-icons/hi';

export default class Documentosub extends React.Component {

  state = {
    datos: [],
    cargando: true,

    micliente: '',
    //ESTADOS DEL ÚLTIMO EJERCICIO Y MES DISPONIBLES EN LA BBDD

    mes: '',
    meses: [],
    nombremes: '',
    ejercicio: '',
    ejercicios: [],
    maxmes: '',
    tipo: '',
    modoBusqueda: false,
    mibusqueda: '',
    show:false,
    nombredoc:'',
    iddoc:'',
   
  }


  meses = async () => {
    return ApiService.fetchDatosMeses(this.state.micliente, this.state.ejercicio)
      .then(response => {
        // console.log(this.state.ejercicio)

        this.setState(
          {
            meses: response.data
          })



      })
      .catch(error => {


      });

  }
  ejercicios = async () => {
    //console.log(this.state.micliente)
    return ApiService.fetchDatosEjercicios(this.state.micliente)
      .then(response => {

        this.setState(
          {
            maxmes: response.data[0].maxmes, ejercicios: response.data, ejercicio: response.data[0].maxejer, mes: response.data[0].maxmes, nombremes: response.data[0].meses
          })

      })
      .catch(error => {


      });

  };

  miusuario = async () => {
    // return  ApiService.fetchDocumentod(this.state.micliente, this.state.tipo)

    //         .then(res => {

    //           this.setState({datos:res.data})

    //         })

    if (this.state.modoBusqueda === true) {
      console.log('entrando por busqueda')
      return ApiService.fetchDocumentobusd(this.state.micliente, this.props.location.state.mibusqueda, this.state.tipo)
        .then(response => {
          console.log(response.data.length)
          this.setState({ datos: response.data })
          this.setState({ cargando: false })
          
        })
        .catch(error => {


        });
    } else {
      return ApiService.fetchDocumentod(this.state.micliente, this.state.tipo)
        .then(response => {
          console.log(response.data.length)
          this.setState({ datos: response.data })
          this.setState({ cargando: false })
          
        })


        .catch(function (error) {
          console.log(error);
        });
    }


  };
   handleClick = async (e) => {
    

   //alert('borramos'+this.state.iddoc)
    e.preventDefault();
    await axios.post("https://apicore.myleistung.com/documento/eliminar/"+this.state.iddoc)
    .then(response => {
      this.setState({show:false}); 
      if(this.state.datos.length <=1){this.props.history.push('/documentos') }
      
      
      this.miusuario();

    })


    .catch(function (error) {
      console.log(error);
    });

  };
handleDelete= async (e)=>{

  this.setState({show:true,nombredoc:e.currentTarget.title,iddoc: e.currentTarget.id})
};
  async componentDidMount() {
   
    // const { id } = this.props.match.params;
    const {id, modoBusqueda, mibusqueda } = this.props.location.state;

    this.setState({ tipo: id, modoBusqueda: modoBusqueda, mibusqueda: mibusqueda })
    this.setState({ micliente: sessionStorage.getItem("cliente") });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(400)
    //alert(this.state.tipo)

    await this.ejercicios();

    // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    // await sleep(400)

    await this.meses();

    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(600)
    await this.miusuario();
    
// c
    const sleep3 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep3(800)
    
    this.setState({ cargando: false })


    // alert(parseInt(this.state.mes))// Ejecutamos la función 

  }
  
  handleChange = async (e) => {
    this.setState({ cargando: true })
    this.setState({ mes: e.value, nombremes: e.label });
    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    this.miusuario()

    this.setState({ nombremes: e.label });
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(600)
    this.setState({ cargando: false })
  }

  handleChange2 = async (e) => {
    // alert(e.nombre)
    this.setState({ cargando: true })
    this.setState({ ejercicio: e.value, mes: e.maxmes, nombremes: e.nombre });

    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    this.meses(e.value)

    this.miusuario()

    // this.setState({nombremes:e.label});
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(600)
    //alert(this.state.meses)
    this.setState({ cargando: false })
  }
  handleClose = async () => {this.setState({show:false})}
  handleShow = async () => {this.setState({show:true})}

  render() {

    const options =
      this.state.meses.map((dato, i) => {
        return {
          label: dato.meses,
          value: dato.nummes,
          nombre: dato.meses


        }
      }
      );
    const options2 =
      this.state.ejercicios.map((dato, i) => {
        return {
          label: dato.ejercicios,
          value: dato.ejercicios,
          maxmes: dato.maxmes,
          nombre: dato.meses
        }
      }
      );

    const dot = (color = "#395482") => ({
      alignItems: "center",
      display: "flex",
      color: '#395482',
      borderColor: "black",

      ":before": {
        backgroundColor: "#fff",
        // borderRadius: 1,
        // content: '" "',
        // display: "block",
        // marginRight: 8,
        // height: 10,
        // width: 10

      }
    });
    const styles = {

      control: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          fontFamily: 'CircularStd-Bold',
          borderColor: "black",
          boxShadow: 'none',
          border: '2px ',
          borderWidth: "1px",
          borderRadius: "0px",
          backgroundColor: isFocused ? "#39548290" : "#39548240",
          fontSize: '15px', fontColor: 'white', minWidth: '170px', width: '170px', height: '35px',
          ":hover": {
            ...styles[":hover"],
            backgroundColor: "#39548240",

            color: "white",
            borderColor: "white"
          },

        };
      },
      indicatorSeparator: style => ({
        ...style,
        display: 'none',
        color: '#395482'
      }),
      dropdownIndicator: style => ({
        ...style,

        color: '#395482'
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          minWidth: '150px',
          height: '35px',
          borderRadius: '0px',
          backgroundColor: isSelected ? "#00000050" : "#fff",
          // width:'150px',
          color: "#000",
          fontFamily: 'CircularStd-Book',
          cursor: isDisabled ? "not-allowed" : "default",
          ":active": {
            ...styles,
            backgroundColor: "#000000",
            color: 'black',
            borderColor: "black"
          },
          ":hover": {
            ...styles[":hover"],
            backgroundColor: "#00000080",
            color: "white",
            borderColor: "black"
          },

        };
      },

      menu: styles => ({ ...styles, marginTop: '0px', borderRadius: '0px', borderColor: "black", backgroundColor: "white", fontSize: '13px', minWidth: '168px', width: '168px' }),
      input: styles => ({ ...styles, borderColor: "black", borderWidth: "0px" }),
      placeholder: styles => ({ ...styles, ...dot() }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
    };
    return (
      <div style={{ padding: '24px', minWidth: 'calc(100vw- 270px)', width: 'calc(100vw  - 270px)', minHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}>




        {this.state.cargando ?

          <div style={{
            display: 'flex',

            height: '90vh',
            width: 'calc(100vw-250px)',
            justifyContent: 'center',
            alignItems: 'center',


          }}>
            <BarLoader color={'#000000'} size={40} />
            {/* <img src={Spinner} style={{width:'50px'}}/> */}
            {/* <i style={{fontSize:25,color:'#4682b4'}} className="fas fa-circle-notch fa-spin" />  */}
          </div>
          :


          <div style={{ margin: '10px ', minWidth: 'calc(100vw- 265px)', verticalAlign: 'middle', maxWidth: '1500px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>

              <div style={{ display: 'flex', justifyContent: 'flex-start', width: '60%' }}>
                <div style={{ display: 'flex', height: '50px', fontSize: '32px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>{this.state.datos[0].Nombre} </div>&nbsp;&nbsp;
                {/* <h3>Dashboard a {this.state.nombremes} de {this.state.ejercicio}</h3> */}
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-end', width: '40%' }}>

                <button style={{ display: 'inline-block', textAlign: 'center', minWidth: '50px', height: '35px', border: '0px solid #39548240', backgroundColor: 'rgba(255,193,53,1)', padding: '0px', fontSize: '16px', fontFamily: 'CircularStd-Book', color: '#000000',borderRadius:'5px' }} onClick={() => this.props.history.push('/documentos')}><HiArrowSmLeft size={20} /></button>

              </div>

            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>


              {this.state.datos.length > 0 && this.state.datos.map((dato, i) =>

                <Caryt
                  cabecera={dato.Descripcion.toLowerCase()}
                  titulo={dato.Descripcion.toLowerCase()}
                  spano2={dato.Nombre}
                  tamano={dato.Tamano}
                  fichero={dato.Fichero}
                  tipo={this.state.tipo}
                  Id={dato.Id}
                  fecha={Moment(dato.Fecha).format('DD/MM/YYYY')}
                  call={this.handleDelete}

                />

              )}


            </div>

          </div>
        } <>
      <Modal show={this.state.show} onHide={this.handleClose} style={{fontFamily:'CircularStd-Book'}}>
        <Modal.Header closeButton >

          <Modal.Title style={{fontFamily:'CircularStd-Bold'}}>Eliminar Documento</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{fontFamily:'CircularStd-Light',textAlign:'center' }}>¿Quieres borrar el documento <strong>{this.state.nombredoc.toUpperCase()}</strong> ?
       <div style={{display: 'flex',alignContent:'center',justifyContent:'center'}}>
          <Button onClick={this.handleClose} style={{borderRadius:'5px',border:'0px solid ',alignItems:'center', justifyContent:'center',width:'100px',
                display: 'flex',marginTop:'45px',right:'100px',padding:'5px',backgroundColor:'rgba(255,193,53,1)',color:'#000', fontFamily: 'CircularStd-Book',fontSize:'14px' }}>
            Cancelar
          </Button>&nbsp;&nbsp;
          <Button onClick={this.handleClick} style={{borderRadius:'5px',border:'0px solid ',alignItems:'center', justifyContent:'center',width:'100px',
                display: 'flex',marginTop:'45px',right:'100px',padding:'5px',backgroundColor:'rgba(255,193,53,1)',color:'#000', fontFamily: 'CircularStd-Book',fontSize:'14px' }}>
            Eliminar
          </Button>
          </div>
       </Modal.Body>
      </Modal>
</>
      </div>
     

    );


  }
}









