import React from 'react';
import  Modal  from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import './card.css'
import  {HiOutlineShare,HiOutlineDotsVertical,HiCloud,HiChartBar,HiOutlineDocumentText,HiOutlineTrendingUp,HiOutlineDocumentDuplicate,HiOutlineCurrencyEuro,HiOutlineCash,HiOutlineCalculator,HiOutlineChartSquareBar,HiChevronRight,HiOutlineArrowCircleRight,HiOutlineChartBar,HiOutlineArrowSmRight,HiOutlinePresentationChartLine,HiOutlineFolder,HiOutlineChartPie} from 'react-icons/hi';

function Card2(props) {
 
return(
              <div className="card2">
                  <div className="card-content">
                  <div style={{ cursor:'pointer',fontSize: '22px', fontFamily: 'CircularStd-Bold', color: '#000000' }} id={props.Id} title={props.nombreratio} onClick={props.call} >
                  <HiOutlineDotsVertical style={{
                    fontSize: '22px', float: 'right', marginTop: '5px',
                    color: '#000000'
                  }} /> 
               
        </div>
                  <div style={{fontSize:'22px',fontFamily:'CircularStd-Bold',color:'rgba(0,0,0,0.71)'}}>{props.titulo} <HiChartBar style={{
                              fontSize:'22px',float:'right',marginTop:'5px',
                              color: props.semaforo==''?'#00000051':props.semaforo=='verde'?'green': props.semaforo=='rojo'?'red':props.semaforo=='naranja'?'orange':''}}/>
                   </div>  

                  {/* <div style={{fontSize:'22px',fontFamily:'CircularStd-Light',color:'#000000'}}>{props.titulo}</div>   */}
                    <div style={{fontSize:'20px',fontFamily:'CircularStd-Light',color:'black'}}>{props.valor} </div>
                    <div style={{fontFamily:'CircularStd-Light',fontSize:'14px',color:'gray'}}> {props.comentario===''? props.comentario:'Comentario: '+props.comentario }</div>
                  </div>
              </div>



);
}
export default Card2;

// texto3={dator.semaforo==''?'#2E5786':dator.semaforo=='verde'?'green': dator.semaforo=='rojo'?'red':dator.semaforo=='naranja'?'orange':''}