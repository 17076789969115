import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Caryt from '../components/cardefe'
import axios from 'axios';
import Select from 'react-select'
import ApiService from "./ApiService";
import Spinner from "../assets/icons8-loading-infinity.gif"
import BarLoader from "react-spinners/BarLoader";
import { HiOutlineExclamation, HiOutlineEyeOff, HiOutlineEye, HiOutlineCalendar, HiOutlineBriefcase, HiOutlineDatabase, HiOutlineCog, HiOutlineDocumentText, HiOutlineTrendingUp, HiOutlineDocumentDuplicate, HiOutlineCurrencyEuro, HiOutlineCash, HiOutlineCalculator, HiOutlineChartSquareBar, HiChevronRight, HiOutlineArrowCircleRight, HiOutlineChartBar, HiOutlineArrowSmRight, HiOutlinePresentationChartLine, HiOutlineFolder, HiOutlineChartPie } from 'react-icons/hi';
import styled from 'styled-components';
import { Bar, Line } from 'react-chartjs-2';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import { VscFilePdf } from 'react-icons/vsc';

export default class VentasFamilias extends React.Component {

  state = {
    datos: [],
    datos2: [],
    datos3: [],
    cargando: true,
    mensaje: '',
    micliente: sessionStorage.getItem('cliente'),
    //ESTADOS DEL ÚLTIMO EJERCICIO Y MES DISPONIBLES EN LA BBDD
    show: false,
    mes: '',
    meses: [],
    nombremes: '',
    ejercicio: '',
    ejercicios: [],
    maxmes: '',
    ver: false,
    cambiotexto: 'E.F.E',
  }
  descargaPdf = async () => {

    var a = document.createElement('a');
    a.href = "https://apicore.myleistung.com/cashflow/" + this.state.ejercicio + "/" + this.state.mes + "/" + this.state.micliente + "/" + sessionStorage.getItem("usuario").toUpperCase();
    a.download = 'download';
    a.click();
  }
  handleDelete = async (e) => {
    // alert(e.currentTarget.id)

    this.setState({ show: true })
  };
  handleClose = async () => { this.setState({ show: false }) }
  handleShow = async () => { this.setState({ show: true }) }
  meses = async () => {
    return ApiService.fetchDatosMeses(this.state.micliente, this.state.ejercicio)
      .then(response => {
        // console.log(this.state.ejercicio)

        this.setState(
          {
            meses: response.data
          })



      })
      .catch(error => {


      });

  }
  ejercicios = async () => {
    //console.log(this.state.micliente)
    return ApiService.fetchDatosEjercicios(this.state.micliente)
      .then(response => {

        this.setState(
          {
            maxmes: response.data[0].maxmes, ejercicios: response.data, ejercicio: response.data[0].maxejer, mes: response.data[0].maxmes, nombremes: response.data[0].meses
          })

      })
      .catch(error => {


      });

  };

  miusuario = async () => {
    if (this.state.ver === true) {
      axios.get('https://apicore.myleistung.com/efe/' + this.state.micliente + '/' + this.state.mes + '/' + this.state.ejercicio, {

      })
        .then(res => {

          this.setState({ datos: res.data })

        })
        .catch(function (error) {
          console.log(error);
        })
    } else { }




  };
  miusuario2 = async () => {
    axios.get('https://apicore.myleistung.com/anaefe/' + this.state.micliente + '/' + this.state.mes + '/' + this.state.ejercicio, {

    })
      .then(res => {

        this.setState({ datos2: res.data })

      })
      .catch(function (error) {
        console.log(error);
      })





  };
  miusuariochart = async () => {
    axios.get('https://apicore.myleistung.com/anaefe/' + this.state.micliente + '/' + this.state.mes + '/' + this.state.ejercicio, {

    })
      .then(res => {

        this.setState({ datos3: res.data })

      })
      .catch(function (error) {
        console.log(error);
      })





  };
  cargaver = async () => {
    //await this.ejercicios();

    // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    // await sleep(400)

    // await this.meses();
    await this.miusuario2();
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(600)
    await this.miusuario();

    const sleep3 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep3(800)

    this.setState({ cargando: false })
  }
  async componentDidMount() {

    await this.ejercicios();

    // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    // await sleep(400)

    await this.meses();
    await this.miusuario2();
    await this.miusuariochart();
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(600)
    await this.miusuario();

    const sleep3 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep3(800)

    this.setState({ cargando: false })


    // alert(parseInt(this.state.mes))// Ejecutamos la función 

  }
  handleChange = async (e) => {
    this.setState({ cargando: true })
    this.setState({ mes: e.value, nombremes: e.label });
    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)
    this.miusuario2()
    this.miusuario()

    this.setState({ nombremes: e.label });
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(600)
    this.setState({ cargando: false })
  }

  handleChange2 = async (e) => {
    // alert(e.nombre)
    this.setState({ cargando: true })
    this.setState({ ejercicio: e.value, mes: e.maxmes, nombremes: e.nombre });

    //alert(e.value)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300)

    this.meses(e.value)
    this.miusuario2()
    this.miusuario()

    // this.setState({nombremes:e.label});
    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(600)
    //alert(this.state.meses)
    this.setState({ cargando: false })
  }


  render() {


    const datas = {
      labels: ['FEAE', 'FEAI', 'FEAF'],
      datasets: [
        {
          label: ['FEAE', 'FEAI', 'FEAF'],
          backgroundColor: 'rgba(50,74,117,0.51)',
          // borderColor: '#4682b4',
          // borderWidth: 1,
          hoverBackgroundColor: '#4682b440',
          hoverBorderColor: '#4682b4',
          data: this.state.datos2.feae
        }
      ]
    };

    const options =
      this.state.meses.map((dato, i) => {
        return {
          label: dato.meses,
          value: dato.nummes,
          nombre: dato.meses


        }
      }
      );
    const options2 =
      this.state.ejercicios.map((dato, i) => {
        return {
          label: dato.ejercicios,
          value: dato.ejercicios,
          maxmes: dato.maxmes,
          nombre: dato.meses
        }
      }
      );


    const dot = (color = "rgb(255,193,53)") => ({
      alignItems: "center",
      display: "flex",
      color: '#000000',
      borderColor: "black",

      ":before": {
        backgroundColor: "#fff",
        // borderRadius: 1,
        // content: '" "',
        // display: "block",
        // marginRight: 8,
        // height: 10,
        // width: 10

      }
    });
    const styles = {

      control: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          fontFamily: 'CircularStd-Bold',
          borderColor: "black",
          boxShadow: 'none',
          border: '2px ',
          borderWidth: "1px",
          borderRadius: "5px",
          backgroundColor: isFocused ? "rgba(255,193,53,1)" : "rgba(255,193,53,1)",
          fontSize: '15px', fontColor: 'white', minWidth: '170px', width: '170px', height: '35px',
          ":hover": {
            ...styles[":hover"],
            backgroundColor: "rgba(255,193,53,0.30)",

            color: "white",
            borderColor: "white"
          },

        };
      },
      indicatorSeparator: style => ({
        ...style,
        display: 'none',
        color: '#000000'
      }),
      dropdownIndicator: style => ({
        ...style,

        color: '#000000'
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          minWidth: '150px',
          height: '35px',
          borderRadius: '0px',

          backgroundColor: isSelected ? "rgba(255,193,53,0.60)" : "#fff",
          // width:'150px',
          color: "#000",
          fontFamily: 'CircularStd-Book',
          cursor: isDisabled ? "not-allowed" : "default",
          ":active": {
            ...styles,
            backgroundColor: "#000000",
            color: 'black',
            borderColor: "black"
          },
          ":hover": {
            ...styles[":hover"],
            backgroundColor: "rgba(255,193,53,0.30)",
            color: "black",
            borderColor: "black"
          },

        };
      },

      menu: styles => ({ ...styles, marginTop: '3px', borderRadius: '5px', borderColor: "black", backgroundColor: "white", fontSize: '13px', minWidth: '168px', width: '168px' }),
      input: styles => ({ ...styles, borderColor: "black", borderWidth: "0px" }),
      placeholder: styles => ({ ...styles, ...dot() }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
    };
    return (
      <div style={{ padding: '24px', minWidth: 'calc(100vw- 265px)', width: 'calc(100vw  - 265px)', minHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}>




        {this.state.cargando ?

          <div style={{
            display: 'flex',

            height: '90vh',
            width: 'calc(100vw-250px)',
            justifyContent: 'center',
            alignItems: 'center',


          }}>
            <BarLoader color={'#000'} size={40} />
            {/* <img src={Spinner} style={{width:'50px'}}/> */}
            {/* <i style={{fontSize:25,color:'#4682b4'}} className="fas fa-circle-notch fa-spin" />  */}
          </div>
          :


          <div style={{ margin: '10px ', minWidth: 'calc(100vw- 265px)', verticalAlign: 'middle', maxWidth: '1500px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>

              <div style={{ display: 'flex', justifyContent: 'flex-start', width: '60%' }}>
                <div style={{ display: 'flex', fontSize: '32px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>Análisis Cash Flow</div>
                {/* <h3>Dashboard a {this.state.nombremes} de {this.state.ejercicio}</h3> */}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-end', width: '40%' }}>

                <div style={{
                  pointerEvents: this.state.datos2.length > 0 ? 'auto' : 'none',
                  opacity: this.state.datos2.length > 0 ? '1' : '0', display: 'flex', borderRadius: '5px', border: '1px solid #39548240'
                }}>
                  <button className="boton" onClick={this.descargaPdf}><VscFilePdf size={20} style={{ marginTop: '-3px', lineHeight: '20px' }} /></button>

                </div>  &nbsp;&nbsp;
                <div style={{
                  opacity: this.state.datos2.length > 0 ? '1' : '0',


                  display: 'flex', flexWrap: 'wrap', flexDirection: 'row',
                  cursor: 'pointer',
                  border: '0px',
                  borderRadius: '5px',
                  color: "#000",
                  backgroundColor: "rgba(255,193,53,1)",
                  fontFamily: 'CircularStd-Bold', fontSize: '15px', minWidth: '130px', width: '130px', height: '38px',
                }} onClick={() => {

                  this.handleDelete()
                }}>

                  <div style={{ lineHeight: '38px', textAlign: 'center', width: '100%' }}><HiOutlineChartSquareBar size={'20px'} />&nbsp;&nbsp;Gráfica</div>
                </div>&nbsp;&nbsp;
                <div
                  style={{
                    pointerEvents: this.state.datos2.length > 0 ? 'auto' : 'none',
                    opacity: this.state.datos2.length > 0 ? '1' : '0',
                    padding: '7px 0px 0px 35px',
                    display: 'flex', flexWrap: 'wrap', flexDirection: 'row',
                    cursor: 'pointer',
                    border: '0px',
                    borderRadius: '5px',
                    color: "#000",
                    backgroundColor: "rgba(255,193,53,1)",
                    fontFamily: 'CircularStd-Bold', fontSize: '15px', minWidth: '130px', width: '130px', height: '38px',
                  }} onClick={() => {

                    this.state.ver === true
                      ? this.setState({ ver: false, cambiotexto: 'E.F.E', datos: [] }, this.cargaver)
                      : this.setState({ ver: true, cambiotexto: 'E.F.E', datos: [] }, this.cargaver)
                  }}>
                    <div style={{ verticalAlign: 'middle' }}>{this.state.ver ? <HiOutlineEyeOff size={'20px'} /> : <HiOutlineEye size={'20px'} />}</div>&nbsp;&nbsp;<div style={{ lineHeight: '1.60' }}>{this.state.cambiotexto}</div>
                    
                    </div>
                    &nbsp;&nbsp;&nbsp;


                <Select
                  isSearchable={false}
                  // value={selectedOption}
                  value={{ value: this.state.ejercicio, label: this.state.ejercicio }}
                  onChange={this.handleChange2}

                  options={options2} styles={styles} placeholder="Ejercicios" /> &nbsp;
                <Select

                  isSearchable={false}
                  //defaultValue={options[1]}
                  value={options.filter(({ value }) => value === this.state.mes)}
                  onChange={this.handleChange}
                  options={options} styles={styles} placeholder="Meses" />

              </div>

            </div>

            {this.state.datos2.length > 0 ?


              <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>

                {this.state.ver === true && this.state.datos.length > 0 &&

                  <MiTabla>


                    <div style={{ marginTop: '5px', backgroundColor: 'rgba(223,223,223,1)', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '25px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>Estado Flujo Efectivo</div>

                    <table>

                      {this.state.datos.map((dato, i) =>

                        <Caryt
                          concepto={dato.CONCEPTO}
                          saldo={dato.N}
                          estilo={dato.ESTILO}

                        />

                      )}

                    </table>&nbsp;&nbsp;
                  </MiTabla>
                }
                <div className="cardefe" >
                  <div style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Flujos de Efectivo Explotación</div>
                  <div style={{ color: 'gray', fontSize: '18px', fontFamily: 'CircularStd-Light' }}>{this.state.datos2.map((datos2, i) => datos2.feae)} €</div>
                </div>
                <div className="cardefe" >
                  <div style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Flujos de Efectivo Inversión</div>
                  <div style={{ color: 'gray', fontSize: '18px', fontFamily: 'CircularStd-Light' }}>{this.state.datos2.map((datos2, i) => datos2.feai)} €</div>
                </div>
                <div className="cardefe" >
                  <div style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Flujos de Efectivo Financiación</div>
                  <div style={{ color: 'gray', fontSize: '18px', fontFamily: 'CircularStd-Light' }}>{this.state.datos2.map((datos2, i) => datos2.feaf)} €</div>
                </div>
                <div className="cardefe" >
                  <div style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Flujo de Caja Libre</div>
                  <div style={{ color: 'gray', fontSize: '18px', fontFamily: 'CircularStd-Light' }}>{this.state.datos2.map((datos2, i) => datos2.fcf)} €</div>
                </div>
                <div className="cardefe2" >
                  <div style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>Comentario Análisis </div>
                  <div style={{ color: 'gray', fontSize: '15px', fontFamily: 'CircularStd-Light' }}>{this.state.datos2.map((datos2, i) => datos2.comentario)}</div>
                </div>


                <>
                  <Modal aria-labelledby="contained-modal-title-vcenter"
                    centered show={this.state.show} onHide={this.handleClose} dialogClassName="custom-modal-style" style={{ fontFamily: 'CircularStd-Book' }}>
                    <Modal.Header closeButton >
                      <div style={{ display: 'flex', float: 'right', width: '80%' }}>
                        <Modal.Title id="contained-modal-title-vcenter" style={{ fontFamily: 'CircularStd-Bold', color: '#000000' }}>Gráfica E.F.E</Modal.Title>
                      </div> &nbsp;&nbsp;

                    </Modal.Header>
                    <Modal.Body style={{ fontFamily: 'CircularStd-Light', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>


                      <div className="cardefeana" style={{ border: '0px' }}>


                        <Bar
                          data={{
                            labels: ['FEAE', 'FEAI', 'FEAF'],
                            datasets: [
                              {
                                //  label: ['FEAE','FEAI','FEAF'],
                                backgroundColor: 'rgba(0,0,0,0.31)',
                                //  borderColor: '#4682b4',
                                //borderWidth: 1,
                                hoverBackgroundColor: 'rgba(0,0,0,0.21)',
                                hoverBorderColor: 'rgba(0,0,0,0.31)',
                                data: [parseInt(this.state.datos2[0].feae.split('.').join("")), parseInt(this.state.datos2[0].feai.split('.').join("")), parseInt(this.state.datos2[0].feaf.split('.').join(""))]
                              }
                            ]
                          }}
                          options={

                            {

                              elements: {
                                point: {
                                  radius: 4
                                },

                              },
                              tension: 0.3,
                              fill: true,
                              fill: {
                                target: 'start',
                                above: 'rgba(0,0,0,0.31)',   // Area will be red above the origin
                                below: 'rgba(0,0,0,0.31)'    // And blue below the origin
                              },
                              borderWidth: 0,
                              showLine: true,

                              backgroundColor: "rgba(255,193,53,0.60)",
                              borderColor: "rgba(0,0,0,0.61)",

                              pointBackgroundColor: "rgba(255,255,255,0.8)",
                              maintainAspectRatio: true,

                              responsive: true,
                              plugins: {
                                tooltip: {
                                  displayColors: false,
                                  bodyFont: {
                                    size: 13,
                                    family: 'CircularStd-Light'
                                  },
                                  titleFont: {
                                    size: 13,
                                    family: 'CircularStd-Bold'
                                  },
                                },
                                legend: {
                                  display: false,
                                }
                              },
                              animation: {
                                duration: 0,
                              },

                              scales: {

                                x: {
                                  grid: {
                                    display: true,
                                  },
                                  ticks: {
                                    display: true,
                                    font: {
                                      size: 12,
                                      family: 'CircularStd-Bold',
                                    }
                                  }
                                },
                                y: {
                                  beginAtZero: true,
                                  grid: {
                                    display: true,
                                  },
                                  ticks: {
                                    display: false,
                                    font: {
                                      size: 12,
                                      family: 'CircularStd-Book',
                                    }
                                  }
                                },


                              }



                            }}

                        />

                      </div>

                    </Modal.Body>
                  </Modal>
                </>




              </div>
              : <div style={{ borderRadius: '5px', border: '1px solid lightgray', display: 'flex', backgroundColor: 'white', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
                {/* <div style={{backgroundColor:'',  display:'flex',margin: '5px', paddingTop: '10px' }}>
                  <HiOutlineExclamation size={25} />
                </div> */}
                <div style={{ backgroundColor: '', display: 'flex', margin: '5px' }}>
                  {this.state.datos2.mensaje}
                </div>
              </div>
            }
          </div>
        }
      </div>



    );


  }
}

const MiTabla = styled.table`
table {
  
  background-color:white;
  table-layout: fixed;
  border-collapse:collapse;
  
  padding:5px;
  //box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 1px ;
  width: 100%;
}
th {
  font-family:'CircularStd-Bold';
  color:#000000;
  border:1px solid lightgray;
  padding:5px;
  background-color:rgba(223,223,223,1);
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
td {
  font-family:'CircularStd-Light';
  color:'#000000';
  border:1px solid lightgray;
  padding:5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
table tr th:nth-child(1){
  width: 50%;
 
}
table tr th:nth-child(2){
  width: 20%;
}
table tr th:nth-child(3){
  width: 20%;
}
table tr th:nth-child(4){
  width: 10%;
}
table tr th:nth-child(5){
  width: 10%;
 
}
table tr th:nth-child(6){
  width: 10%;
}
table tr th:nth-child(7){
  width: 10%;
}
table tr th:nth-child(8){
  width: 10%;
}
tr:hover{
  background-color:#d1d1d140;

}
tr {
  cursor: pointer;
}
`;







