import React from 'react';
import './card.css'
import Folder from '../assets/folder.png';
import  {HiOutlineDocumentText,HiOutlineTrendingUp,HiOutlineDocumentDuplicate,HiOutlineCurrencyEuro,HiOutlineCash,HiOutlineCalculator,HiOutlineChartSquareBar,HiChevronRight,HiOutlineArrowCircleRight,HiOutlineChartBar,HiOutlineArrowSmRight,HiOutlinePresentationChartLine,HiOutlineFolder,HiOutlineChartPie} from 'react-icons/hi';
import { Badge } from 'react-bootstrap'
import {Link} from "react-router-dom";
import imagen from "../assets/folder.png"

function Card4(props) {

return(
    
    <div style={{
        display:'flex',
        //minHeight:0,
       
        minWidth: '300px',
        width: 'calc(100% * (1/1) - 10px )',
       //width:"100%",
        backgroundColor: 'rgba(255,255,255,1)',
        borderRadius:'5px',
        padding:'10px',
        margin:'2px',
        // marginLeft:5,
        // marginRight:5,
        // marginTop:5,
        paddingTop:'5px',
        top:'1px',
        boxShadow: 'rgb(154, 152, 152) 0px 0px 1px',
        paddingRight:'15px',
        fontFamily:'CircularStd-Bold'
        
    }}
    
    
 
   >  
    <Link to={{pathname:"/subdocumentos",state:{id:props.accion,modoBusqueda:props.modo,mibusqueda:props.busqueda}}} style={{textDecoration: 'none'}}>
    <div style={{display:'flex',flexDirection:"column" }} >
   
    <div style={{display:'flex',flexDirection:'row',padding:'5px'}}>
    <div style={{width:'80px'}}>
                        <img src={imagen} style={{marginLeft:'10px',marginTop:'5px',position:'absolute',width:'45px'}}/>

                      
            <Badge pill bg='secondary'
            style={{height:'20px',margin:'0px', position:'absolute',marginTop:'32px',marginLeft:'35px' }}
            >{props.numdocs}</Badge>
        </div>
        <div style={{flexDirection:'row', flex:1,width:'100%',fontFamily:'CircularStd-Bold' }}>
    <div  style={{top:'4px', alignSelf: 'center'}}>
    <span style={{display:'flex', alignItems:'flex-start',color:'#000000',fontFamily: 'CircularStd-Bold',textAlign:'left',fontSize:'22px'}}>{props.cabecera} </span>
    <span style={{display:'flex', alignItems:'flex-start',color:'gray',fontFamily: 'CircularStd-Light',textAlign:'left',fontSize:'14px'}}>Tamaño: {props.spano2} </span> 
   
        </div>
       
    </div>
    </div> 
    
   
   
   
   </div> </Link> 
    </div>
   
);
}
export default Card4;