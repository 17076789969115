import React from 'react';
import './card.css'

function Card3(props) {

return(

  <tr>
    <td width='5%' align='center' style={{color:'#000000'}}>{props.posicion}</td>
    <td width='40%' align='left' style={{color:'#000000'}}>{props.titulo}</td>
    <td width='10%' align='right' style={{textTransform:'none'}}>{props.ventasa} €</td> 
    <td width='10%' align='right'>{props.comprasa} €</td>
    <td width='10%' align='right' style={{color:parseFloat(props.diferenciaa) >= 0 ? 'green' : 'red'}}>{props.diferenciaa} %</td>
    <td width='10%' align='right' style={{textTransform:'none'}}>{props.ventasm} €</td> 
    <td width='10%' align='right'>{props.comprasm} €</td>
    <td width='10%' align='right' style={{color:parseFloat(props.diferenciam) >= 0 ? 'green' : 'red'}}>{props.diferenciam} %</td>
  </tr>
              // <div className="card3">
              //     <div className="card-content">
              //     <div style={{display: 'flex',flexDirection: 'row'}}>

              //  <span style={{display:'flex',height:'25px',width:'25px', textAlign:'center',
              //   fontFamily:'CircularStd-Book', justifyContent:'center',alignItems:'center', color:'#ffffff',backgroundColor:'#00000090',borderRadius:'35px',fontSize:'18px'}}>{props.posicion}</span>  
              //  <span style={{display:'flex',height:'25px',justifyContent:'center',alignItems:'center', paddingLeft:'10px',fontSize:'22px',fontFamily:'CircularStd-Bold',color:'#000000'}}>{props.titulo}</span>  
              //  </div>
              //       <div style={{paddingLeft:'35px',fontSize:'20px',fontFamily:'CircularStd-Light',color:'black'}}>{props.valor} €</div>
              //       <div style={{paddingLeft:'35px',fontFamily:'CircularStd-Light',fontSize:'14px',fontWeight:'normal',color:'gray'}}> {props.mes}: {props.comentario } €</div>
              //     </div>
              // </div>
);
}
export default Card3;