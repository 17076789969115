import React from 'react';
import './card.css';
import {Link,useHistory} from "react-router-dom";
import  {HiChartBar,HiOutlineDotsVertical,HiOutlineDotsCircleHorizontal,HiOutlineCubeTransparent} from 'react-icons/hi';



function CardCli(props) {

 
return(
  
  <tr onClick={props.call } id={props.miid} title={props.minombre}>
   
    <td width='40%' align='left' style={{color:'black'}} >{props.nombre}</td>
    <td width='20%' align='left' style={{textTransform:'none'}}>{props.vencida} €</td>  
    <td width='20%' align='right'>{props.novencida} €</td>
    <td width='20%' align='right'>{props.total} €</td>
    <td width='20%' align='right'>{props.totalvencida} %</td>
  
  </tr>

               
             
             
          
);
}

export default CardCli;