import React, { useEffect, useState } from 'react';
import '../App.css';
import PropTypes from 'prop-types';
import ApiService from '../paginas/ApiService'
import {
  BrowserRouter as Router,
  Link,
  useLocation, useHistory, Redirect
} from "react-router-dom";
import Logo from '../assets/logo-mixed-light.svg'
import bg from '../assets/bg3.jpg'
import { HiOutlineExclamation } from 'react-icons/hi';
import { CgSpinner } from 'react-icons/cg';
import e from 'cors';
export default class Login extends React.Component {

  state = {
    boton:'Login',
    username: '',
    password: '',
    mensaje: '',
    empresas: [],
  }
  resetMessage() {
    this.timerId = setTimeout(() => {
      this.setState({
        mensaje: "",
        boton:'Login'
      });
      this.timerId = null;
    }, 2000);
  }

  misempresas = async (cliente) => {
    return ApiService.fetchEmpresasGrupo(cliente)

      .then(response => {

        this.setState({ empresas: response.data });

      })
      .catch(error => {


      });


  }


  Encriptar = async (cadena) => {

    await ApiService.fetchDatosEncriptar(cadena)

      .then(async res => {

        sessionStorage.setItem("usuario", res.data)


      })
  }
  loginUser = async (usuario, password) => {
    // this.Encriptar("manolo")
    await ApiService.login(usuario, password)



      .then(async res => {


        console.log(res.data)
        if (res.data.existe !== 0) {
          this.setState({ mensaje: "" })
          console.log(res.data[0].Id)

          // this.Encriptar(res.data[0].usuario)
          sessionStorage.setItem("admin", res.data[0].Id)
          sessionStorage.setItem("usuario", res.data[0].usuario)
          sessionStorage.setItem("password", res.data[0].password)
          sessionStorage.setItem("cliente", res.data[0].Id)
          sessionStorage.setItem("token", true)

          this.misempresas(res.data[0].Id);
          const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
          await sleep2(800)
          sessionStorage.setItem("empresas", JSON.stringify(this.state.empresas));
          //sessionStorage.setItem("empresas", this.state.empresas)
          if (window.location.pathname === '/login' || window.location.pathname === '/' || window.location.pathname === '')
            window.location = '/dashboard'
          else
            window.location = window.location.pathname
          //this.props.history.push('/dashboard');
          // window.location = '/dashboard'
        } else {

          this.setState({ mensaje: "Usuario o contraseña incorrecta" })
          this.resetMessage()

        }

      })
      .catch(err => {
        //this.setState({message:"Error conectando con el servidor."})


      })


  }
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  }
  handleSubmit = async (e) => {
this.setState({boton:<CgSpinner size={21} className='loaderIcon'/>});
    if (this.state.username === "" || this.state.password === "") {

      if (this.state.username === "" && this.state.password === "") {

        this.userinput.focus();
        this.setState({ mensaje: "Usuario y contraseña vacios." });
        this.resetMessage();
        return


      }
      if (this.state.username === "") {


        this.userinput.focus();
        this.setState({ mensaje: "Por favor, rellene el usuario." });
        this.resetMessage();
        return
      }

      if (this.state.password === "") {


        this.passwordinput.focus();
        this.setState({ mensaje: "Por favor, rellene la contraseña." });
        this.resetMessage();
        return
      }

    }
    else {

      await this.loginUser(
        this.state.username,
        this.state.password
      );
    }

  }



  render() {

    return (
      <div className="principal">
        <div className='background'></div>
        <div className="box-container">
          <div className="inner-container login-wrapper">
            <div className="header">
              <img src={Logo} style={{ width: '6em' }} />
            </div>

            <div className="box">

              <div className="input-group">
                {/* <label className="login-label" htmlFor="username">Usuario</label> */}
                <input
                  ref={inputEl => (this.userinput = inputEl)}
                  type="text"
                  name="username"
                  className="login-input"

                  placeholder="Usuario" onChange={e => this.setState({ username: e.target.value })} />
              </div>

              <div className="input-group">
                {/* <label className="login-label" htmlFor="password">Password</label> */}
                <input
                  ref={inputEl => (this.passwordinput = inputEl)}
                  type="password"
                  name="password"
                  className="login-input"
                  placeholder="Password"
                  onKeyPress={this.handleKeyPress}
                  onChange={e => this.setState({ password: e.target.value })} />
              </div>
              <div className="input-group">

                <button
                  type="submit"
                  className="login-btn"
                  onClick={this.handleSubmit}>{this.state.boton}</button>
              </div>
              {this.state.mensaje ?
                <div style={{ border: '2px solid white', backgroundColor: 'transparent', display: 'flex', width: '100%', height: '40px', padding: '4px', borderRadius: '0px', color: 'white', marginTop: '12px', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>

                  <div style={{ top: '50%', left: '50%', height: '100%', display: 'flex' }}>
                    <div style={{ backgroundColor: 'transparent', height: '100%', display: 'flex', padding: '5px', verticalAlign: 'bottom', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}><HiOutlineExclamation size={25} />
                    </div>
                    <div style={{ backgroundColor: 'transparent', height: '100%', display: 'flex', padding: '5px', fontSize: '13px', justifyContent: 'flex-start', alignSelf: 'flex-start', alignItems: 'flex-start', textAlign: 'left' }}>{this.state.mensaje}
                    </div>
                  </div>
                </div> : null
              }
            </div>


          </div>

        </div>
      </div>

    );


  }
}
