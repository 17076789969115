import axios from 'axios';

//CARTERA CLIENTES DEUDA
const USER_API_BASE_DEUDA = 'https://apicore.myleistung.com/deuda';
const USER_API_BASE_DEUDAC = 'https://apicore.myleistung.com/deudachart';
const USER_API_BASE_CLIENTES = 'https://apicore.myleistung.com/clientes';
//SALDOS POR FAMILIAS
const USER_API_BASE_FAMILIAS = 'https://apicore.myleistung.com/familias';
//LOGIN
const USER_API_BASE_URL = 'https://apicore.myleistung.com/api/login';

//DATOS CUENTAS CONTABILIDAD
const USER_API_BASE_MENSUAL = 'https://apicore.myleistung.com/mensual';
const USER_API_BASE_ANUAL = 'https://apicore.myleistung.com/anual';
const USER_API_BASE_EJERCICIOS = 'https://apicore.myleistung.com/ejercicios';
const USER_API_BASE_MESES = 'https://apicore.myleistung.com/meses';

const USER_API_BASE_CUENTAS = 'https://apicore.myleistung.com/saldo';
const USER_API_BASE_SUBCUENTAS = 'https://apicore.myleistung.com/saldosub';
const USER_API_BASE_SUBCUENTASB = 'https://apicore.myleistung.com/saldosubb';
const USER_API_BASE_CUENTASBAL = 'https://apicore.myleistung.com/saldob';
const USER_API_BASE_SUBCUENTASBAL = 'https://apicore.myleistung.com/saldosub';

const USER_API_BASE_CUENTASC = 'https://apicore.myleistung.com/saldoc';
const USER_API_BASE_SUBCUENTASC = 'https://apicore.myleistung.com/saldosub';

//DOCUMENTOS
const USER_API_BASE_DOCUMENTOS = 'https://apicore.myleistung.com/documentos/';
const USER_API_BASE_DOCUMENTOSD = 'https://apicore.myleistung.com/documento/';
const USER_API_BASE_DOCUMENTOSBUS = 'https://apicore.myleistung.com/busqueda/';
const USER_API_BASE_DOCUMENTOSDEL = 'https://apicore.myleistung.com/documento/eliminar/';

//ENCRIPTAR USUARIO Y CONTRASEÑA
const USER_API_BASE_ENCRIPTAR = 'https://apicore.myleistung.com/encriptar';
const USER_API_BASE_DESENCRIPTAR = 'https://apicore.myleistung.com/desencriptar';
//EMPRESAS GRUPO
const USER_API_BASE_GRUPO = 'https://apicore.myleistung.com/empresas';

class ApiService {

    fetchEmpresasGrupo(cliente) {

        return axios.get(USER_API_BASE_GRUPO + '/' + cliente);
    }

    fetchDatosEncriptar(cadena) {

        return axios.get(USER_API_BASE_ENCRIPTAR + '/' + cadena);
    }
    fetchDatosDesencriptar(cadena) {

        return axios.get(USER_API_BASE_DESENCRIPTAR + '/' + cadena);
    }


    fetchDatosSubCuentas(ejercicio, mes, cliente, epigrafe) {

        return axios.get(USER_API_BASE_CUENTAS + '/' + ejercicio + '/' + mes + '/' + cliente + '/' + epigrafe);
    }

    fetchDatosSubCuentas2(ejercicio, mes, cliente, epigrafe) {

        return axios.get(USER_API_BASE_SUBCUENTAS + '/' + ejercicio + '/' + mes + '/' + cliente + '/' + epigrafe);
    }

    fetchDatosSubCuentasbal(ejercicio, mes, cliente, epigrafe) {

        return axios.get(USER_API_BASE_CUENTASBAL + '/' + ejercicio + '/' + mes + '/' + cliente + '/' + epigrafe);
    }

    fetchDatosSubCuentasbal2(ejercicio, mes, cliente, epigrafe) {

        return axios.get(USER_API_BASE_SUBCUENTASB + '/' + ejercicio + '/' + mes + '/' + cliente + '/' + epigrafe);
    }

    fetchDatosSubCuentasc(ejercicio, mes, cliente, epigrafe) {

        return axios.get(USER_API_BASE_CUENTASC + '/' + ejercicio + '/' + mes + '/' + cliente + '/' + epigrafe);
    }

    fetchDatosSubCuentasc2(ejercicio, mes, cliente, epigrafe) {

        return axios.get(USER_API_BASE_SUBCUENTASBAL + '/' + ejercicio + '/' + mes + '/' + cliente + '/' + epigrafe);
    }


    fetchDocumentosTipo() {
        // axios.defaults.headers.common['token'] = token;



        return axios.get(USER_API_BASE_DOCUMENTOS)

    }
    fetchDocumentosBorrar(id) {
        // axios.defaults.headers.common['token'] = token;



        return axios.get(USER_API_BASE_DOCUMENTOSDEL + id)

    }
    fetchDocumentod(cliente, tipo) {
        // axios.defaults.headers.common['token'] = token;



        return axios.get(USER_API_BASE_DOCUMENTOSD + cliente + '/' + tipo)

    }

    fetchDocumentobus(cliente, busqueda) {
        // axios.defaults.headers.common['token'] = token;



        return axios.get(USER_API_BASE_DOCUMENTOSBUS + cliente + '/' + busqueda)

    }
    fetchDocumentobusd(cliente, busqueda, tipo) {
        // axios.defaults.headers.common['token'] = token;



        return axios.get(USER_API_BASE_DOCUMENTOSBUS + cliente + '/' + busqueda + '/' + tipo)

    }

    fetchDatosFamiliasMes() {

        return axios.get(USER_API_BASE_FAMILIAS);

    }
    login(usuario, password) {

        return axios.post(USER_API_BASE_URL, {

            'Usuario': usuario,
            'Password': password

        });
    }
    fetchDatosMeses(cliente, ejercicio) {

        return axios.get(USER_API_BASE_MESES + '/' + cliente + '/' + ejercicio);

    }

    fetchDatosEjercicios(cliente) {

        return axios.get(USER_API_BASE_EJERCICIOS + '/' + cliente);

    }

    fetchDatosDeuda(empresa) {

        return axios.get(USER_API_BASE_DEUDA + '/' + empresa);
    }
    fetchDatosDeudaIndividual(empresa,cliente) {

        return axios.get(USER_API_BASE_DEUDA + '/' + empresa+ '/' + cliente);
    }
    fetchDatosDeudaChart(empresa) {

        return axios.get(USER_API_BASE_DEUDAC + '/' + empresa);
    }

    fetchDatosDeudaClientes(empresa) {

        return axios.get(USER_API_BASE_CLIENTES + '/' + empresa);
    }

    fetchDatosAnual(cliente, ejercicio, mes) {

        return axios.get(USER_API_BASE_ANUAL + '/' + ejercicio + '/' + cliente + '/' + mes);

    }
}
export default new ApiService();