import React from 'react';
import './card.css'
import Folder from '../assets/folder.png';
import { HiOutlineTrash,HiOutlineDocumentText, HiOutlineTrendingUp, HiOutlineDocumentDuplicate, HiOutlineCurrencyEuro, HiOutlineCash, HiOutlineCalculator, HiOutlineChartSquareBar, HiChevronRight, HiOutlineArrowCircleRight, HiOutlineChartBar, HiOutlineArrowSmRight, HiOutlinePresentationChartLine, HiOutlineFolder, HiOutlineChartPie } from 'react-icons/hi';
import { Badge } from 'react-bootstrap'
import { Link } from "react-router-dom";
import imagen from "../assets/document-64.png"

function Card5(props) {

    return (

        <div style={{
            display: 'flex',
            //minHeight:0,
        
            minWidth: '300px',
            width: 'calc(100% * (1/1) - 10px )',
            //width:"100%",
            backgroundColor: 'rgba(255,255,255,1)',
            borderRadius: '5px',
            padding: '10px',
            margin: '2px',
            // marginLeft:5,
            // marginRight:5,
            // marginTop:5,
            paddingTop: '5px',
            top: '1px',
            boxShadow: 'rgb(154, 152, 152) 0px 0px 1px',
            paddingRight: '15px',
            fontFamily: 'CircularStd-Bold'

        }}



        >
            <div style={{ display: 'flex' }}  >
                <Link to={{ pathname: "/visordocumentos", state: { id: props.fichero, tipo: props.tipo } }} style={{ textDecoration: 'none' }}>
                    <div style={{ display: 'flex', flexDirection: 'row',padding:'5px' }}>

                     <div style={{width:'80px'}}>
                        <img src={imagen} style={{marginLeft:'15px',marginTop:'5px',position:'absolute',width:'50px'}}/>
 
                        </div>
                        
                        <div style={{ flex: 1, width: '100%', fontFamily: 'CircularStd-Bold' }}>
                            <div style={{ top: '4px', alignSelf: 'center' }}>
                                <span style={{ display: 'flex', alignItems: 'flex-start', color: '#000000', fontFamily: 'CircularStd-Bold', textAlign: 'left', fontSize: '22px', textTransform: 'none' }}>{props.cabecera.charAt(0).toUpperCase() + props.cabecera.slice(1)} </span>
                                {/* <span style={{ display: 'flex', alignItems: 'flex-start', color: 'gray', fontFamily: 'CircularStd-Light', textAlign: 'left', fontSize: '14px' }}>Directorio: {props.spano2} </span> */}
                                <span style={{display:'flex', alignItems:'flex-start',color:'gray',fontFamily: 'CircularStd-Light',textAlign:'left',fontSize:'14px'}}>Fecha: {props.fecha} </span>  
                                <span style={{ display: 'flex', alignItems: 'flex-start', color: 'gray', fontFamily: 'CircularStd-Light', textAlign: 'left', fontSize: '14px' }}>Tamaño: {props.tamano} Mb</span>

                            
                            </div>
                          
                   
                        </div>
                        
                    </div>




                </Link> 
                       
                 </div> 
                 <div style={{cursor:'pointer',borderRadius:'5px'
                 ,textAlign:'center',width:'100px',height:'33px',
                marginLeft:'auto',marginRight:'0',marginTop:'45px',padding:'6px',backgroundColor:'rgba(255,193,53,1)',color:'#000', fontFamily: 'CircularStd-Book',fontSize:'14px' }} id={props.Id} title={props.titulo} onClick={props.call}> Eliminar</div>      
                
        </div>

    );
}
export default Card5;