import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Caryt from '../components/card6'
import Caryt2 from '../components/cardsoc'
import axios from 'axios';
import Select from 'react-select'
import ApiService from "./ApiService";
import Spinner from "../assets/icons8-loading-infinity.gif"
import BarLoader from "react-spinners/BarLoader";
import styled from 'styled-components';
import { Bar, Line } from 'react-chartjs-2';
export default class Tesoreria extends React.Component {

  state = {
    datos: [],
    datosoc: [],
    cargando: true,
    ver: false,
    micliente: '',
    //ESTADOS DEL ÚLTIMO EJERCICIO Y MES DISPONIBLES EN LA BBDD

  }



  saldos = async () => {
    axios.get('https://apicore.myleistung.com/cuentas/' + sessionStorage.getItem("cliente"))
      .then(res => {

        this.setState({ datos: res.data })

      })
      .catch(function (error) {
        console.log(error);
      })





  };
  handleUpdate = async (e) => {
    // alert(e.target.getAttribute('data-handle'))
    alert(e.currentTarget.id + ' ' + e.currentTarget.title)

  }
  handleShow = async (e) => {
    // alert(e.target.getAttribute('data-handle'))
    if (this.state.ver == false) {
      this.setState({ ver: true });
      const ele = document.getElementsByClassName(e.currentTarget.title);
      ele[0].style.display = 'block';
    } else {
      this.setState({ ver: false });
      const ele = document.getElementsByClassName(e.currentTarget.title);
      ele[0].style.display = 'none';
    }
  }
  async componentDidMount() {



    const sleep2 = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep2(600)
    await this.saldos();


    this.setState({ cargando: false })

  }



  render() {

    const groupBy = (data, key) => {
      return data.reduce(function (acc, item) {
        (acc[item[key]] = acc[item[key]] || []).push(item);
        return acc;
      }, {});
    };

    const ejercicioa = groupBy(this.state.datos, 'ejercicio');

    return (
      <div style={{ padding: '24px', minWidth: 'calc(100vw- 265px)', width: 'calc(100vw  - 265px)', minHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}>




        {this.state.cargando ?

          <div style={{
            display: 'flex',

            height: '90vh',
            width: 'calc(100vw-250px)',
            justifyContent: 'center',
            alignItems: 'center',


          }}>
            <BarLoader color={'#000'} size={40} />
            {/* <img src={Spinner} style={{width:'50px'}}/> */}
            {/* <i style={{fontSize:25,color:'#4682b4'}} className="fas fa-circle-notch fa-spin" />  */}
          </div>
          :


          <div style={{ margin: '10px ', minWidth: 'calc(100vw- 265px)', verticalAlign: 'middle', maxWidth: '1500px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>

              <div style={{ display: 'flex', justifyContent: 'flex-start', width: '60%' }}>
                <div style={{ display: 'flex', fontSize: '32px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>Ejercicios</div>
                {/* <h3>Dashboard a {this.state.nombremes} de {this.state.ejercicio}</h3> */}
                {/* <div style={{display:'flex',marginLeft:'5px',display:'flex',backgroundColor:'transparent',borderRadius:'5px',textAlign:'center',fontFamily:'CircularStd-Book', fontSize:'32px'}}> {this.state.datos[0].saldototal} €</div> */}

              </div>

            </div>

            {/* <div style={{marginTop:'10px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gridTemplateColumns: 'repeat(1, 3fr)' }}>
            */}


            {this.state.datos.length > 0 && Object.keys(ejercicioa).sort().reverse().map(cat => (
              <div style={{ padding: '5px' }}>
                <div tabIndex={-1} style={{ borderRadius: '5px', paddingLeft: '15px', userSelect: 'none', cursor: 'pointer', fontSize: '25px', backgroundColor: 'rgba(254,207,125,1)', fontFamily: 'CircularStd-Bold' }} onClick={this.handleShow} title={cat}>{cat}</div>
                <div class={cat} style={{ display: 'none', marginLeft: '5px', fontSize: '15px', fontFamily: 'CircularStd-Light' }}>
                  {ejercicioa[cat].map(ord => (

                    <div style={{ userSelect: 'none', cursor: 'pointer', marginTop: '10px', marginLeft: '25px', fontSize: '15px', fontFamily: 'CircularStd-Light' }} onClick={this.handleUpdate} id={ord.mes} title={cat}>{ord.nombre}</div>


                  ))} </div>
              </div>
              //  <div> 
              //  <div>{ejercicioa}</div>
              //  </div>

            ))}
            {/* </div> */}




          </div>
        }
      </div>



    );


  }
}

const MiTabla = styled.table`
table {
  font-size:15px;
  background-color:white;
  table-layout: fixed;
  border-collapse:collapse;
  padding:5px;
  //box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px ;
  width: 100%;
}
th {
  font-size:15px;
  font-family:'CircularStd-Bold';
  color:#000000;
  border:1px solid lightgray;
   padding:5px;
   background-color:rgba(223,223,223,1);
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
td {
  font-family:'CircularStd-Light';
  color:'#000000';
  border:1px solid lightgray;
  padding:5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
table tr th:nth-child(1){
  width: 15%;
 
}
table tr th:nth-child(2){
  width: 45%;
}
table tr th:nth-child(3){
  width: 20%;
}
table tr th:nth-child(4){
  width: 20%;
}
tr:hover{
  background-color:#d1d1d140;
  cursor:'pointer';
}
// thead {position: -webkit-sticky; position: sticky; top: 50px; z-index: 100;}
`;






