import React from 'react';
import './card.css'
import {Link,useHistory} from "react-router-dom";

function CardFun(props) {

  let history= useHistory();

  
return(

  <tr>
    <td width='40%' align='left' style={{backgroundColor:props.estilo == "A" ? 'rgba(223,223,223,1)': props.estilo=="B"?'': '',fontFamily: props.estilo == "A"? 'CircularStd-Bold': props.estilo=="B"?'CircularStd-Bold': props.estilo=="C"?'': '',color:props.estilo == "A"? '#000000':'#000000',fontSize:props.estilo == "A"? '15px':props.estilo == "B"? '15px':'13px'}}>{props.titulo}</td>
    <td width='20%' align='right' style={{backgroundColor:props.estilo == "A" ? 'rgba(223,223,223,1)': props.estilo=="B"?'': '',fontFamily: props.estilo == "A"? 'CircularStd-Bold': props.estilo=="B"?'CircularStd-Bold': props.estilo=="C"?'': '',color:props.estilo == "A"? '#000000':'#000000',fontSize:props.estilo == "A"? '15px':props.estilo == "B"? '15px':'13px'}}>{props.saldon} </td>
    <td width='20%' align='right' style={{backgroundColor:props.estilo == "A" ? 'rgba(223,223,223,1)': props.estilo=="B"?'': '',fontFamily: props.estilo == "A"? 'CircularStd-Bold': props.estilo=="B"?'CircularStd-Bold': props.estilo=="C"?'': '',color:props.estilo == "A"? '#000000':'#000000',fontSize:props.estilo == "A"? '15px':props.estilo == "B"? '15px':'13px'}}>{props.vertica} </td>
    { props.saldon1 != null && 
    <td width='20%' align='right' style={{backgroundColor:props.estilo == "A" ? 'rgba(223,223,223,1)': props.estilo=="B"?'': '',fontFamily: props.estilo == "A"? 'CircularStd-Bold': props.estilo=="B"?'CircularStd-Bold': props.estilo=="C"?'': '',color:props.estilo == "A"? '#000000':'#000000',fontSize:props.estilo == "A"? '15px':props.estilo == "B"? '15px':'13px'}}>{props.saldon1}  </td> 
 } 
 { props.saldon1 != null && 
     <td width='20%' align='right' style={{backgroundColor:props.estilo == "A" ? 'rgba(223,223,223,1)': props.estilo=="B"?'': '',fontFamily: props.estilo == "A"? 'CircularStd-Bold': props.estilo=="B"?'CircularStd-Bold': props.estilo=="C"?'': '',color:props.estilo == "A"? '#000000':'#000000',fontSize:props.estilo == "A"? '15px':props.estilo == "B"? '15px':'13px'}}>{props.verticb} </td>
}
     { props.saldon1 != null && 
 
 <td width='10%' align='right' style={{backgroundColor:props.estilo == "A" ? 'rgba(223,223,223,1)': props.estilo=="B"?'': '',fontFamily: props.estilo == "A"? 'CircularStd-Bold': props.estilo=="B"?'CircularStd-Bold': props.estilo=="C"?'': '',color:props.estilo == "A"? '#000000':'#000000',fontSize:props.estilo == "A"? '15px':props.estilo == "B"? '15px':'13px'}}>{props.diferencia}</td> 
 } 
  </tr>
              // <div className="card3">
              //     <div className="card-content">
              //     <div style={{display: 'flex',flexDirection: 'row'}}>

              //  <span style={{display:'flex',height:'25px',width:'25px', textAlign:'center',
              //   fontFamily:'CircularStd-Book', justifyContent:'center',alignItems:'center', color:'#ffffff',backgroundColor:'#00000090',borderRadius:'35px',fontSize:'18px'}}>{props.posicion}</span>  
              //  <span style={{display:'flex',height:'25px',justifyContent:'center',alignItems:'center', paddingLeft:'10px',fontSize:'22px',fontFamily:'CircularStd-Bold',color:'#000000'}}>{props.titulo}</span>  
              //  </div>
              //       <div style={{paddingLeft:'35px',fontSize:'20px',fontFamily:'CircularStd-Light',color:'black'}}>{props.valor} €</div>
              //       <div style={{paddingLeft:'35px',fontFamily:'CircularStd-Light',fontSize:'14px',fontWeight:'normal',color:'gray'}}> {props.mes}: {props.comentario } €</div>
              //     </div>
              // </div>
);
}
export default CardFun;