import React, { useEffect, useRef, useState } from 'react';
import {
  BrowserRouter as Router,
  Link, withRouter,
  useLocation, Redirect, useHistory
} from "react-router-dom";
import { HiOutlineLogout, HiOutlineOfficeBuilding, HiOutlineShare, HiOutlineRefresh, HiOutlineViewGrid, HiOutlineBell, HiOutlineUserCircle, HiUserCircle, HiOutlineTrendingUp, HiOutlineDocumentDuplicate, HiOutlineCurrencyEuro, HiOutlineCash, HiOutlineCalculator, HiOutlineChartSquareBar, HiChevronRight, HiOutlineArrowCircleRight, HiOutlineChartBar, HiOutlineArrowSmRight, HiOutlinePresentationChartLine, HiOutlineFolder, HiOutlineChartPie } from 'react-icons/hi';
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import App from '../App';
import ApiService from "../paginas/ApiService";


function Nav(props) {


  let history = useHistory();
  const [usuario, setUsuario] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const [selectedOption, setSelectedOption] = useState(null);
  // const [empresa, setEmpresas]=useState([JSON.parse(sessionStorage.getItem("empresas"))]);
  const [empresa, setEmpresas] = useState([], () => {
    const localData = sessionStorage.getItem('empresas');
    return localData ? JSON.parse(localData) : [];
  });
  const onOptionClicked = value => () => {
    //alert(value.value);
    changeuser(value.id, value.usuario);
    setSelectedOption(value);
    setIsOpen(false);

  };
  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();
  const handleClickOutside = e => {


    if (!myRef.current.contains(e.target)) {

      setClickedOutside(true);
      setIsOpen(false);
    }

  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    ReactTooltip.rebuild();

    //  Desencriptar();


    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);

  });
  const result = JSON.parse(sessionStorage.getItem('empresas'));
  const options = [
    { value: '31', label: 'Ricopia Technologies, S.A', usuario: 'Ricopia' },
    { value: '38', label: 'GMA Office, S.L', usuario: 'GMA' },
    { value: '56', label: 'Comercial Diaz S.A', usuario: 'CDIAZ' },
    { value: '36', label: 'Kloudsherpa, S.L', usuario: 'Kloudsherpa' }
  ]

  const Main = styled("div")`
  font-family: sans-serif;
  background: #f0f0f0;
  height: 100vh;
`;

  const DropDownContainer = styled("div")`
  width: 15.5em;
  margin: 0 auto;
  position:absolute;
  right:0;

  
`;

  const DropDownHeader = styled("div")`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 15px;
  color: #3faffa;
  background: #ffffff;

`;

  const DropDownListContainer = styled("div")`

    

`;

  const DropDownList = styled("ul")`
  font-family: CircularStd-Light;

  margin-top: 13px;
  text-indent: 8px;
  padding-left: 0px;
  background: #ffffff;
  border: 1px solid #00000030;
  box-sizing: border-box;
  color: #000000;
  font-size: 14px;
  border-radius:0px;

  &:first-child {
    padding-top: 0em;

  }
  
`;

  const ListItem = styled("li")`
  list-style: none;
  padding:5px;
  
  width:100%;
  //background-color:red;
  
  :hover{
    background-color:#d1d1d180;
  
  }
`;
  const logout = () => {

    sessionStorage.removeItem('admin')
    sessionStorage.removeItem('empresas')
    sessionStorage.removeItem('usuario')
    sessionStorage.removeItem('password')
    sessionStorage.removeItem('cliente')
    sessionStorage.removeItem('token')
    sessionStorage.clear()
    window.location = "/login";
    //return  <Redirect to='/login'/>
  }
  const changeuser = (value, label) => {

    sessionStorage.removeItem('cliente')
    sessionStorage.removeItem('usuario')
    sessionStorage.setItem("usuario", label)
    sessionStorage.setItem("cliente", value)
    window.location.reload()
    //window.location = "/login";
    //return  <Redirect to='/login'/>
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const Desencriptar = () => {

    ApiService.fetchDatosDesencriptar(sessionStorage.getItem("usuario"))

      .then((response => {
        setUsuario(response.data)


      })
      )

  }


  const eltoken = sessionStorage.getItem("token");

  let query = useQuery();

  return (
    <usartoken>
      <div style={{
        zIndex: 2, position: 'fixed',
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', padding: '10px 15px 15px 15px', fontFamily: 'CircularStd-Book', fontSize: '18px', backgroundColor: "rgba(254,207,125,1)", width: 'calc(100vw  - 240px)', height: '50px', color: 'black'
      }}>   <ReactTooltip id="toolTip1" />
        <ReactTooltip id="toolTip2" />
        <ReactTooltip id="toolTip3" />
        <div style={{ width: '50%', marginTop: 3 }}>Hola, {sessionStorage.getItem("usuario").toUpperCase()}!</div>
        <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-end' }}>
          <div ><HiOutlineBell style={{ color: 'black', marginRight: '15px' }} /></div>



          <div style={{ cursor: 'pointer' }} data-tip="Salir" data-for="toolTip1" onClick={logout}> <HiOutlineLogout style={{ color: 'black', marginRight: '15px' }} /></div>


          {/* {
 sessionStorage.getItem("cliente")!=='36' && */}



          <div style={{ cursor: 'pointer' }} data-tip="Empresas" data-for="toolTip2" ref={myRef} onClick={toggling}><HiOutlineOfficeBuilding style={{ color: 'black', marginRight: '15px' }} />


            {isOpen && (

              <DropDownContainer >

                <DropDownListContainer>
                  <DropDownList>
                    <div data-tip='' data-for="toolTip2" style={{ zIndex: 5, padding: '5px', fontSize: '16px', fontFamily: 'CircularStd-Bold', color: '#fff', backgroundColor: 'rgb(0,0,0)', display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}><div><HiOutlineOfficeBuilding style={{ verticalAlign: 'text-bottom' }} size={18} /></div> <div>Empresas</div></div>

                    {result.map((option,index)=>(
                      <ListItem data-tip='' data-for="toolTip2" onClick={onOptionClicked(option)} key={Math.random()} style={{fontSize: '14px', fontFamily: 'CircularStd-Light'}}>
                       {index+1}. {option.nombre}
                      </ListItem>
                    ))}
                  </DropDownList>
                </DropDownListContainer>
              </DropDownContainer>



            )}

          </div>
          {/* } */}
        </div>

      </div>
    </usartoken>
  );
}

export default Nav;
