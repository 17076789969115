import React, {useEffect, useState} from "react";
import styled from 'styled-components'
import SidebarItems from "./SidebarItems";
import Estados from "./estados";
import {Link,useParams} from "react-router-dom";
import  {IoAnalyticsOutline,IoPulseOutline,IoStatsChartOutline} from 'react-icons/io5';
import  {HiOutlineLibrary,HiOutlineBriefcase,HiOutlineDatabase,HiOutlineCog,HiOutlineDocumentText,HiOutlineTrendingUp,HiOutlineDocumentDuplicate,HiOutlineCurrencyEuro,HiOutlineCash,HiOutlineCalculator,HiOutlineChartSquareBar,HiChevronRight,HiOutlineArrowCircleRight,HiOutlineChartBar,HiOutlineArrowSmRight,HiOutlinePresentationChartLine,HiOutlineFolder,HiOutlineChartPie} from 'react-icons/hi';
import Cifras from '../assets/cifras2.png'
import Ratios from '../assets/ratios2.png'
import Nof from '../assets/nof2.png'
import Logo from '../assets/logo-mixed-light.svg'
//import Logo from '../assets/logo_bloom.png'
const tipo=[
  HiOutlinePresentationChartLine,
  HiOutlineLibrary,
  HiOutlineTrendingUp,
  HiOutlineBriefcase,
  HiOutlineChartSquareBar,
  HiOutlineChartBar,
   
  
  
   
   
  // HiOutlineCalculator,
  HiOutlineDocumentText,
  HiOutlineDocumentText,
  HiOutlineDocumentText,
  HiOutlineDocumentText,
  
 
  HiOutlineCash,
  HiOutlineFolder,
  HiOutlineDocumentText,
   
  HiOutlineDocumentText,
  HiOutlineDocumentText,
  
];

const mitipo=tipo[0];
function Sidebar(props , {defaultActive}) {
  const location = props.history.location;
    const lastActiveIndexString = sessionStorage.getItem("lastActiveIndex");
    const lastActiveIndex = Number(lastActiveIndexString);
    const [activeIndex, setActiveIndex] = useState(lastActiveIndex || defaultActive);

    function changeActiveIndex(newIndex) {
        sessionStorage.setItem("lastActiveIndex", newIndex)
        setActiveIndex(newIndex)
    }
    
   
    function getPath(path) {
        if (path.charAt(0) !== "/") { 
            return  "/" + path;
        }
        return path;
    }
    const { id } = useParams();
    useEffect(()=> {
    
     // setActiveIndex(props.default)
        const activeItem = SidebarItems.findIndex(item=> getPath(item.route) === getPath(location.pathname))
        changeActiveIndex(activeItem);


    }, [location])

    return (
        <>
    
            <SidebarParent> 
            
                <div style={{position: 'fixed'}}>
               
              <div style={{marginBottom:'10px', lineHeight:'50px',backgroundColor:'rgba(152,164,185,0)',width:'240px',padding:'0px', height:'50px',textAlign:'center'}}> <img src={Logo} style={{width:'65%',verticalAlign:'middle'}}/></div>
             
               {
                 Estados.map((estado) => (
              <div><div style={{backgroundColor:'transparent',display:'flex',fontFamily:'CircularStd-Book',fontSize:'13px',color:'rgb(133,133,133)',width:'240px',verticalAlign:'left',paddingLeft:'10px', height:'20px',marginBottom:'3px',textAlign:'left'}}>{estado.name} </div>
              
                
              {
                SidebarItems.filter(b=>b.icon===estado.name).map((item,index) =>
               
                {
                  const Micono=tipo[item.indice]; 
                 if(sessionStorage.getItem("admin")==='60'|| sessionStorage.getItem("admin")==='57'){
              return  <Link to={item.route} onClick={item.indice===2||item.indice===2||item.indice===10?e => e.preventDefault():''}>
                               
                        <SidebarItem style={{ cursor:item.indice===2||item.indice===2||item.indice===10?'not-allowed':'',opacity:item.indice===2||item.indice===2||item.indice===10?'0.3':'',pointerEvents:item.indice===2||item.indice===2||item.indice===10?'none':''}} key={item.indice}
                                active={
                                  //  activeIndex===-1 ?index === 0:index===activeIndex 
                                  // ||
                                  getPath(location.pathname)==="/upload" ?item.indice === 11 : getPath(location.pathname) ==="/subdocumentos" ? item.indice === 11 : getPath(location.pathname)==="/visordocumentos" ?item.indice === 11:getPath(location.pathname)==="/cuentas" || getPath(location.pathname)==="/subcuentas" ?item.indice === 0:item.indice===activeIndex
                                }
                    >
                 <p><Micono style={{fontSize:'19px',verticalAlign:'text-bottom',color:props => props.active ? "#000000" : "#ffffff",marginRight:'5px'}}/>{item.name}</p>
                  </SidebarItem>
                  
              </Link>       
              
                    } else{
                      return  <Link to={item.route} >
                               
                      <SidebarItem key={item.indice}
                              active={
                                //  activeIndex===-1 ?index === 0:index===activeIndex 
                                // ||
                                getPath(location.pathname)==="/upload"?item.indice === 11 :getPath(location.pathname)==="/subdocumentos"?item.indice === 11 : getPath(location.pathname)==="/visordocumentos" ?item.indice === 11:getPath(location.pathname)==="/cuentas" || getPath(location.pathname)==="/subcuentas" ?item.indice === 0:item.indice===activeIndex
                              }
                  >
               <p><Micono style={{fontSize:'19px',verticalAlign:'text-bottom',color:props => props.active ? "#000000" : "#ffffff",marginRight:'5px'}}/>{item.name}</p>
                </SidebarItem>
                
            </Link>       

                    }       })
              }
              
              
              </div>
                
                              
                 
              ))
                 
                 }
                 
                  {/* {
                       SidebarItems.map((item, index)=> {
                        const Micono=tipo[index]; 
                       
                        if(sessionStorage.getItem("cliente")==='36'  && index !==4 && index !==5 && index !==7 && index !==6)
                        
                           return <Link to={item.route}>
                                
                           <SidebarItem key={index}
                                        active={
                                         //  activeIndex===-1 ?index === 0:index===activeIndex 
                                         // ||
                                         getPath(location.pathname)==="/subdocumentos/"+location.pathname.charAt(location.pathname.length-1) || getPath(location.pathname)==="/visordocumentos" ?index === 10:getPath(location.pathname)==="/cuentas" || getPath(location.pathname)==="/subcuentas" ?index === 0:index===activeIndex
                                       }
                           >
                          <p> <Micono style={{fontSize:'19px',verticalAlign:'text-bottom',color:'white',marginRight:'5px'}}/> {item.name}</p>
                           </SidebarItem>
                           
                       </Link>               
                             // { index=== 9 && item.name==='RICOPIA' ?

                             if(sessionStorage.getItem("cliente")!=='36')
                             return <Link to={item.route}>
                                
                             <SidebarItem key={index}
                                          active={
                                           //  activeIndex===-1 ?index === 0:index===activeIndex 
                                           // ||
                                           getPath(location.pathname)==="/upload" || getPath(location.pathname)==="/subdocumentos/" || getPath(location.pathname)==="/visordocumentos" ?index === 11:getPath(location.pathname)==="/cuentas" || getPath(location.pathname)==="/subcuentas" ?index === 0:index===activeIndex
                                         }
                             >
                            <p> <Micono style={{fontSize:'19px',verticalAlign:'text-bottom',color:'white',marginRight:'5px'}}/> {item.name} </p>
                             </SidebarItem>
                             
                         </Link>      
                         
                         })
                  }
                    */
                    }
                     </div>
                
 <div className="behind-the-scenes"/>
                
            </SidebarParent>
            
        </>
    );
}

export default Sidebar;

const SidebarParent = styled.div`
  background: rgb(37,37,37);
 
  a {
    text-decoration: none;
  }
  
  & > div {
    width: 245px;
    height: 100vh;
   overflow-y:scroll;
  }
  
  .behind-the-scenes {
    width: 245px;
    
    
  }
`;

const SidebarItem = styled.div`
 
  padding: 8px 5px 5px 11px;
  height:35px;
  transition: all 0.25s ease-in-out;
  background: ${props => props.active ? "rgba(255,193,53,0.91)" : ""};
  margin:0px 10px 3px 15px ;
  border-radius: 4px;

  font-size:14px;
  font-family:'CircularStd-Book';
  color: ${props => props.active ? "#000000" : "#ffffff"};
  
  p {
   
    //font-weight: ${props => props.active ? "bold" : "normal"};
    text-decoration: none;
    font-family:${props => props.active ? "'CircularStd-Bold'" : "'CircularStd-Book'"};
    vertical-align:'middle';
  }
   
  &:hover {
    cursor:pointer;
    background: rgb(255,193,53);
    color:#000;
  }
  
  &:hover:not(:first-child) {
    background: #c34a36;
  }
`;
