import React from 'react';
import './card.css';
import { Link } from "react-router-dom";
import { HiChartBar, HiOutlineDotsVertical, HiOutlineDotsCircleHorizontal, HiOutlineCubeTransparent } from 'react-icons/hi';



function Card(props) {

  return (

    <div className="card1">
      <div className="card-content">
        <div style={{ fontSize: '22px', fontFamily: 'CircularStd-Bold', color: 'rgba(0,0,0,0.71)' }}>{props.titulo}
                { props.titulo !== 'Beneficio' &&
                <Link to={{ pathname: "/cuentas", state: { ejercicio: props.ejercicio, mes: props.mes,mimes: props.mimes, cliente: props.cliente, epigrafe: props.epigrafe, concepto: props.concepto ,nombremes:props.nombremes} }} style={{ textDecoration: 'none' }}>
                  <HiOutlineDotsVertical style={{
                    fontSize: '22px', float: 'right', marginTop: '5px',
                    color: '#000000'
                  }} /> </Link>
                }
          {/* <HiChartBar style={{
                            fontSize:'22px',float:'right',marginTop:'5px',
                            color: parseInt(props.porcentaje) < 0 && props.titulo === 'Ventas' || parseInt(props.porcentaje) <0 && props.titulo === 'Beneficio' || parseInt(props.porcentaje) < 0 && props.titulo === 'Otros Ingresos' || parseInt(props.porcentaje) < 0 && props.titulo === 'Ingresos Financieros' || parseInt(props.porcentaje) > 0 && props.titulo === 'Compras' || parseInt(props.porcentaje) > 0 && props.titulo === 'Gastos Personal' || parseInt(props.porcentaje) > 0 && props.titulo === 'Gastos Explotación' || parseInt(props.porcentaje) > 0 && props.titulo === 'Amortizaciones' || parseInt(props.porcentaje) > 0 && props.titulo === 'Gastos Financieros' ? 'red' : 'green',}}/> */}
        </div>

        <div style={{ fontSize: '23px', fontFamily: 'CircularStd-Book', color: 'black' }}>{props.acumulado} €</div>
        <div style={{ fontSize: '14px', fontFamily: 'CircularStd-Light', color: 'rgba(0,0,0,0.51)' }}>{props.mes}: {props.actual} €</div>

        <div style={{  display: 'row' }}>
          <div style={{  fontSize: '14px', fontFamily: 'CircularStd-Light', color: 'rgba(0,0,0,0.51)' }}>{props.anterior}</div>

          <div style={{ backgroundColor:'transparent',textAlign: 'right', marginRight:0, marginTop: -27, alignContent: 'flex-end', fontSize: '20px', fontFamily: 'CircularStd-Light', color: parseInt(props.porcentaje) < 0 && props.titulo === 'Ventas' || parseInt(props.porcentaje) < 0 && props.titulo === 'Beneficio' || parseInt(props.porcentaje) < 0 && props.titulo === 'Otros Ingresos' || parseInt(props.porcentaje) < 0 && props.titulo === 'Ingresos Financieros' || parseInt(props.porcentaje) > 0 && props.titulo === 'Compras' || parseInt(props.porcentaje) > 0 && props.titulo === 'Gastos Personal' || parseInt(props.porcentaje) > 0 && props.titulo === 'Gastos Explotación' || parseInt(props.porcentaje) > 0 && props.titulo === 'Amortizaciones' || parseInt(props.porcentaje) > 0 && props.titulo === 'Gastos Financieros' ? 'red' : 'green' }} >{props.porcentaje} %

          </div>

        </div>


      </div>
    </div>
  );
}
export default Card;