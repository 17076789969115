import React, { useState, useEffect } from 'react';
import { Link, useParams,useHistory } from 'react-router-dom';
import axios from "axios";
import Select from 'react-select'
import ApiService from "./ApiService";
import imagen from '../assets/pdf.png'
import { HiOutlineChevronLeft,HiOutlineArrowCircleLeft, HiArrowSmLeft, HiOutlineDocumentText, HiOutlineTrendingUp, HiOutlineDocumentDuplicate, HiOutlineCurrencyEuro, HiOutlineCash, HiOutlineCalculator, HiOutlineChartSquareBar, HiChevronRight, HiOutlineArrowCircleRight, HiOutlineChartBar, HiOutlineArrowSmRight, HiOutlinePresentationChartLine, HiOutlineFolder, HiOutlineChartPie } from 'react-icons/hi';
import { Document, Page, pdfjs } from 'react-pdf';
/**
 * Component to handle file upload. Works for image
 * uploads, but can be edited to work for any file.
 */

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function FileUpload() {
  // State to store uploaded file
  const [file, setFile] = React.useState("");
  const [texto, setTexto] = React.useState("");
  const [tipo, setTipo] = React.useState("");
  const [tipos, setTipos] = React.useState([]);
  // Handles file upload event and updates state

  function handleUpload(event) {

    setFile(event.target.files[0]);

    // Add code here to upload file to server
    // ...
    var pdffile = document.getElementById("upload").files[0];
    var url = URL.createObjectURL(pdffile);
    var loadingTask = pdfjs.getDocument(url);

    loadingTask.promise.then(function (pdf) {


      var data = [];


      pdf.getPage(1).then(function (page) {

        var scale = 1;
        var viewport = page.getViewport({ scale: scale });

        var canvas = document.getElementById('micanvas');


        // Prepare canvas using PDF page dimensions
        var context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        // var image = new Image();
        // image.src =
        //   imagen;
        // image.onload = () => {
        //   context.drawImage(image, 0, 0, viewport.width, viewport.height);
        // Render PDF page into canvas context
         var renderContext = { canvasContext: context, viewport: viewport };

        var renderTask = page.render(renderContext);
        renderTask.promise.then(function () {
           data.push(canvas.toDataURL('image/png'))
         
 });
         // };
       
      });


    }, function (reason) {
      // PDF loading error
      console.error(reason);
    });
  }
  const documentoTipos = async (e) => {
    
    return ApiService.fetchDocumentosTipo()
    .then(response => {
      // console.log(this.state.ejercicio)
      setTipos(response.data);
      



    })
    .catch(error => {


    });

  };
  const importFile = async (e) => {
    if(tipo==="" || texto===""){
      alert('Debes rellenar todos los datos.')
      return
      }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("Cliente", sessionStorage.getItem("cliente"));
    formData.append("Descripcion", texto);
    formData.append("Tipo", tipo);
    try {
      const res = await axios.post("https://apicore.myleistung.com/api/documento", formData);
    } catch (ex) {
      console.log(ex);
    }

    history.push('/subdocumentos/',{id:tipo})

  };
  const borrarFile = async (e) => {

    var canvas = document.getElementById('micanvas'),
    ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setFile('');
    document.getElementById("upload").value = '';
  };
  const history=useHistory();
  const handleChange = async (e) => {
    setTipo(e.value)
  };

  useEffect(() => {
    // declare the data fetching function
   documentoTipos()
   
      // make sure to catch any error
      
  }, [])
  const options =
  tipos.map((dato,i) => {
        return {
          label: dato.Documento,
          value: dato.Id,
          nombre: dato.Documento


        }
      }
      );
  const dot = (color = "#395482") => ({
    alignItems: "center",
    display: "flex",
    color: 'gray',
    borderColor: "black",

    ":before": {
      backgroundColor: "#fff",
      // borderRadius: 1,
      // content: '" "',
      // display: "block",
      // marginRight: 8,
      // height: 10,
      // width: 10

    }
  });
  const styles = {

    control: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontFamily: 'CircularStd-Light',
        borderColor: "gray",
        boxShadow: 'none',
        border: '1px solid lightgray',
        borderWidth: "1px",
        borderRadius: "0px",
        backgroundColor: isFocused ? "#ffffff90" : "#ffffff",
        fontSize: '15px', fontColor: 'white', minWidth: '285px', width: '285px', height: '35px',
        ":hover": {
          ...styles[":hover"],
          backgroundColor: "#ffffff40",
          color: "gray",
          borderColor: "gray"
        },

      };
    },
    indicatorSeparator: style => ({
      ...style,
      display: 'none',
      color: '#395482'
    }),
    dropdownIndicator: style => ({
      ...style,

      color: 'lightgray'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        minWidth: '280px',
        height: '35px',
        borderRadius: '0px',
        backgroundColor: isSelected ? "#fff" : "#fff",
        // width:'150px',
        color: "#000",
        fontFamily: 'CircularStd-Book',
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles,
          backgroundColor: "#000000",
          color: 'black',
          borderColor: "black"
        },
        ":hover": {
          ...styles[":hover"],
          backgroundColor: "#00000080",
          color: "white",
          borderColor: "black"
        },

      };
    },

    menu: styles => ({ ...styles, marginTop: '0px', borderRadius: '0px', borderColor: "black", backgroundColor: "white", fontSize: '13px', minWidth: '285px', width: '285px' }),
    input: styles => ({ ...styles, borderColor: "black", borderWidth: "0px" }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
  };

  return (
    <div style={{ fontFamily: 'CircularStd-Book', fontSize: '15px', padding: '24px', minWidth: 'calc(100vw- 265px)', width: 'calc(100vw  - 265px)', minHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}>

      <div style={{ marginTop: '10px ', minWidth: 'calc(100vw- 265px)', verticalAlign: 'middle', maxWidth: '1500px' }}>
        
        
      <div style={{display:'flex',flexWrap:'wrap',flexDirection:'row'}}>
{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'flex-end', width: '40%' }}>

<button style={{ display: 'inline-block', textAlign: 'center', minWidth: '150px', height: '35px', border: '0px solid #39548240', backgroundColor: '#39548240', padding: '0px', fontSize: '16px', fontFamily: 'CircularStd-Book', color: '#000000' }} onClick={() => history.push('/documentos')}><HiArrowSmLeft size={20} />regresar</button>

</div> */}
  <Link to={{ pathname: "/documentos" }} style={{ textDecoration: 'none' }}> <HiOutlineChevronLeft style={{ marginTop: '10px', color: '#000000' }} size={30} /> </Link> &nbsp; &nbsp;
             
<div style={{fontSize:'32px',fontFamily:'CircularStd-Bold', color:'#000000'}}>Subir Documentos</div>

</div>

        <div style={{marginTop:'20px',padding:'',display: 'table'}}>
         
          <div style={{display: 'table-row'}}>
              <div style={{display: 'table-cell',width:'150px'}}>Descripción:</div>
              <div style={{display: 'table-cell', padding:'3px'}}><input type="text" className="documentosinput2" placeholder="Descripción" onChange={(e) => setTexto(e.target.value)} /></div>
	      	</div>
            <div style={{display: 'table-row'}}>
                <div style={{display: 'table-cell',width:'150px'}}>Tipo Documento:</div>
                 <div style={{display: 'table-cell', padding:'3px'}}>
                <Select
                  isSearchable={false}
                  // value={selectedOption}
                  //value={{ value: options.value, label: options.nombre}}
                  onChange={handleChange}
                  
                  options={options} styles={styles} placeholder="Documentos" /> &nbsp;
             
            </div>
            </div>
        </div>
        
      
        <br />
        <div id="upload-box">
          <label for="upload" style={{
            padding: "5px 10px",
            cursor: 'pointer',
            border: '0px',
            borderRadius: '5px',
            color: "#000",
            backgroundColor: "rgba(255,193,53,1)",
            fontFamily: 'CircularStd-Bold', fontSize: '15px'
          }}>
            <input type="file" id="upload" style={{ display: 'none' }} accept=".pdf" onChange={handleUpload} />

            <span>Selecciona documento</span>


          </label><br /><br />
        </div>


        {file && file.type === 'application/pdf' && 
          <div style={{ width: '550px', backgroundColor: '#ffffff50', border: 'solid 1px', padding: '15px', display: 'inline-flex', flexDirection: 'row' }}>
            <div id="canvas" style={{ width:'30%', alignItems: 'flex-start', textAlign: 'center', marginTop: 0 }}><canvas id="micanvas" style={{ position: 'relative',width:'80%',border:'solid 1px',borderColor:'gray'}}></canvas></div>
           

            <div style={{width:'70%',marginLeft:'3px',alignItems: 'flex-start', textAlign: 'left'}}>
              {/* <p>Fichero: {file.name}</p> */}
              <p>Tipo: {file.type}</p>
              <p>Tamaño: {file.size} bytes</p>
              <p>Descripción: {texto}</p>
              <p>Cliente: {sessionStorage.getItem("cliente")}</p>
              <p>Tipo Fichero: {tipo}</p>
           
            </div>
          </div>
      }

        {file && file.type !== 'application/pdf' &&
          <div>
            <p>Sólo se pueden adjuntar ficheros PDF.</p>
          </div>
        }

      </div> <br />
      {file &&
        <div>

          <input type="button" value="Enviar" onClick={importFile}

            style={{
              padding: "5px 10px",
              cursor: 'pointer',
              border: '0px',
              borderRadius: '5px',
              color: "#000",
              backgroundColor: "rgba(255,193,53,1)",
              fontFamily: 'CircularStd-Bold', fontSize: '15px'
            }}
          />&nbsp;&nbsp;
          <input type="button" value="Borrar" onClick={borrarFile}

            style={{
              padding: "5px 10px",
              cursor: 'pointer',
              border: '0px',
              borderRadius: '5px',
              color: "#000",
              backgroundColor: "rgba(255,193,53,1)",
              fontFamily: 'CircularStd-Bold', fontSize: '15px'
            }}
          />
        </div>
      }
    </div>
  );
}




export default function App() {
  return <FileUpload />;
}