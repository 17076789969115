import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import Nof from "./paginas/Nof";
import Ratios from "./paginas/Ratios";
import dashboard from "./paginas/Dashboard";
import Login from "./paginas/login";
import Excel from "./paginas/Fichero";
import VentasFamilias from './paginas/VentasFamilias';
import Efe from './paginas/efe';
import VentasGlobales from './paginas/VentasGlobal';
import BS from './paginas/BalanceSituacion';
import CR from './paginas/CuentaResultados';
import CF from './paginas/funcional';
import Documentos from './paginas/Documentos';
import Tesoreria from './paginas/tesoreria';
import SubDocumentos from './paginas/Documentosub';
import Visor from './paginas/VisorDocs';
import Cuentas from './paginas/cuentas';
import Cuentasc from './paginas/cuentasc';
import SubCuentas from './paginas/subcuentas';
import SubCuentasc from './paginas/subcuentasc';
import Cartera from './paginas/Cartera';
import Update from './paginas/update';
import Layout from "./components/Layout";
import NotFound from "./paginas/Error";
import Upload from "./paginas/upload";
import Balance from './paginas/Balance';
import resultados from './paginas/resultados';
import miform from './paginas/formulario';
import ScrollToTop from "./paginas/scrolltotop";

export default class App extends React.Component {

  state = {

    dentro: false,

  }


  getToken() {

    this.setState({ dentro: sessionStorage.getItem('token') });
  }


  async componentDidMount() {

    this.getToken();

  }
  componentDidUpdate() {

    window.onpopstate = e => {

      e.preventDefault();
      window.history.go(0)

    }
  }
  // async componentDidUpdate() {
  //  // this.getToken();
  // if( sessionStorage.getItem('token')===null) window.location.reload(false)
  // }

  render() {

    // if(window.location.pathname==='/login' || window.location.pathname==='/' || window.location.pathname==='')
    // window.location='/dashboard'
    if (!this.state.dentro && window.location.pathname === '/' || window.location.pathname === '')
      window.location = '/login'
    if (this.state.dentro && window.location.pathname === '/login')
      window.location = '/dashboard'
    return (
      <div>
        {!this.state.dentro ?
          <Login />

          :


          <Route render={(props) => (


            <ScrollToTop>
              <Layout {...props}>

                <Switch>

                  <Route exact path="/" component={dashboard} name="Inicio" />
                  {/* <Route exact path="/login" component={Login} name="Login"/>  */}
                  <Route exact path="/familias" component={VentasFamilias} name="Ventas Familias" />
                  <Route exact path="/ventasglobal" component={VentasGlobales} name="Ventas Grupo" />
                  <Route exact path="/efe" component={Efe} name="Cash Flow" />
                  <Route exact path="/fm-nof" component={Nof} name="FM-NOF" />
                  <Route exact path="/ratios" component={Ratios} name="Ratios" />
                  <Route exact path="/fichero" component={Excel} name="Fichero" />
                  <Route exact path="/balance" component={Balance} name="Balance Situación" />
                  <Route exact path="/tesoreria" component={Tesoreria} name="Tesorería" />
                  <Route exact path="/cuentaresultados" component={resultados} name="Cuenta de Resultados" />
                  <Route exact path="/funcionales" component={CF} name="Cuentas Funcionales" />
                  <Route exact path="/documentos" component={Documentos} name="Documentos" />
                  <Route exact path="/subdocumentos" component={SubDocumentos} name="Documentos" />
                  <Route exact path="/visordocumentos" component={Visor} name="Documentos" />
                  <Route exact path="/cuentas" component={Cuentas} name="Cuentas" />
                  <Route exact path="/cuentas_cr" component={Cuentasc} name="Cuentas" />
                  <Route exact path="/subcuentas" component={SubCuentas} name="SubCuentas" />
                  <Route exact path="/subcuentas_cr" component={SubCuentasc} name="SubCuentas" />
                  <Route exact path="/dashboard" component={dashboard} name="DashBoard" />
                  <Route exact path="/cartera" component={Cartera} name="Cartera" />
                  <Route exact path="/upload" component={Upload} name="Upload" />
                  <Route exact path="/update" component={Update} name="Update" />
                  {/* {/* <Route  path="/mibalance" component={Balance} name="Balance"/> */}

                  <Route path="*" component={NotFound} name="404" />

                </Switch>

              </Layout>

            </ScrollToTop>

          )}
          //ROUTE END
          />
        }
      </div>
    )

  }

}