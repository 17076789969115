import React from 'react';
import './card.css'
import { Bar, Line } from 'react-chartjs-2';


function CardSoc(props) {
  const datas3 = {
    labels: props.labels,
    datasets: [
      {
        label: 'Saldo',
        backgroundColor: '#4682b4',
          //borderColor: '#4682b4',
          // borderWidth: 1,
          hoverBackgroundColor: '#4682b440',
          hoverBorderColor: '#4682b4',
        data: props.data
      }
    ]
  };
  return (

    <div className="cardsoc">


      <span style={{ display: 'flex', height: '25px', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: '0px', fontSize: '20px', fontFamily: 'CircularStd-Bold', color: '#000000' }}>{props.sociedad}</span>


      <span style={{ fontSize: '20px', justifyContent: 'flex-start', alignItems: 'flex-start', fontFamily: 'CircularStd-Light', color: 'black' }}>{props.saldo} €</span>

      <div style={{padding:'0px', position: 'relative', marginLeft: '0px' }}>
        <Line
          data={datas3}
          options={{
            elements: {
              point: {
                radius: 4
              },

            },
            lineTension: 0.3,
            fill: true,
            fill: {
              target: 'start',
              above: 'rgba(65,65,65,0.21)',   // Area will be red above the origin
              below: 'rgba(65,65,65,0.21)'    // And blue below the origin
            },
            borderWidth: 3,
            showLine: true,

            backgroundColor: "rgba(255,193,53,0.60)",
            borderColor: "rgba(0,0,0,0.61)",

            pointBackgroundColor: "rgba(255,255,255,0.8)",
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              tooltip: {
                mode:'index',
                intersect:false,
                displayColors: false,
                bodyFont: {
                  size: 13,
                  family: 'CircularStd-Light'
                },
                titleFont: {
                  size: 13,
                  family: 'CircularStd-Bold'
                },
              },
              legend: {
                display: false,
              }
            },
            animation: {
              duration: 0,
          },
         
            scales: {

              x: {
                grid: {
                  display: true,
                  drawBorder: true,
                },
              
                ticks: {
                  display: true,
                  font: {
                    size: 12,
                    family: 'CircularStd-Book',
                  }
                }
              },
              y: {
                beginAtZero: true,
                grid: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                  font: {
                    size: 12,
                    family: 'CircularStd-Book',
                  }
                }
              },


            }



          }}

        />
      </div>
    </div>








  );
}
export default CardSoc;