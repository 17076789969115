import React from 'react';
import './card.css';
import {Link,useHistory} from "react-router-dom";
import  {HiChartBar,HiOutlineDotsVertical,HiOutlineDotsCircleHorizontal,HiOutlineCubeTransparent} from 'react-icons/hi';



function Card(props) {

  let history= useHistory();

  function link(){
    history.push({
      pathname: '/subcuentas',
      
      state:{ejercicio:props.ejercicio,mes:props.mimes,nombremes:props.nombremes,cliente:props.cliente,epigrafe:props.epigrafe,concepto:props.concepto.toLowerCase(),subepigrafe:props.subepigrafe,subconcepto:props.subconcepto,pagina:props.pagina}
    })
  }
return(
  
  <tr onClick={() =>link() }>
   
    <td width='30%' align='left' style={{color:'black'}} >{props.titulo}</td>
    <td width='50%' align='left' style={{textTransform:'none'}}>{props.acumulado.charAt(0).toUpperCase() + props.acumulado.slice(1)}</td>  
    <td width='10%' align='right'>{props.anterior} €</td>
    <td width='10%' align='right'>{props.actual} €</td>
  
  </tr>

          // <div className="card7">
          //     <div>
          //       <div style={{display:'flex', fontSize:'22px',fontFamily:'CircularStd-Light',color:'#000000'}}>({props.titulo})&nbsp;&nbsp;
          //       <div style={{display:'flex', fontSize:'22px',fontFamily:'CircularStd-Light',color:'black'}}>{props.acumulado} </div>     
                
                                        
          //                 </div>  
          //             </div>  
          //            
          //                 <HiOutlineDotsVertical style={{
          //                   fontSize:'22px',float:'right',marginTop:'-30px',
          //                   color: '#000000'}}/> 
          //             </Link>
          //         <div style={{fontSize:'14px',fontFamily:'CircularStd-Light',color:'gray'}}>{props.mes}: {props.actual} €</div>
                 
          //        <div style={{width:'100%'}}>
          //           <div style={{float:'left',fontSize:'14px',fontFamily:'CircularStd-Light',color:'gray'}}>{props.anterior} €</div>
              
                   
          //        </div>
                 
          //        </div>
               
             
             
          
);
}

export default Card;