import { Alert } from 'bootstrap';
import React from 'react';
import { Link } from 'react-router-dom';
import imgs from '../assets/404-error.png'
import moment from 'moment'

export default class HomePage extends React.Component {
  
  state = {
    datos: [],
    cargando: true,
    micliente:'',
    //ESTADOS DEL ÚLTIMO EJERCICIO Y MES DISPONIBLES EN LA BBDD

    mes: '',
    meses:[],
    nombremes:'',
    ejercicio: '',
    ejercicios:[],
    maxmes:'',
    mismasas:[],
    ANC:'',
    AC:'',
    PN:'',
    PNC:'',
    PC:'',
    FM:'',
    RESTO:''
  }

  currentweek = async () => { 
    // var date = new Date();
    // var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    // alert(diff.toString())
    var current = new Date();
      var week = [];
      // empezar por el lunes no por el domingo
      var first = current.getDate() - current.getDay() + 1;
      current.setDate(first);
      for (var i = 0; i < 7; i++) {
        week.push(new Date(+current));
        current.setDate(current.getDate()+1);
      }
     // alert(week.map(d => d.toLocaleDateString('es', { weekday: 'long' })+' '+d.toLocaleDateString()));
    
}

nextweek = async () => { 
  // var date = new Date();
  // var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  // alert(diff.toString())
  var current = new Date();
    var week = [];
    // Starting Monday not Sunday 
    var first = current.getDate() - current.getDay() + 1;
    current.setDate(first+7);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(+current));
      current.setDate(current.getDate()+1);
    }
    //alert(week.map(d => d.toLocaleDateString('es', { weekday: 'long' })));
  
}
async componentDidMount() {

// this.currentweek();

}
render() {
  return (
    <div style={{ width: 'calc(100vw  - 265px)', minHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}>
    <div style={{margin: '100px 0 0 35px'}}>
      <div><img src={imgs} style={{display:'block',marginTop:'auto',marginBottom:'auto',marginLeft:'auto',marginRight:'auto',width:'342px'}}></img></div>
    {/* <div style={{color:'rgba(50,74,117,0.8)',fontFamily:'CircularStd-Bold',fontSize:'85px',textAlign:'center' }}>404</div> */}
     <div style={{color:'rgba(0,0,0,0.8)',fontFamily:'CircularStd-Light',fontSize:'15px',textAlign:'center'}}>Página no encontrada. Lo sentimos mucho!!!</div>
    <div style={{display:'block',marginLeft:'auto',marginRight:'auto',textAlign:'center',backgroundColor:'rgba(255,193,53,1)',color:'black',width:'200px',padding:'5px', marginTop:'15px',borderRadius:'5px'}}><Link style={{textDecoration:'none',color:'black',fontFamily:'CircularStd-Book',fontSize:'15px'}} to='/dashboard'>Inicio</Link></div>
    </div>
    </div>
  );
}}
